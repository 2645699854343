import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import automobileAPI from 'api/automobileAPI';
import productAPI from 'api/productAPI';
import orderAPI from 'api/orderAPI';
import { getJsonObject } from 'utils/common';
import { ORDER_ID } from 'constants/global';

const createInputInfo = createAsyncThunk(
  'AUTOMOBILE_CREATE_INPUT_INFO',
  async (param, { rejectWithValue }) => {
    try {
      const res = await orderAPI.registerInsuredInfo(param);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const updateInputInfo = createAsyncThunk(
  'AUTOMOBILE_UPDATE_INPUT_INFO',
  async (params, { rejectWithValue }) => {
    try {
      const res = await orderAPI.updateInsuredInfo(params);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

// eslint-disable-next-line no-unused-vars
const getInsuredInfo = createAsyncThunk(
  'AUTOMOBILE_GET_INSURED_INFO',
  async (param, { rejectWithValue }) => {
    try {
      const res = await automobileAPI.getInsured(param);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getProductByProductId = createAsyncThunk(
  'AUTOMOBILE_GET_PRODUCT_BY_PRODUCT_ID',
  async (param, { rejectWithValue }) => {
    try {
      const res = await productAPI.getProductByProductId(param.product_id);
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const registerDelivery = createAsyncThunk(
  'AUTOMOBILE_REGISTER_DELIVERY',
  async (params, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const res = await productAPI.registerDelivery({ orderId, params });
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const getDelivery = createAsyncThunk(
  'AUTOMOBILE_GET_DELIVERY',
  async (params, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const res = await productAPI.getDelivery(orderId);
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const updateDelivery = createAsyncThunk(
  'AUTOMOBILE_UPDATE_DELIVERY',
  async (params, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const res = await productAPI.updateDelivery({ orderId, params });
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const initialState = {
  loading: false,
  errors: [],
  insuredInfo: null,
  product: null,
  delivery: null,
};
const automobile = createSlice({
  name: 'automobile',
  initialState,
  reducers: {
    setInsuredInfo: {
      reducer: (state, action) => {
        state.insuredInfo = action.payload;
      },
    },
    setProduct: {
      reducer: (state, action) => {
        state.product = action.payload;
      },
    },
    setDelivery: {
      reducer: (state, action) => {
        state.delivery = action.payload;
      },
    },
  },
  extraReducers: {
    [getInsuredInfo.pending]: (state) => {
      state.insuredInfo = null;
      state.errors = [];
    },
    [getInsuredInfo.fulfilled]: (state, { payload }) => {
      state.insuredInfo = payload;
    },
    [getInsuredInfo.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
    [createInputInfo.fulfilled]: (state) => {
      state.loading = false;
    },
    [createInputInfo.pending]: (state) => {
      state.loading = true;
    },
    [createInputInfo.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.errors;
    },
    [updateInputInfo.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateInputInfo.pending]: (state) => {
      state.loading = true;
    },
    [updateInputInfo.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.errors;
    },
    [getProductByProductId.pending]: (state) => {
      state.product = null;
    },
    [getProductByProductId.fulfilled]: (state, { payload }) => {
      state.product = payload;
    },
    [getProductByProductId.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
    [getDelivery.pending]: (state) => {
      state.delivery = null;
    },
    [getDelivery.fulfilled]: (state, { payload }) => {
      state.delivery = payload;
    },
    [getDelivery.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
  },
});

const { reducer, actions } = automobile;
export {
  getInsuredInfo,
  createInputInfo,
  updateInputInfo,
  getProductByProductId,
  registerDelivery,
  getDelivery,
  updateDelivery,
};
export const { setInsuredInfo, setProduct, setDelivery } = actions;
export default reducer;
