import orderAPI from 'api/orderAPI';
import {
  CURRENCY,
  DATE_FORMAT,
  KEY_STORAGE,
  MILESTON_AGE,
  OBJECT_LANGUAGE,
  ORDER_ID,
  ORDER_PRODUCT_LIST,
  PRODUCTS_ID,
} from 'constants/global';
import PATH from 'constants/path';
import * as moment from 'moment';
import { useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

/**
 * Check null or empty
 * @param {object} val
 */
function isEmptyOrNull(val) {
  return !!(
    val === undefined ||
    val == null ||
    val.length <= 0 ||
    (typeof val === 'object' && Object.keys(val).length === 0)
  );
}

/**
 * Check is Object
 * @param {object} val
 *
 */

function isObject(value) {
  if (typeof value === 'object') return true;
  return false;
}

/**
 * Check is Numeric
 * @param {object} val
 *
 */

function isNumeric(str) {
  if (typeof str === 'number') {
    return true;
  }
  return false;
}

/**
 * Check is Array
 * @param {array} val
 * @param {number | bool} val
 *
 */

function isArray(value, length) {
  if (Array.isArray(value)) {
    if (isNumeric(length)) {
      return value.length > length;
    }
    return true;
  }
  return false;
}

/**
 * Parse Address To String
 * @param {object} address
 */
function parseAddressToString(address) {
  if (!address) return '';
  let fullAddress = '';
  if (!isEmptyOrNull(address.street)) fullAddress += `${address.street}, `;
  if (!isEmptyOrNull(address.ward)) fullAddress += `${address.ward.name}, `;
  if (!isEmptyOrNull(address.district)) fullAddress += `${address.district.name}, `;
  if (!isEmptyOrNull(address.province)) fullAddress += address.province.name;
  return fullAddress;
}

/**
 * get Path
 * @param {path} string
 * @param {pathParams} object
 * @param {varParams} object
 * @return string
 */

const replacePathParams = (path, pathParams, varParams) => {
  if (!path) return '/';
  let pathToGo = path;
  if (pathParams) {
    Object.keys(pathParams).forEach((param) => {
      pathToGo = pathToGo.replace(`:${param}`, pathParams[param]);
    });
  }
  if (varParams) {
    const urlParams = Object.keys(varParams).map((param) => {
      return `${param}=${varParams[param]}`;
    });
    pathToGo += `?${urlParams.join('&')}`;
  }

  return pathToGo;
};

/**
 * Get Full Name
 * @param {*} param
 */
const getFullName = ({ firstName, lastName }) => {
  return `${lastName || ''} ${firstName || ''}`.trim();
};

const storeJsonObject = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const removeJsonObject = (key) => {
  localStorage.removeItem(key);
};

const getJsonObject = (key) => {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key));
  }
  return null;
};

function defaultEffectiveDate() {
  const date = new Date();
  const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate() + 1}` : date.getDate() + 1;
  return `${date.getFullYear()}-${month}-${day}T00:00`;
}

function addDefaultSelected(product) {
  const objectTemp = [];
  product = {
    ...product,
    effective_date: defaultEffectiveDate(),
  };
  product.plans.forEach((x) => {
    let item = {};
    // eslint-disable-next-line dot-notation
    item = {
      ...x,
      selected: false,
    };
    // item['selected'] = false;
    const coveragesList = [];
    item.coverages.forEach((child) => {
      let childItem = {};
      // eslint-disable-next-line dot-notation
      childItem = {
        ...child,
        selected: false,
      };
      const subCoverages = [];
      if (child.sub_coverages && child.sub_coverages.length > 0) {
        child.sub_coverages.forEach((sub) => {
          let subItem = {};
          // eslint-disable-next-line dot-notation
          subItem = {
            ...sub,
            selected: false,
          };
          subCoverages.push(subItem);
        });
        child.sub_coverages = subCoverages;
      }
      coveragesList.push(childItem);
    });
    item.coverages = coveragesList;
    objectTemp.push(item);
  });
  product.plans = objectTemp;
  return product;
}

function hasOrdered(productName) {
  const orderList = getJsonObject(ORDER_PRODUCT_LIST);
  const product = orderList && orderList[productName];
  if (!product || product.product_id === -1) {
    return false;
  }
  return true;
}

// String dob
function isChildByDOB(dob) {
  if (!['string'].includes(typeof dob)) return false;
  const age = moment().diff(moment(dob, DATE_FORMAT.DATE), 'years');
  if (Number(age) > MILESTON_AGE) return false;
  return true;
}

/**
 * Get previous value
 * @param any value
 */
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * conpare input date with next day current
 * @param Date someDate
 */
const compareDateWidthNextCurrentDate = (someDate, formatType, nextNumberOfDate) => {
  if (!formatType || !someDate) return false;
  const nextDayOfCurrentDate = moment(new Date(), formatType).add(nextNumberOfDate || 1, 'days');
  return nextDayOfCurrentDate.diff(moment(someDate, formatType), 'days') <= 0;
};

/**
 * Convert date time to format insert DB
 * @param Date someDate
 */
const formatDate = (someDate, formatType) => {
  if (!formatType || !someDate) return false;
  return moment(someDate, formatType).format(formatType);
};

/**
 * set default 2 from day
 * @param Date someDate
 */
const setDefaultFromDate = (date, formatType, add) => {
  if (!formatType || !date) return false;
  const newDate = moment(date, formatType);
  newDate.add(add || 1, 'day');
  newDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return newDate ? formatDate(new Date(newDate), formatType) : false; // return the format with set into input[type=date]
};

/**
 * call api create order id and store in local storage
 * @param function callback func
 */
const setOrderIdToLocalStorage = async (callback = () => { }) => {
  if (!localStorage.getItem(ORDER_ID)) {
    try {
      const res = await orderAPI.createOrderId();
      localStorage.setItem(ORDER_ID, res.id);
      callback();
    } catch (error) {
      const { data, status } = error;
      // status = 409: order id already exists
      if (status === 409 && data.id) {
        localStorage.setItem(ORDER_ID, data.id);
        callback();
      }
    }
  } else callback();
};

/**
 * remove or add id options to checkedOptionals
 * @param ids id checked
 * @param checkedOptionals list checked optional
 */
const checkedOptionalItem = (ids, checkedOptionals) => {
  let listCheked = [...checkedOptionals];
  if (isArray(ids, 0) && isArray(listCheked, 0)) {
    ids.forEach((id) => {
      const index = listCheked.findIndex((x) => x === id);
      if (index > -1) {
        listCheked.splice(index, 1);
      } else {
        listCheked.push(id);
      }
    });
    return listCheked;
  }

  listCheked = ids;
  return listCheked;
};

/**
 * convert string to date
 * @param Date someDate
 */
const convertStringToDate = (date, formatType) => {
  if (!formatType || !date) return false;
  const newDate = moment(date, formatType);
  return newDate ? new Date(newDate) : false;
};

/**  init basic order params for api add
 *  @param string productId
 *  @param string planId
 *  @param array coverages
 */
const initBasicOrderParams = (productId, planId, coverages) => {
  if (!productId || !planId || !coverages) return null;

  const getBasicIds = () => {
    return coverages
      .filter((x) => x.optional === false)
      .map((x) => {
        return x.id;
      });
  };

  const params = {
    product_id: productId,
    plan_id: planId,
    coverage_ids: getBasicIds(),
  };
  return params;
};

/**  get Accept-Language for request header
 *  @param string lng
 */

const getAcceptLanguage = (lng) => {
  if (lng === OBJECT_LANGUAGE.EN) return OBJECT_LANGUAGE.EN;
  return OBJECT_LANGUAGE.VI;
};

/**
 * token validate
 */

const isAuthenticated = () => {
  const token = localStorage.getItem(KEY_STORAGE.TM_TOKEN);
  return !isEmptyOrNull(token);
};

const formatToCurrency = (number, suffix) => {
  if (!number) return 0;
  const currency = number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return currency + (suffix ? ` ${suffix}` : '');
};

const convertProductData = (product) => {
  if (!product) return [];

  // combine coverages & sub_coverages
  const combineCoverageArray = (coverages) => {
    let coverageCombined = [];
    coverages.forEach((item) => {
      const { sub_coverages: subCoverages } = item;
      if (subCoverages) {
        coverageCombined = [...coverageCombined, ...subCoverages];
      } else coverageCombined = [...coverageCombined, item];
    });
    return coverageCombined;
  };

  // format data compatible with table structure
  const formatColumnData = (insuranceArray, nonePremiumCouter) => {
    const arrayWithPremium = [];
    const arrayNonePremium = [];
    insuranceArray.forEach(({ id, name, sumInsured, premium }) => {
      if (premium) {
        arrayWithPremium.push({
          id,
          name,
          sumInsured: formatToCurrency(sumInsured, CURRENCY.VND),
          premium: {
            value: formatToCurrency(premium, CURRENCY.VND),
            rowspan: nonePremiumCouter + 1,
          },
        });
      } else {
        arrayNonePremium.push({ id, name, sumInsured: formatToCurrency(sumInsured, CURRENCY.VND) });
      }
    });
    return arrayWithPremium.concat(arrayNonePremium).sort((a, b) => a.id - b.id);
  };

  // convert data by old: adult, children
  const convertDataByOld = (type, coverages) => {
    const isAdult = type === 'adult';
    const basicInsurance = [];
    const optionalInsurance = [];
    let basicNonePremiumCouter = 0; // for rowSpan
    let optionalNonePremiumCouter = 0; // for rowSpan

    combineCoverageArray(coverages).forEach((item) => {
      const { id, adult, optional, name, premium, sum_insured: sumInsured } = item || {};
      if (!optional && adult === isAdult) {
        if (!premium) basicNonePremiumCouter += 1;
        basicInsurance.push({ id, name, sumInsured, premium });
      }
      if (optional && adult === isAdult) {
        if (!premium) optionalNonePremiumCouter += 1;
        optionalInsurance.push({ id, name, sumInsured, premium });
      }
    });

    return {
      title: type,
      basicInsurance: formatColumnData(basicInsurance, basicNonePremiumCouter),
      optionalInsurance: formatColumnData(optionalInsurance, optionalNonePremiumCouter),
    };
  };

  const productArray = product.map(({ id, name, coverages }) => {
    const adult = convertDataByOld('adult', coverages);
    const children = convertDataByOld('children', coverages);
    return { id, name, adult, children };
  });

  return productArray;
};

/**
 * Get url params
 * @param any value
 */
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * set default 2 from day
 * @param Date someDate
 */
const addNumberMonth = (date, formatType, monthCount) => {
  if (!formatType || !date) return false;
  const newDate = moment(date, formatType);
  newDate.add(monthCount, 'month');
  newDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return newDate ? formatDate(new Date(newDate), formatType) : false; // return the format with set into input[type=date]
};

/**
 * return date object from date string
 * @param Date string
 * @param format string
 */

const getDateObj = (date, format) => {
  if (format) {
    const d = date || new Date();
    return moment(d, format).toDate();
  }
  return new Date();
};

/**
 * return timestamp
 * @param Date string date or null
 */

const getTimestamp = (d, format = DATE_FORMAT.DATE) => {
  const dateObj = getDateObj(d, format);
  return dateObj ? dateObj.getTime() : null;
};

/**
 * return soldCount
 * @param soldNumber
 */
const convertSold = (soldNumber) => {
  let soldCount = String(soldNumber);
  if (Number(soldCount) >= 1000) {
    const soldCountTemp = soldCount.slice(soldCount.length - 3, soldCount.length);
    soldCount = soldCount.replace(soldCountTemp, '');
    soldCount = `+${soldCount}K`;
  }
  return soldCount;
};

/**
 * return Date
 * @param Number
 */
const getFutureDate = (next) => {
  const futureDate = new Date();
  futureDate.setDate(new Date().getDate() + next);
  return futureDate;
};

const reponseSuccess = (response) => {
  return isEmptyOrNull(response?.status);
};

const isDev = () => process.env.NODE_ENV && process.env.NODE_ENV !== 'development';

const removeAllClientStorage = () => {
  localStorage.clear();
};
function isIOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform);
}

const redirectHomeOrderPaid = () => {
  localStorage.removeItem(ORDER_ID);
  window.location.href = '/';
};

const removeStorageAndRedirectLogin = (params) => {
  removeJsonObject(KEY_STORAGE.TM_TOKEN);
  removeJsonObject(ORDER_ID);
  params.dispatch(params.setDrawer(false));
  window.location.replace(`#${PATH.LOGIN}`);
};
/**
 * go back to specific page when user clicked on browser back button
 */
const useBrowserBack = (backPath) => {
  const history = useHistory();

  useEffect(() => {
    const myPopState = () => {
      if (backPath) history.replace(backPath);
    };

    window.addEventListener('popstate', myPopState);
    return () => {
      window.removeEventListener('popstate', myPopState);
    };
  }, [history, backPath]);
};

/**
 * set default location data
 */
const useSetAddress = (provinceId, districtId, wardId, { provinceRef, districtRef, wardRef }) => {
  const { provinces, wards, districts } = useSelector((state) => state.app.location);

  useEffect(() => {
    if (isArray(provinces, 0)) {
      if (provinceId && provinceRef) provinceRef.current.setImperativeValue(provinceId);
    }
  }, [provinceId, provinces, provinceRef]);

  useEffect(() => {
    if (isArray(districts, 0)) {
      if (districtId && districtRef) districtRef.current.setImperativeValue(districtId);
    }
  }, [districtId, districts, districtRef]);

  useEffect(() => {
    if (isArray(wards, 0)) {
      if (wardId && wardRef) wardRef.current.setImperativeValue(wardId);
    }
  }, [wardId, wards, wardRef]);
};

// validate response data
const isResquestFailed = (response) => {
  const { errors: resError, status } = response?.payload || {};
  if (status === 'UNKNOWN' || resError?.status || (status && status !== 200))
    return resError?.status || status || true;
  return false;
};

const formatPrice = (price, t) => {

  if (price >= 1000000000) {
    return `${(price / 1000000000)} <br/> <span>${t('billion')}</span>`;
  }
  if (price >= 10000000) {
    return `${(price / 1000000)} <br/> <span>${t('million')}</span>`;
  }
  if (price >= 1000000) {
    return `${(price / 1000000)} <br/> <span>${t('million')}</span>`;
  }
  return `${(price / 1000)} <br/> <span>${t('thousand')}</span>`;
}

const getObjectByLanguage = (objectOne, objectTwo) => {
  return (i18next.language === OBJECT_LANGUAGE.VI) ? objectOne : objectTwo;
}

const checkIsExitsProductInCombo = (productId) => {
  const resultComBo = getJsonObject(KEY_STORAGE.COMBO);
  if (resultComBo) {
    const isExitsProduct = resultComBo.findIndex((x) => x.id === productId);
    if (isExitsProduct > -1) {
      resultComBo[isExitsProduct].isSelected = true;
      storeJsonObject(KEY_STORAGE.COMBO, resultComBo);
      let url = PATH.COMBO.QUESTIONNAIRE;
      for (let i = 0; i < resultComBo.length; i += 1) {
        if (!resultComBo[i].isSelected) {
          const id = Number(resultComBo[i].id);
          if (id === PRODUCTS_ID.PA) {
            url = PATH.PA.MAIN;
          } else if (id === PRODUCTS_ID.HEALTH_CARE) {
            url = PATH.HEALTH_CARE.MAIN;
          } else if (id === PRODUCTS_ID.HOME_LIABILITY) {
            url = PATH.HOME_LIABILITY.MAIN;
          }
          return { isComBo: true, url, id };
        }
      }
      return { isComBo: true, url, id: PRODUCTS_ID.PA };
    }
  }
  return { isComBo: false };
};

const getAge = (birthDay) => {
  const dob = convertStringToDate(birthDay, DATE_FORMAT.DATETIME)
  const currentDate = new Date();
  return currentDate.getFullYear() - dob.getFullYear();
}

export {
  isNumeric,
  isArray,
  parseAddressToString,
  isEmptyOrNull,
  isObject,
  replacePathParams,
  getFullName,
  convertProductData,
  storeJsonObject,
  getJsonObject,
  addDefaultSelected,
  hasOrdered,
  usePrevious,
  compareDateWidthNextCurrentDate,
  defaultEffectiveDate,
  setDefaultFromDate,
  setOrderIdToLocalStorage,
  checkedOptionalItem,
  convertStringToDate,
  isChildByDOB,
  initBasicOrderParams,
  removeJsonObject,
  formatDate,
  getAcceptLanguage,
  isAuthenticated,
  formatToCurrency,
  useQuery,
  addNumberMonth,
  getDateObj,
  getTimestamp,
  convertSold,
  getFutureDate,
  reponseSuccess,
  isDev,
  removeAllClientStorage,
  isIOS,
  redirectHomeOrderPaid,
  removeStorageAndRedirectLogin,
  useBrowserBack,
  useSetAddress,
  isResquestFailed,
  formatPrice,
  getObjectByLanguage,
  checkIsExitsProductInCombo,
  getAge
};
