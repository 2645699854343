import React from 'react';
import PropTypes from 'prop-types';
import { iconMenuClose } from 'assets/images';
import './Backdrop.scss';

const Backdrop = ({ clickHandler }) => {
  return (
    <div className="outside" role="button" onClick={() => clickHandler()}>
      <img className="close-menu" alt="" src={iconMenuClose} onClick={() => clickHandler()} />
    </div>
  );
};

Backdrop.propTypes = {
  clickHandler: PropTypes.func.isRequired,
};

export default Backdrop;
