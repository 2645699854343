import { icDownloadFile } from 'assets/images';
import { getFile } from 'components/Header/headerSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

function DownloadItem() {
  const { url } = useSelector((state) => state.header);
  const dispatch = useDispatch();
  const downloadFile = () => {
    dispatch(getFile(url));
  };

  return <img alt="" className="header-left" src={icDownloadFile} onClick={() => downloadFile()} />;
}

export default DownloadItem;
