import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userAPI from 'api/userAPI';
import orderAPI from 'api/orderAPI';
import productAPI from 'api/productAPI';
import { ORDER_ID } from 'constants/global';
import { isArray, getJsonObject } from 'utils/common';

const getSeletedPlanAndCoverage = createAsyncThunk(
  'HL_GET_SELETED_PLAN_AND_COVERAGE',
  async (param, { rejectWithValue }) => {
    try {
      let data = {
        plan_id: 1,
        coverage_ids: [],
        number_of_people: 1,
        product_id: 1,
        total_amount: 0,
      };
      const res = await orderAPI.getOrderDetailByOrderId(param.order_id);
      if (res && isArray(res.order_details, 0)) {
        data = res.order_details.find((x) => x.product_id === param.product_id);
      }
      return data;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const headOfHousehold = localStorage.getItem('headOfHousehold')
  ? JSON.parse(localStorage.getItem('headOfHousehold'))
  : {};

// eslint-disable-next-line no-unused-vars
const getProductByProductId = createAsyncThunk(
  'HL_GET_PRODUCT_BY_PRODUCT_ID',
  async (param, { rejectWithValue }) => {
    try {
      const res = await productAPI.getProductByProductId(param.product_id);
      if (param.seletedMode) {
        param.dispatchFuc(getSeletedPlanAndCoverage(param));
      }
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const createCustomerInfo = createAsyncThunk(
  'HOME_LIABILITY_CREATE_CUSTOMER_INFO',
  async (arg, { rejectWithValue }) => {
    try {
      const res = await userAPI.create(arg);

      return res.data;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const updatePlanAndCoverage = createAsyncThunk(
  'HL_UPDATE_PLAN_AND_COVERAGE',
  async (param, { rejectWithValue }) => {
    try {
      const res = await productAPI.updatePlanAndCoverage(param);
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

/**
 * Add product to order
 * @object { orderId, params }
 */

const registerPlanAndCoverage = createAsyncThunk(
  'HL_REGISTER_PLAN_AND_COVERAGE',
  async (param, { rejectWithValue }) => {
    try {
      const res = await orderAPI.registerPlanAndCoverage(param);
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

/**
 * Add product to order
 * @object { orderId, params }
 */

const createAddressInsurance = createAsyncThunk(
  'HL_CREATE_ADDRESS_INSURANCE',
  async (data, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const res = await orderAPI.createAddressInsurance({ order_id: orderId, data });
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

/**
 * update home insured liability
 */

const updateAddressInsurance = createAsyncThunk(
  'HL_UPDATE_ADDRESS_INSURANCE',
  async (data, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const res = await orderAPI.updateAddressInsurance({ order_id: orderId, data });
      return res;
    } catch (errors) {
      return rejectWithValue({ errors });
    }
  }
);

/**
 * get home insured liability
 */

const getInsuredHomeliability = createAsyncThunk(
  'HL_GET_INSURED_HOMELIABILITY',
  async (data, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const res = await orderAPI.getInsuredHomeliability(orderId);
      return res;
    } catch (errors) {
      return rejectWithValue({ errors });
    }
  }
);

const getEffectDateOfSuperInsurance = createAsyncThunk(
  'HL_GET_EFFECT_DATE_OF_SUPER_INSURANCE',
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await orderAPI.getEffectDateOfSuperInsurance(orderId);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const updateEffectDateOfSuperInsurance = createAsyncThunk(
  'HL_UPDATE_EFFECT_DATE_OF_SUPER_INSURANCE',
  async ({ orderId, params }, { rejectWithValue }) => {
    try {
      const res = await orderAPI.updateEffectDateOfSuperInsurance(orderId, params);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const getPremiumsOfPlansByProductId = createAsyncThunk(
  'HL_GET_PREMIUM_OF_PLANS_BY_PRODUCT_ID',
  async (params, { rejectWithValue }) => {
    try {
      const res = await orderAPI.getPremiumsOfPlansByProductId(params);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const initialState = {
  loading: false,
  errors: [],
  headOfHousehold,
  product: null,
  insuredList: [],
  seletedPlanAndCoverageOld: null,
  registerPlanAndCoverageResult: null,
  homeInsured: null,
  effectDateObj: null,
  premiumsOfPlansByProductId: [],
};
const homeLiability = createSlice({
  name: 'homeLiability',
  initialState,
  reducers: {
    saveHeadOfHouseholdInfo(state, action) {
      state.headOfHousehold = action.payload;
      localStorage.setItem('headOfHousehold', JSON.stringify(action.payload));
    },
    clearRegisterResponse: {
      reducer: (state) => {
        state.registerPlanAndCoverageResult = null;
      },
    },
  },
  extraReducers: {
    [getProductByProductId.fulfilled]: (state, { payload }) => {
      state.product = payload;
      state.loading = false;
    },
    [getProductByProductId.pending]: (state) => {
      state.loading = true;
    },
    [getProductByProductId.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.errors;
    },
    [createCustomerInfo.fulfilled]: (state, { payload }) => {
      state.insuredList.push(payload);
      state.loading = false;
    },
    [createCustomerInfo.pending]: (state) => {
      state.loading = true;
    },
    [createCustomerInfo.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.errors;
    },
    [registerPlanAndCoverage.fulfilled]: (state, { payload }) => {
      state.registerPlanAndCoverageResult = payload;
    },
    [registerPlanAndCoverage.pending]: (state) => {
      state.registerPlanAndCoverageResult = null;
    },
    [registerPlanAndCoverage.rejected]: (state, { payload }) => {
      state.registerPlanAndCoverageResult = payload;
    },
    [getSeletedPlanAndCoverage.fulfilled]: (state, { payload }) => {
      state.errors = {};
      state.seletedPlanAndCoverageOld = payload;
      state.loading = false;
    },
    [getSeletedPlanAndCoverage.pending]: (state) => {
      state.loading = true;
    },
    [getSeletedPlanAndCoverage.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    [updatePlanAndCoverage.fulfilled]: (state) => {
      state.errors = {};
      state.loading = false;
    },
    [updatePlanAndCoverage.pending]: (state) => {
      state.loading = true;
    },
    [updatePlanAndCoverage.rejected]: (state) => {
      state.loading = false;
    },
    [getInsuredHomeliability.fulfilled]: (state, { payload }) => {
      state.errors = {};
      state.homeInsured = payload;
    },
    [updateAddressInsurance.fulfilled]: (state) => {
      state.homeInsured = null;
    },
    // get effect date of super insurance
    [getEffectDateOfSuperInsurance.pending]: (state) => {
      state.errors = null;
      state.loading = true;
      state.effectDateObj = null;
    },
    [getEffectDateOfSuperInsurance.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.effectDateObj = payload;
    },
    [getEffectDateOfSuperInsurance.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    // update effect date of super insurance
    [updateEffectDateOfSuperInsurance.pending]: (state) => {
      state.errors = null;
      state.loading = true;
    },
    [updateEffectDateOfSuperInsurance.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateEffectDateOfSuperInsurance.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    // get premium of plans by product id
    [getPremiumsOfPlansByProductId.pending]: (state) => {
      state.errors = null;
      state.loading = true;
      state.premiumsOfPlansByProductId = [];
    },
    [getPremiumsOfPlansByProductId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.premiumsOfPlansByProductId = payload;
    },
    [getPremiumsOfPlansByProductId.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
      state.premiumsOfPlansByProductId = [];
    },
  },
});

const {
  reducer,
  actions: { clearRegisterResponse },
} = homeLiability;
export const { saveHeadOfHouseholdInfo } = homeLiability.actions;
export {
  getProductByProductId,
  registerPlanAndCoverage,
  updatePlanAndCoverage,
  getSeletedPlanAndCoverage,
  clearRegisterResponse,
  createAddressInsurance,
  getInsuredHomeliability,
  updateAddressInsurance,
  getEffectDateOfSuperInsurance,
  updateEffectDateOfSuperInsurance,
  getPremiumsOfPlansByProductId,
};
export default reducer;
