/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import './ProductIcon.scss';

const ProductIcon = ({ name, iconText, imgSrc, color, size, onPress, smallText }) => {
  return (
    <div className="pi-container" role="button" onClick={() => onPress()}>
      <div
        className={`mx-auto pi-icon-outer pi-icon-${size}`}
        style={{ backgroundImage: `url(${imgSrc})` }}
      >
        {iconText && (
          <div className={`pi-subtext pi-subtext-${color} ${smallText ? 'small-text' : ''}`}>
            <span dangerouslySetInnerHTML={{ __html: iconText }} />
          </div>
        )}
      </div>
      <p className="pi-name">{name}</p>
    </div>
  );
};

ProductIcon.defaultProps = {
  name: '',
  iconText: '',
  color: 'blue', // blue | violet
  size: 'small', // small | large
  smallText: false,
  onPress: () => {},
};

ProductIcon.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  name: PropTypes.string,
  iconText: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  onPress: PropTypes.func,
  smallText: PropTypes.bool,
};

export default ProductIcon;
