const QUESTIONNAIRE_TYPE = {
  SINGLE_CHOICE: 'single',
  MULTIPLE_CHOICE: 'multiple',
};

const OBJECT_TYPE_MENU = {
  MENU: 'menuItem',
  SHARE: 'shareItem',
  SEARCH: 'searchItem',
  EMPTY: 'empty',
  DOWNLOAD: 'download',
  CART: 'cartMenu',
  BACK: 'backItem',
};

const OBJECT_HEADER_FULL_WIDTH = {
  LANDING_PAGE: 'landing-page',
};

const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
};

const PHONE_MATCH = /^[0-9]{10,11}$/i;
const EMAIL_MATCH = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+[a-z0-9-]$/i;
const PARENT_APP_CLASS = 'parent-app';
const PARENT_APP_CONTAINS_FOOTER_CLASS = 'parent-app-contains-footer';
const NUMBER_MATCH = /^[0-9]*$/;

const LABELS = {
  UPDATE: 'update',
  EDIT_INFO: 'label_edit_info',
  CONFIRM: 'confirm',
  BACK: 'back',
  FINISH: 'finish',
  CANCEL: 'cancel',
  OK: 'ok',
};

const TITLES = {
  WARNING: 'warning',
  NOTIFY: 'notify',
  CONFIRM: 'confirm',
  INFORMATION: 'information',
};

const HEADER_TITLE = {
  CUSTOMTER_INFO: 'insured_info',
  PROFILE: 'profile',
};

const PRODUCTS_ID = {
  PA2: 4,
  PA: 1,
  HEALTH_CARE: 2,
  HOME_LIABILITY: 3,
  AUTOMOBILE: 5,
  MOTORBIKE: 6,
};

const ORDER_PRODUCT_LIST = 'order_product_list';

const PRODUCT_ORDER = {
  COVERGAGE_IDS: 'coverage_ids',
  INSURED_IDS: 'insured_ids',
  PLAN_ID: 'plan_id',
  PRODUCT_ID: 'product_id',
  INSURED_LIST: 'insured_list',
  EFFECTIVE_DATE: 'effective_date',
};

const PRODUCTS_NAME = {
  PA: 'pa',
  HEALTH_CARE: 'health_care',
  HOME_LIABILITY: 'home_liability',
  AUTOMOBILE: 'automobile',
  MOTORBIKE: 'motorbike',
};

const PRODUCTS_I18_NAME = {
  1: 'personal_accident_insurance',
  2: 'healthcare_insurance',
  3: 'public_liability_insurance',
  4: 'automobile_insurance',
  5: 'motorbike_insurance',
};

const SURVEY = {
  PA: '1',
  HEALTHCARE: '2',
  PA2: '4',
};

const INSURED_STATUS = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};
const ROUTER_HISTORY_ACTIONS = {
  PUSH: 'PUSH',
  POP: 'POP',
};

const KEY_STORAGE = {
  PA_INSURED_LIST: 'PA_INSURED_LIST',
  HC_INSURED_LIST: 'HC_INSURED_LIST',
  PA_DELETED_INSURED_LIST: 'PA_DELETED_INSURED_LIST',
  HC_DELETED_INSURED_LIST: 'HC_DELETED_INSURED_LIST',
  HEAD_OF_HOUSE_HOLD: 'headOfHousehold',
  PA_CHANGED: 'PA_CHANGED',
  HC_CHANGED: 'HC_CHANGED',
  TM_TOKEN: 'TM_TOKEN',
  LANGUAGE: 'LNG',
  CURRENCY: 'CURRENCY',
  COMBO: 'COMBO',
};

const RELATIONSHIP = {
  CHOOSE: 'choose_relationship',
  ACCOUNT_OWNER: 'account_owner',
  PARENTS: 'parents',
  SPOUSE: 'spouse',
  CHILD: 'child',
  GRANDPARENTS: 'grandparents',
  GRANDCHILDREN: 'grandchildren',
  SIBLINGS: 'siblings',
  ADOPTED: 'adopted',
  CHILD_IN_LAW: 'child_in_law',
};

const VEHICLE_TYPE = {
  MORE_50: 'lt_50cc',
  UPTO_50: 'gt_50cc',
  SIMILAR_3W: '3_wheels',
  TRUCK: 'truck_automobile',
  NONE_BUSINESS: 'unuse_in_business_automobile',
  BUSINESS: 'use_in_business_automobile',
};

const DATE_FORMAT = {
  DATETIME_WITHOUT_SECONDS: 'DD/MM/YYYY HH:mm',
  DATETIME: 'DD/MM/YYYY HH:mm:ss',
  DATE: 'DD/MM/YYYY',
  TIME: 'HH:mm',
};

const ACTION_EVENT = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

const ALERT_TYPE = {
  SUCCESS: 'success',
  UNSUCCESS: 'unsuccess',
  WARNING: 'warning',
  NOTIFY: 'notify',
};
const ORDER_ID = 'order_id';

const UPDATE_TIMES = 1; // limit update customer info

const MILESTON_AGE = 15;

const PAYMENT_STATUS = {
  SUCCESS: 'success',
  UNSUCCESSFUL: 'unsuccessful',
  CANCEL: 'cancel',
};

const PAYMENT_METHOD = {
  POINT: 'point',
  VISA: 'visa',
};
const VALIDATION = {
  CUSTOMER: {
    FIRST_NAME_MAX_LENGTH: 60,
    LAST_NAME_MAX_LENGTH: 60,
    ID_CARD_MAX_LENGTH: 24,
    STREET_MAX_LENGTH: 60,
    PHONE_MAX_LENGTH: 11,
  },
  AUTOMOBILE: {
    LICENSE_PLATES_MAX_LENGTH: 12,
    CHASSIS_NUMBER_MAX_LENGTH: 17,
    ENGINE_NUMBER_MAX_LENGTH: 20,
    VEHICLE_TYPE_MAX_LENGTH: 30,
    TONNAGE_MAX_LENGTH: 4,
    SEAT_NUMBER_MAX_LENGTH: 2,
    TONNAGE_PATTERN: /^([0-9]{1,2}){1}(\.[0-9]{1})?$/,
  },
  MOTORBIKE: {
    PLATE_MAX_LENGTH: 12,
    ENGINE_MAX_LENGTH: 20,
    CHASSIS_MAX_LENGTH: 17,
  },
  QA: {
    MAX_LENGTH: 2048,
    MIN_LENGTH: 20,
  },
  HOME_LIABILITY: {
    STREET: 60,
  },
};

const STATUS_CODE = {
  CONFLICT: 409,
};

const AGE = {
  ADULT: 'adult',
  CHILDREN: 'children',
};

const OBJECT_LANGUAGE = {
  EN: 'en-US',
  VI: 'vi-VN',
};

const CURRENCY = {
  VND: 'VND',
};

const MARRIED = {
  SINGLE: 'single',
  MARRIED: 'married',
  REMARRIED: 'remarried',
  SEPARATED: 'separated',
  DIVORCED_WIDOWED: 'divorced_widowed',
};

const CONTRACT_STATUS = {
  EXPIRE: 'expired',
  CANCEL: 'cancelled',
  CANCELING: 'canceling',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

const CONTRACT_STATUS_LANG = {
  [CONTRACT_STATUS.EXPIRE]: 'expired_contract',
  [CONTRACT_STATUS.CANCEL]: 'cancelled_contract',
  [CONTRACT_STATUS.CANCELING]: 'canceling_contract',
  [CONTRACT_STATUS.ACTIVE]: 'active_contract',
  [CONTRACT_STATUS.INACTIVE]: 'inactive_contract',
};

const PRODUCT_DISCOUNT = '5%';

const HOME_CLASSIFY_OPTION = [
  { label: 'owner', value: 'owner' },
  { label: 'rent', value: 'rent' },
];

const HOME_OWNER = 'owner';

const TIME_LOADING = 500;

const ORDER_NAME = 'order';

const ORDER_STATUS = {
  PAID: 'paid',
};

const DELIVERY_METHOD = {
  DELIVERY: 'home',
  AEON: 'aeon',
};

const COMPULSORY_EFFECTED_DATE = 2;

const URL_PARAMS = {
  PAYMENT: 'payment',
};

const OBJECT_RECEIVED_PA2 = {
  NAME: 'RECEIVED_PA2',
  VALUE: 1,
};

const YOUTUBE_INTRODUCE_URL = 'https://www.youtube.com/embed/E5A_fhsU5yQ';
const CLAIM_GUIDE_URL = 'https://media.tokiomarine.com.vn//uploads-1/files/TMIV_CLM Procedure_Cashless service_Quy trinh BL Vien phi.pdf';

export {
  PARENT_APP_CONTAINS_FOOTER_CLASS,
  PARENT_APP_CLASS,
  OBJECT_HEADER_FULL_WIDTH,
  OBJECT_TYPE_MENU,
  PHONE_MATCH,
  EMAIL_MATCH,
  GENDER,
  LABELS,
  TITLES,
  HEADER_TITLE,
  QUESTIONNAIRE_TYPE,
  PRODUCTS_ID,
  ORDER_PRODUCT_LIST,
  PRODUCT_ORDER,
  PRODUCTS_NAME,
  ROUTER_HISTORY_ACTIONS,
  KEY_STORAGE,
  RELATIONSHIP,
  INSURED_STATUS,
  SURVEY,
  VEHICLE_TYPE,
  PRODUCTS_I18_NAME,
  DATE_FORMAT,
  ACTION_EVENT,
  NUMBER_MATCH,
  UPDATE_TIMES,
  ORDER_ID,
  MILESTON_AGE,
  PAYMENT_STATUS,
  PAYMENT_METHOD,
  VALIDATION,
  ALERT_TYPE,
  STATUS_CODE,
  AGE,
  OBJECT_LANGUAGE,
  CURRENCY,
  MARRIED,
  CONTRACT_STATUS,
  CONTRACT_STATUS_LANG,
  PRODUCT_DISCOUNT,
  HOME_CLASSIFY_OPTION,
  HOME_OWNER,
  TIME_LOADING,
  ORDER_NAME,
  ORDER_STATUS,
  DELIVERY_METHOD,
  COMPULSORY_EFFECTED_DATE,
  URL_PARAMS,
  OBJECT_RECEIVED_PA2,
  YOUTUBE_INTRODUCE_URL,
  CLAIM_GUIDE_URL
};
