import { setLoading } from 'app/appSlice';
import axios from 'axios';
import { setDrawer } from 'components/Header/headerSlice';
import { KEY_STORAGE, ORDER_NAME } from 'constants/global';
import queryString from 'query-string';
import { getAcceptLanguage, redirectHomeOrderPaid, removeStorageAndRedirectLogin } from 'utils/common';

const TIMEOUT = 30000;
// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request- config` for the full list of configs
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: TIMEOUT,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

/**
 * Set Interceptor
 * @param {*} dispatch
 */
export const setInterceptor = (dispatch) => {
  axiosClient.interceptors.request.use(async (config) => {
    dispatch(setLoading(true));
    const token = localStorage.getItem(KEY_STORAGE.TM_TOKEN);
    const lng = localStorage.getItem(KEY_STORAGE.LANGUAGE);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers['Accept-Language'] = getAcceptLanguage(lng);
    return config;
  });

  axiosClient.interceptors.response.use(
    async (response) => {
      dispatch(setLoading(false));
      if (response && response.data) {
        return response.data;
      }
      return true;
    },
    async (error) => {
      // Handle errors
      const { response } = error;
      const originalRequest = error.config;

      // redirect home and remove order id in localstorage when purchased order 
      if (response?.status === 406 && String(originalRequest.url).includes(ORDER_NAME)) {
        dispatch(setLoading(false));
        redirectHomeOrderPaid();
      }

      if (response?.status === 401) {
          dispatch(setLoading(false));
          removeStorageAndRedirectLogin({ dispatch, setDrawer });
      } else if (response && response?.status) {
        dispatch(setLoading(false));
        const { data, status } = response;
        const errorData = { data, status };
        throw errorData;
      }
      dispatch(setLoading(false));
      const fomartedError = {
        code: 'UNKNOWN',
        message: error.message || '',
      };
      const errorData = { data: fomartedError, status: response?.status || 'UNKNOWN' };
      throw errorData;
    }
  );
};
export default axiosClient;
