import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userAPI from 'api/userAPI';
import { ALERT_TYPE, RELATIONSHIP } from 'constants/global';
import { MSG_0007, MSG_0008 } from 'constants/messages';

const getProfile = createAsyncThunk('GET_PROFILE', async (_arg, { rejectWithValue }) => {
  try {
    const res = await userAPI.get();
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const updateProfile = createAsyncThunk('UPDATE_PROFILE', async (arg, { rejectWithValue }) => {
  try {
    arg.relationship = RELATIONSHIP.ACCOUNT_OWNER;
    const res = await userAPI.update(arg);

    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const updateCustomerContact = createAsyncThunk(
  'UPDATE_CUSTOMER_CONTACT',
  async (arg, { rejectWithValue }) => {
    try {
      const res = await userAPI.updateCustomerContact(arg);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createProfile = createAsyncThunk(
  'PF_CREATE_CUSTOMER_INFO',
  async (arg, { rejectWithValue }) => {
    try {
      const res = await userAPI.create(arg);
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const initialState = {
  profile: {},
  loading: false,
  errors: [],
  alertMessage: {},
};

const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearMessage: {
      reducer: (state) => {
        state.alertMessage = '';
      },
    },
  },
  extraReducers: {
    [getProfile.fulfilled]: (state, action) => {
      state.profile = action.payload;
      state.loading = false;
      state.errors = [];
    },
    [getProfile.pending]: (state) => {
      state.loading = true;
      state.profile = {};
    },
    [getProfile.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [updateProfile.fulfilled]: (state) => {
      state.errors = [];
      state.alertMessage = { messageCode: MSG_0007, type: ALERT_TYPE.SUCCESS };
    },
    [updateProfile.rejected]: (state, action) => {
      state.errors = action.payload;
      state.alertMessage = { messageCode: MSG_0008, type: ALERT_TYPE.UNSUCCESS };
    },
    [createProfile.fulfilled]: (state) => {
      state.errors = [];
      state.alertMessage = { messageCode: MSG_0007, type: ALERT_TYPE.SUCCESS };
    },
    [createProfile.rejected]: (state, action) => {
      state.errors = action.payload;
      state.alertMessage = { messageCode: MSG_0008, type: ALERT_TYPE.UNSUCCESS };
    },
    [updateCustomerContact.fulfilled]: (state) => {
      state.loading = false;
      state.errors = [];
      state.alertMessage = { messageCode: MSG_0007, type: ALERT_TYPE.SUCCESS };
    },
    [updateCustomerContact.pending]: (state) => {
      state.loading = true;
    },
    [updateCustomerContact.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
      state.alertMessage = { messageCode: MSG_0008, type: ALERT_TYPE.UNSUCCESS };
    },
  },
});

const { reducer, actions } = profile;
const { clearMessage } = actions;
export { getProfile, updateProfile, updateCustomerContact, clearMessage, createProfile };
export default reducer;
