/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormSelectBox } from 'components';
import { getWards } from 'app/appSlice';

const DistrictBox = forwardRef(({ ...props }, ref) => {
  const dispatch = useDispatch();
  const { districts } = useSelector((state) => state.app.location);
  const provinceId = useSelector((state) => state.app.provinceId);
  const [value, setValue] = useState(props?.value);

  const handleChange = (event) => {
    setValue(Number(event?.target?.value));
  };

  useEffect(() => {    
    if (value) dispatch(getWards({ provinceId, districtId: value }));
  }, [value, dispatch, provinceId]);

  useEffect(() => {
    const districtId = districts[0]?.id;
    if (districtId && !districts.some((i) => i?.id === value)) setValue(districtId);
  }, [districts, value]);

  useImperativeHandle(ref, () => ({
    setImperativeValue(value) {
      if (districts.some((i) => i?.id === value)) setValue(value);
    },
  }));

  return (
    <FormSelectBox
      {...props}
      ref={ref}
      onChange={handleChange}
      value={value}
      controlId="district-box"
    >
      {districts.map((item, index) => (
        <option key={`district_${index + 1}`} value={item.id}>
          {item.name}
        </option>
      ))}
    </FormSelectBox>
  );
});

export default DistrictBox;
