import { CommonButton } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './ConfirmDialog.scss';
import { LABELS } from 'constants/global';

const ConfirmDialog = ({ title, message, leftLabel, rightLabel, btnRightClick, btnLeftClick, isShow, isShowLeftButton }) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        centered
        show={isShow}
        keyboard={false}
        animation={false}
        className="cfp-wrapper"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title className="cfp-title mx-auto mt-2">{t(title)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="cfp-content mx-auto">{message}</Modal.Body>
        <Modal.Footer>
          <div className="tm-popup-footer">
            {isShowLeftButton && (
              <CommonButton
                primary={false}
                type="button"
                className="ml-0 mr-2"
                label={t(leftLabel)}
                onPress={btnLeftClick}
              />
            )}
            <CommonButton className="ml-0" label={t(rightLabel)} onPress={btnRightClick} type="button" />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ConfirmDialog.defaultProps = {
  leftLabel: LABELS.BACK,
  rightLabel: LABELS.OK,
  isShowLeftButton: true
};

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isShow: PropTypes.bool.isRequired,
  btnLeftClick: PropTypes.func.isRequired,
  btnRightClick: PropTypes.func.isRequired,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  isShowLeftButton: PropTypes.bool,
};

export default ConfirmDialog;
