import { INSURED_STATUS } from 'constants/global';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'utils/common';
import './ResultItem.scss';

const ResultItem = ({ item }) => {
  const { t } = useTranslation();
  return (
    <div className="rs-item-wrapper d-flex">
      <div className="user-info">
        <label className="user-name">
          {getFullName({
            firstName: item.first_name,
            lastName: item.last_name,
          })}
        </label>
      </div>
      <div>
        <span className="rs-status">
          {item.reject ? t(INSURED_STATUS.REJECTED) : t(INSURED_STATUS.ACCEPTED)}
        </span>
      </div>
    </div>
  );
};

ResultItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    dob: PropTypes.string,
    gender: PropTypes.string,
    reject: PropTypes.bool,
    relationship: PropTypes.string,
  }).isRequired,
};

export default ResultItem;
