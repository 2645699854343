/* eslint-disable react/no-danger */
import { Checkbox, CommonButton, FooterActions, Radio, ConfirmDialog, ToggleDescription, NotifyDialog } from 'components';
import { TITLES, LABELS } from 'constants/global';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Col, Form, Row, ProgressBar } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Swiper from 'react-id-swiper';
import MarkdownIt from 'markdown-it';
import { getFullName, isEmptyOrNull } from 'utils/common';
import './Questionnaire.scss';
import { MSG_0003 } from 'constants/messages';
import { icNote } from 'assets/images';

const OBJECT_ID_CHECK_ALL = {
  NO: 'no_all',
  YES: 'yes_all'
}

const mdParser = new MarkdownIt();
const Questionnaire = ({ children, questionnaire, member, onSubmit, answered, onGoBack }) => {
  const { t } = useTranslation();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmit1, setIsSubmit1] = useState(false);
  const { register, handleSubmit, errors, getValues } = useForm();
  const swiperRef = useRef();
  const formRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sumIndex, setSumIndex] = useState(0);
  const [curQuestionnaire, setCurQuestionnaire] = useState([]);
  const [quesIndex, setQuesIndex] = useState([]);
  const [progress, setProgress] = useState(0);
  const [disableButton, setDisableButton] = useState(true);
  const initDialog = {
    isShow: false,
    title: '',
    message: '',
    func: () => { },
    leftLabel: '',
    rightLabel: '',
    isShowLeftButton: false,
  };
  const [dialog, setDialog] = useState(initDialog);
  const [showNotify, setShowNotify] = useState(false);
  const htmlParser = (_html) => {
    return mdParser.render(_html);
  };

  const formSubmit = () => {
    const data = getValues();
    const params = [];
    Object.entries(data).map(([key, value]) => {
      if (key !== 'checkboxVerify') {
        return Object.entries(value).map(([k, v]) => {
          return params.push({
            question_id: key,
            personal_id: k,
            offered_answer_id: v,
          });
        });
      }
      return false;
    });
    onSubmit(params, errors);
  };

  const showDialog = async () => {
    setDialog({
      isShow: true,
      title: TITLES.CONFIRM,
      message: t('you_confirm_that_the_answers_are_correct'),
      func: () => formSubmit(),
      leftLabel: TITLES.CANCEL,
      rightLabel: t('ok'),
      isShowLeftButton: true,
    });
  };

  const mapAnswer = useCallback(dataAnswered => {
    dataAnswered.map((ans) => {
      const { questions } = ans;
      const personalId = ans?.personal_id;
      if (personalId && !isEmptyOrNull(questions)) {
        return questions.map((ques) => {
          const { answers } = ques;
          const answerId = !isEmptyOrNull(answers) ? answers[0]?.id || false : false;
          if (answerId) {
            const element = document.querySelector(
              `#ans_${ques?.id || ''}_${personalId}_${answerId}`
            );
            const hasValue = getValues(`${ques?.id}[${personalId}]`);
            if (element && !hasValue) element.click();
          }
          return false;
        });
      }
      return false;
    });
  }, [getValues]);

  useEffect(() => {
    if (!isEmptyOrNull(answered)) {
      setTimeout(() => mapAnswer(answered))
    }
  }, [answered, currentIndex, mapAnswer]);

  useEffect(() => {
    if (Array.isArray(questionnaire) && questionnaire.length > 0) {
      setSumIndex(questionnaire.length);
      setCurrentIndex(1);
      setCurQuestionnaire([questionnaire[0]]);
      setProgress(1);
      const indexQueue = questionnaire.map((item, index) => {
        return ({ index: index + 1, id: item?.id, notice: item?.notice });
      });
      setQuesIndex(indexQueue);
    }
  }, [questionnaire]);

  useEffect(() => {
    if (curQuestionnaire.length > 0 && currentIndex < curQuestionnaire.length) {
      setCurrentIndex(currentIndex + 1);
    }
  }, [curQuestionnaire, currentIndex]);

  const swipperParams = {
    on: {
      slideChange: swiper => setProgress(swiper.activeIndex + 1),
    },
    shouldSwiperUpdate: true,
  };

  const isValidCurrentSlide = useCallback(quesId => {
    const formValue = getValues();
    const currentValue = Object.entries(formValue).find(([k]) => Number(k) === quesId);
    if (Array.isArray(currentValue) && currentValue.length > 1) {
      let count = 0;
      member.map(i => {
        return currentValue[1].map((ii, idx) => {
          if (Number(idx) === i?.id && ii !== '') count += 1;
          return false;
        })
      });
      return count === member.length;
    }
    return false;
  }, [getValues, member]);

  const renderSubFooter = () => {
    return (
      <>
        <div className="section-confirm">
          <div className="confirm-content">
            <div className="pb-2">{t('we_warranty_and_declare_that')}</div>
            <Checkbox
              ellipsis={1}
              label={t('the_above_statement_are_true_and_correct_and')}
              name="checkboxVerify"
              ref={register({ required: true })}
              defaultChecked={isSubmit}
              onChange={(e) => setIsSubmit(e.target.checked)}
            />
            <Checkbox
              ellipsis={1}
              label={t('we_agree_to_consent_to_the_use_storage_and')}
              name="checkboxVerify"
              ref={register({ required: true })}
              defaultChecked={isSubmit1}
              onChange={(e) => setIsSubmit1(e.target.checked)}
            />
          </div>
        </div>
      </>
    )
  }

  const renderCheckAllRaido = (index, quesId) => {
    const id = curQuestionnaire[progress - 1]?.offer_answers[index]?.id;

    const data = getValues()[quesId];
    if (data) {
      Object.entries(data).forEach((item) => {
        const element = document.querySelector(
          `#ans_${quesId}_${item[0]}_${id}`
        );
        if (element) element.click();
      });
    }
  }

  const addClickButtonAll = (quesId, mode) => {
    const data = getValues()[quesId];
    let dataTemp = '';
    let flag = false
    Object.entries(data).forEach((item, i) => {
      if ((i !== 0 && dataTemp !== item[1]) || item[1] === '') {
        flag = true;
      }
      dataTemp = String(item[1]);
    });

    if (!flag) {
      const nameCheckAll = mode === OBJECT_ID_CHECK_ALL.NO ? OBJECT_ID_CHECK_ALL.NO : OBJECT_ID_CHECK_ALL.YES;
      const element = document.querySelector(
        `#${nameCheckAll}_${quesId}`
      );
      if (element) element.click();
    } else {
      const nameCheckAll = mode === OBJECT_ID_CHECK_ALL.NO ? OBJECT_ID_CHECK_ALL.YES : OBJECT_ID_CHECK_ALL.NO;
      const element = document.querySelector(
        `#${nameCheckAll}_${quesId}`
      );
      element.checked = false;
    }
  }

  const handleIndex = (index, quesId, mode) => {
    const isValid = isValidCurrentSlide(quesId);
    if (isValid) {
      setDisableButton(false);
      if (index + 1 === currentIndex && curQuestionnaire.length < sumIndex)
        setCurQuestionnaire([...curQuestionnaire, questionnaire[currentIndex]]);
    }

    if (member.length > 1) {
      addClickButtonAll(quesId, mode);
    }

    if (mode === OBJECT_ID_CHECK_ALL.YES && quesId === curQuestionnaire[progress - 1]?.id) {
      setShowNotify(true);
    }
  };

  useEffect(() => {
    const ques = quesIndex.find(i => i?.index === progress);
    const isValid = isValidCurrentSlide(ques?.id);
    if (isValid) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [progress, getValues, quesIndex, isValidCurrentSlide]);

  return (
    <div className="common-questionnaire-wrapper">
      <Form className="questionnaire-form" onSubmit={handleSubmit(() => showDialog())} ref={formRef}>
        <div className="progress-wrapper">
          <div className="d-flex flex-row col-12 p-0 justify-content-between header">
            <div className="title">{t('progress')}</div>
            <div className="number">{`${progress}/${sumIndex}`}</div>
          </div>
          <ProgressBar now={(progress / sumIndex) * 100} />
        </div>
        <Swiper {...swipperParams} ref={swiperRef}>
          {curQuestionnaire.length > 0 &&
            curQuestionnaire.map((item, index) => {
              return (
                <div key={`q_${index + 1}`} className="section-wrapper">
                  <div key={`k_${index + 1}`} className="section-content">
                    <div
                      className={
                        errors[`${item?.id}`] ? 'question-content warning' : 'question-content'
                      }
                    >
                      <span className="question-text">{item.name}</span>
                    </div>
                    {errors[`${item?.id}`] && (
                      <div className="error-content">{t(MSG_0003)}</div>
                    )}
                    <div className="option-wrapper">
                      <Row className="header">
                        <Col>{t('questionnaire_full_name')}</Col>
                        <Col className="answer-opt">
                          {t('answer_yes')}
                        </Col>
                        <Col className="answer-opt">
                          {t('answer_no')}
                        </Col>
                      </Row>
                      {(member.length > 1) &&
                        (
                          <Row className="body">
                            <Col className="all">{t('all')}</Col>
                            <Col className="answer-opt">
                              <Radio
                                id={`${OBJECT_ID_CHECK_ALL.YES}_${item?.id}`}
                                name={item?.id}
                                onChange={() => {
                                  renderCheckAllRaido(0, item?.id);
                                }}
                              />
                            </Col>
                            <Col className="answer-opt">
                              <Radio
                                id={`${OBJECT_ID_CHECK_ALL.NO}_${item?.id}`}
                                name={item?.id}
                                onChange={() => {
                                  renderCheckAllRaido(1, item?.id);
                                }}
                              />
                            </Col>
                          </Row>
                        )
                      }
                      {member.length > 0 &&
                        member.map((user, index1) => {
                          const ansYes = item?.offer_answers && item?.offer_answers[0]?.id;
                          const ansNo = item?.offer_answers && item?.offer_answers[1]?.id;

                          return (
                            <Row key={`k_${index1 + 1}`} className="body">
                              <Col>
                                {getFullName({
                                  firstName: user.first_name,
                                  lastName: user.last_name,
                                })}
                              </Col>
                              <Col>
                                <Radio
                                  id={`ans_${item?.id}_${user?.id}_${ansYes}`}
                                  name={`${item?.id}[${user?.id}]`}
                                  value={ansYes}
                                  onChange={() => {
                                    handleIndex(index, item?.id, OBJECT_ID_CHECK_ALL.YES)
                                  }}
                                  ref={register({ required: true })}
                                />
                              </Col>
                              <Col>
                                <Radio
                                  id={`ans_${item?.id}_${user?.id}_${ansNo}`}
                                  name={`${item?.id}[${user?.id}]`}
                                  value={ansNo}
                                  onChange={() => {
                                    handleIndex(index, item?.id, OBJECT_ID_CHECK_ALL.NO)
                                  }}
                                  ref={register({ required: true })}
                                />
                              </Col>
                            </Row>
                          );
                        })}
                    </div>
                  </div>
                  {item?.notice && (
                    <ToggleDescription
                      title={(
                        <div className="note-text">
                          <img className="alert-img" alt="" src={icNote} />
                          <span>{t('note')}</span>
                        </div>
                      )}
                    >
                      <div dangerouslySetInnerHTML={{ __html: htmlParser(item.notice) }} />
                    </ToggleDescription>
                  )}
                </div>
              );
            })}
        </Swiper>
        {progress === sumIndex && renderSubFooter()}
        {children}
        <FooterActions isBlur={false}>
          <CommonButton
            primary={false}
            label={t('back')}
            onPress={() => {
              if (progress <= 1) onGoBack();
              else swiperRef.current.swiper.slidePrev();
            }}
          />
          <CommonButton
            label={t(progress === sumIndex ? 'send_away' : 'answer')}
            type="button"
            disabled={(progress === sumIndex && (!isSubmit || !isSubmit1)) || disableButton}
            onClick={() => {
              if (progress < sumIndex && !disableButton) swiperRef.current.swiper.slideNext();
              else if (isSubmit && isSubmit1) formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
            }}
          />
        </FooterActions>
        <ConfirmDialog
          isShow={dialog.isShow}
          title={dialog.title}
          message={dialog.message}
          btnLeftClick={() => setDialog(initDialog)}
          btnRightClick={dialog.func}
          leftLabel={dialog.leftLabel}
          rightLabel={dialog.rightLabel}
          isShowLeftButton={dialog.isShowLeftButton}
        />
        <NotifyDialog
          isShow={showNotify}
          title={TITLES.NOTIFY}
          message={t('questionnaire_header_notice_text', { n: sumIndex })}
          okClick={() => setShowNotify(false)}
          leftBtn={LABELS.OK}
        />
      </Form>
    </div>
  );
};

Questionnaire.defaultProps = {
  children: '',
  questionnaire: [],
  member: [],
  answered: [],
};

Questionnaire.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  questionnaire: PropTypes.arrayOf(PropTypes.object),
  member: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  answered: PropTypes.arrayOf(PropTypes.object),
  onGoBack: PropTypes.func.isRequired,
};

export default Questionnaire;
