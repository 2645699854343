/* eslint-disable react/no-danger */
import { ToggleDescription } from 'components';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MarkdownIt from 'markdown-it';
import { YOUTUBE_INTRODUCE_URL, PRODUCTS_ID } from 'constants/global';
import './ProductDescription.scss';

const mdParser = new MarkdownIt();

const iframeStyle = {
  width: '100%',
  borderRadius: 6,
};

const iframeAtributtes = {
  style: iframeStyle,
  src: `${YOUTUBE_INTRODUCE_URL}?autoplay=1&mute=1`,
  frameBorder: 0,
  allow: 'autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; encrypted-media',
};

const civilInsurance = [PRODUCTS_ID.AUTOMOBILE, PRODUCTS_ID.MOTORBIKE];

const ProductDescription = ({ productData }) => {
  const { id, name: title, overview, description, image_path: imagePath } = productData;
  const { t } = useTranslation();

  const htmlParser = () => {
    const html = mdParser.render(description);
    return html;
  };

  return (
    <div className="pro-des-container">
      <h2 className="pro-des-title">{title}</h2>
      {civilInsurance.includes(id) ? (
        <>{imagePath && <img alt="" src={imagePath} />}</>
      ) : (
        <iframe title="introduce" {...iframeAtributtes} />
      )}
      <p>{overview}</p>
      {description && (
        <ToggleDescription title={t('product_detail')}>
          <div dangerouslySetInnerHTML={{ __html: htmlParser() }} />
        </ToggleDescription>
      )}
    </div>
  );
};

ProductDescription.propTypes = {
  productData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    overview: PropTypes.string,
    description: PropTypes.string,
    image_path: PropTypes.string,
  }).isRequired,
};

export default ProductDescription;
