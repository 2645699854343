import { iconAvatar, iconSetting } from 'assets/images';
import { setDrawer } from 'components/Header/headerSlice';
import PATH from 'constants/path';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getAge } from 'utils/common';
import './DrawerHeader.scss';

const DrawerHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.profile);

  const age = () => {
    if (profile.dob) {
      return `${getAge(profile.dob)}  ${t('years_old')}`;
    }
    return '';
  }

  return (
    <div className="drawer-header">
      <div className="ico-setting text-right">
        <NavLink exact to={PATH.PROFILE.VIEW_PROFILE} onClick={() => dispatch(setDrawer(false))}>
          <img alt="" src={iconSetting} />
        </NavLink>
      </div>
      <div className="ico-avt mx-auto d-none">
        <img alt="" src={iconAvatar} />
      </div>
      <div className="text-center user-info">
        <h1 className="name">{`${profile?.last_name || ''} ${profile?.first_name || ''}`}</h1>
        <p className="age">
          {age()}
        </p>
      </div>
      <div className="border-bottom" />
    </div>
  );
};

export default DrawerHeader;
