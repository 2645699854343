// core version + navigation, pagination modules:
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { setInterceptor } from 'api/axiosClient';
import GATracking from 'components/GaTracking';
import PATH from 'constants/path';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { debounce, isEmpty } from 'lodash';
import { isAuthenticated } from 'utils/common';
import { TIME_LOADING } from 'constants/global';
import { useTranslation } from 'react-i18next';
import { Alert, DynamicScrollToTop, Header, Loading, NotFound } from './components';
import Home from './features/Home';
import Login from './features/Login';
import featuresPage from './routes';
import './App.scss';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

const PrivateRoute = ({ component, ...rest }) => {
  const Component = component;

  /**
   * Check user is logged in
   */
  return isAuthenticated() ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : (
    <Route
      {...rest}
      render={(props) => <Redirect to={{ pathname: `/login`, state: { from: props.location } }} />}
    />
  );
};

PrivateRoute.defaultProps = {
  location: '',
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading } = useSelector((state) => state.app.appLoading);
  const [rendered, setRendered] = useState(false);
  const [loading, setLoading] = useState(true);
  const myRef = useRef(null);
  // useNavigate(history);

  // fixed header when scroll top .. start
  window.addEventListener('scroll', () => {
    const classname = document.getElementsByClassName('header')[0].className.split(' ');
    if (window.scrollY > 200 && !classname.includes('active')) {
      if (!classname.includes('acitve')) {
        classname.push('active');
      }
    } else if (window.scrollY < 50) {
      const index = classname.findIndex((x) => x === 'active');
      if (index > -1) {
        classname.splice(index, 1);
      }
    }
    let result = '';
    classname.forEach((item) => {
      if (!isEmpty(item)) result += `${item} `;
    });
    document.getElementsByClassName('header')[0].className = result;
  });
  // fixed header when scroll top .. end

  const debounceLoading = useRef(
    debounce((value) => {
      if (loading && !value) setLoading(false);
    }, TIME_LOADING)
  );

  useEffect(() => {
    if (!loading && isLoading) setLoading(true);
    debounceLoading.current(isLoading);
  }, [isLoading, loading]);

  useEffect(() => {
    (async function settingApp() {
      await setInterceptor(dispatch, history);
      setRendered(true);
    })();
  }, [dispatch, history]);

  return (
    <>
      {rendered && (
        <div ref={myRef} className="h-100">
          <HashRouter>
            <DynamicScrollToTop />
            <Loading show={loading} />
            <Suspense fallback={<div>{t('loading')}</div>}>
              <Header />
              <main>
                <Alert />
                {process.env.REACT_APP_GA && <GATracking />}
                <Switch>
                  <PrivateRoute exact path="/" component={Home} />
                  {featuresPage.map((item, key) => (
                    <PrivateRoute
                      key={`idx${key + 1}`}
                      path={item.path}
                      component={item.component}
                    />
                  ))}
                  <Route exact path={PATH.LOGIN} component={Login} />
                  <Route component={NotFound} />
                </Switch>
              </main>
            </Suspense>
          </HashRouter>
        </div>
      )}
      {!rendered && <div>{t('loading')}</div>}
    </>
  );
};

export default App;
