import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userAPI from 'api/userAPI';
import { KEY_STORAGE } from 'constants/global';

const login = createAsyncThunk('LOGIN', async (params, { rejectWithValue }) => {
  try {
    const res = await userAPI.login(params);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const initialState = {
  user: {},
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},

  extraReducers: {
    [login.pending]: (state) => {
      state.user = {};
    },
    [login.fulfilled]: (state, { payload }) => {
      localStorage.setItem(KEY_STORAGE.TM_TOKEN, payload?.value);
      state.user = payload;
    },
  },
});

const { reducer } = loginSlice;
export { login };
export default reducer;
