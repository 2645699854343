import axiosClient from './axiosClient';

const orderAPI = {
  deleteProduct: (param) => {
    const url = `/order/${param.order_id}/product/${param.product_id}`;
    return axiosClient.delete(url);
  },
  createOrderId: () => {
    const url = `/order`;
    return axiosClient.post(url);
  },
  getListInsured: (id, params) => {
    const url = `/order/${id}/personal`;
    return axiosClient.get(url, params);
  },
  registerInsuredPerson: (id, params) => {
    const url = `/order/${id}/personal`;
    return axiosClient.post(url, params);
  },
  getInsuredPerson: (id, personalId) => {
    const url = `/order/${id}/personal/${personalId}`;
    return axiosClient.get(url);
  },
  updateInsuredPerson: (id, personalId, params) => {
    const url = `/order/${id}/personal/${personalId}`;
    return axiosClient.put(url, params);
  },
  deleteInsuredPerson: (id, personalId) => {
    const url = `/order/${id}/personal/${personalId}`;
    return axiosClient.delete(url);
  },
  getOrderDetailByOrderId: (orderId) => {
    const url = `/order/${orderId}/product`;
    return axiosClient.get(url);
  },
  validateAcceptedList: ({ orderId, productId }) => {
    const url = `/order/${orderId}/product/${productId}/validation`;
    return axiosClient.get(url);
  },
  registerPlanAndCoverage: ({ orderId, params }) => {
    return axiosClient.post(`/order/${orderId}/product`, params);
  },
  orderComplate: (orderId) => {
    return axiosClient.patch(`/order/${orderId}/complete`);
  },
  registerInsuredInfo: (params) => {
    return axiosClient.post(`/order/${params.order_id}/automobile`, params.data);
  },
  updateInsuredInfo: (params) => {
    const url = `/order/${params.order_id}/automobile`;
    return axiosClient.put(url, params.data);
  },
  getOrderId: () => {
    return axiosClient.get('/order');
  },
  getStatusPA2: () => {
    return axiosClient.get('/order/pa2');
  },
  registerPA2: () => {
    return axiosClient.post('/order/pa2');
  },
  createAddressInsurance: (params) => {
    return axiosClient.post(`/order/${params.order_id}/home`, params.data);
  },
  updateAddressInsurance: (params) => {
    return axiosClient.put(`/order/${params.order_id}/home`, params.data);
  },
  getInsuredHomeliability: (orderId) => {
    return axiosClient.get(`/order/${orderId}/home`);
  },
  getSoldCount: () => {
    return axiosClient.get(`/order/product/sold`);
  },
  getProductPurchased: () => {
    return axiosClient.get(`/order/product/purchased`);
  },
  getEffectDateOfSuperInsurance: (id) => {
    return axiosClient.get(`/order/${id}/super-insurance/effected-date`);
  },
  updateEffectDateOfSuperInsurance: (id, params) => {
    return axiosClient.patch(`/order/${id}/super-insurance/effected-date`, params);
  },
  getListHistoryInsuredPerson: () => {
    return axiosClient.get('/order/history/insured');
  },
  getPremiumsOfPlansByProductId: (params) => {
    return axiosClient.get(`/order/${params.order_id}/product/${params.product_id}/pricing`, {
      params: { optional_coverage_ids: params.optional_coverage_ids },
    });
  },
};

export default orderAPI;
