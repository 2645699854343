import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getJsonObject, formatDate } from 'utils/common';
import { ACTION_EVENT, ORDER_ID, DATE_FORMAT } from 'constants/global';
import motorbikeAPI from 'api/motorbikeAPI';
import productAPI from 'api/productAPI';

const saveInputInfo = createAsyncThunk(
  'MOTORBIKE_SAVE_INPUT_INFO',
  async (arg, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID) || false;
      const { action } = arg;
      if (!orderId || !action) return rejectWithValue({ errors: 'Invalid parameter' });
      const submitData = {
        first_name: arg?.first_name,
        last_name: arg?.last_name,
        tel: arg?.phone,
        street: arg?.street,
        ward_id: arg?.ward_id,
        plate: arg?.license_plate,
        capacity: 'lt_50cc',
        coverage_id: arg?.vehicle_type,
        engine_number: arg?.engine_number,
        chassis_number: arg?.chassis_number,
        effected_start_date: formatDate(arg?.from_date, DATE_FORMAT.DATETIME) || '',
        // "effected_end_date": formatDate(arg?.to_date, DATE_FORMAT.DATETIME) || ''
      };
      if (action === ACTION_EVENT.CREATE) {
        return motorbikeAPI.createMotorBikeInsured(orderId, submitData);
      }
      if (action === ACTION_EVENT.UPDATE) {
        return motorbikeAPI.updateMotorBikeInsured(orderId, submitData);
      }
      return rejectWithValue({ errors: 'API not found' });
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const getInsuredInfo = createAsyncThunk(
  'MOTORBIKE_GET_INSURED_INFO',
  async (param, { rejectWithValue }) => {
    try {
      const res = await motorbikeAPI.getInsuredInfo(param);
      return res;
    } catch (errors) {
      return rejectWithValue({ errors });
    }
  }
);

const getProductByProductId = createAsyncThunk(
  'MOTORBIKE_GET_PRODUCT_BY_PRODUCT_ID',
  async (param, { rejectWithValue }) => {
    try {
      const res = await productAPI.getProductByProductId(param.product_id);
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const registerDelivery = createAsyncThunk(
  'AUTOMOBILE_REGISTER_DELIVERY',
  async (params, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const res = await productAPI.registerDelivery({ orderId, params });
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const getDelivery = createAsyncThunk(
  'AUTOMOBILE_GET_DELIVERY',
  async (params, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const res = await productAPI.getDelivery(orderId);
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const updateDelivery = createAsyncThunk(
  'AUTOMOBILE_UPDATE_DELIVERY',
  async (params, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const res = await productAPI.updateDelivery({ orderId, params });
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const initialState = {
  loading: false,
  errors: null,
  product: null,
  insuredInfo: null,
  delivery: null,
};

const motorbike = createSlice({
  name: 'motorbike',
  initialState,
  reducers: {
    resetInsuredInfo: {
      reducer: (state) => {
        state.insuredInfo = null;
      },
    },
    setProduct: {
      reducer: (state, action) => {
        state.product = action.payload;
      },
    },
    setDelivery: {
      reducer: (state, action) => {
        state.delivery = action.payload;
      },
    },
  },
  extraReducers: {
    [getInsuredInfo.pending]: (state) => {
      state.insuredInfo = null;
      state.errors = null;
    },
    [getInsuredInfo.fulfilled]: (state, { payload }) => {
      state.insuredInfo = payload;
    },
    [getInsuredInfo.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
    [saveInputInfo.fulfilled]: (state) => {
      state.loading = false;
    },
    [saveInputInfo.pending]: (state) => {
      state.loading = true;
    },
    [saveInputInfo.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.errors;
    },
    [getProductByProductId.pending]: (state) => {
      state.product = null;
    },
    [getProductByProductId.fulfilled]: (state, { payload }) => {
      state.product = payload;
    },
    [getProductByProductId.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
    [getDelivery.pending]: (state) => {
      state.delivery = null;
    },
    [getDelivery.fulfilled]: (state, { payload }) => {
      state.delivery = payload;
    },
    [getDelivery.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
  },
});

const { reducer, actions } = motorbike;
export {
  getInsuredInfo,
  saveInputInfo,
  getProductByProductId,
  registerDelivery,
  getDelivery,
  updateDelivery,
};
export const { resetInsuredInfo, setProduct, setDelivery } = actions;
export default reducer;
