/* eslint-disable react/require-default-props */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import './BasicItem.scss';
import { useTranslation } from 'react-i18next';
import { isArray, formatPrice } from 'utils/common';


function BasicItem({ plans, selected }) {
  const { t } = useTranslation();

  const findCoverages = (id) => {
    return plans.find(x => x.id === id).coverages;
  }

  const filterCoveragesBasic = (coverages) => {
    return coverages.filter(x => x.optional === false);
  }

  const filterBasic = (objectBasic, flagInsured) => {
    const listBasic = [];
    if (isArray(objectBasic, 0)) {
      objectBasic.forEach(item => {
        if (item.sub_coverages) {
          listBasic.push(...item.sub_coverages.filter(x => x.adult === flagInsured))
        } else if (item.adult === flagInsured) {
          listBasic.push(item);
        }
      })
    }
    return listBasic;
  }

  const objectBasicFuc = (id) => {
    return filterCoveragesBasic(findCoverages(id))
  }

  const renderChild = (idPlan, flagInsured, idBasic) => {
    const objectBasic = objectBasicFuc(idPlan);
    const objectBasicItem = filterBasic(objectBasic, flagInsured);
    const basicItem = objectBasicItem.find(x => x.id === idBasic);

    if (basicItem) {
      return (
        <div className={`price ${selected === idPlan ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: formatPrice(basicItem.sum_insured, t) }} />
      )
    }
    return "";
  }

  const renderBox = (flagInsured) => {
    const objectBasic = objectBasicFuc(plans[0].id);
    const objectBasicItem = filterBasic(objectBasic, flagInsured);
    if (objectBasicItem && objectBasic.length > 0) {
      return (
        <div className="box-benefit">
          <>
            {
              objectBasicItem.map(item => (
                <div className="box-title-and-price-benefit" key={item.id}>
                  <div className="title-benefit">
                    {t(item.name)}
                  </div>
                  <div className="d-flex justify-content-between text-center">
                    {
                      plans.map((child) => (
                        <div className="box-price" key={child.id}>
                          {renderChild(child.id, flagInsured, item.id)}
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </>
        </div>
      );
    }
    return "";
  }

  const renderLayout = () => {
    if (plans) {
      return (
        <div className="benefit-item">
          <div className="title">
            {t("base_benefit")}
          </div>
          <p className="title-object">
            {t('adult')}
          </p>
          {renderBox(true)}
          <p className="title-object">
            {t('children')}
          </p>
          {renderBox(false)}
        </div>
      )
    }
    return "";
  }

  return (
    renderLayout()
  )
}

BasicItem.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.number.isRequired
};

export default BasicItem;

