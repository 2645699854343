import PropTypes from 'prop-types';
import React from 'react';
import './CommonButton.scss';

const CommonButton = ({ className, onPress, label, primary, disabled, fullWidth, ...props }) => {
  const color = primary ? 'primary' : 'outline-primary';
  const btnClass = `btn-base btn-base-${color} ${disabled ? `disabled-${color}` : ''} ${
    fullWidth ? 'btn-full-width' : ''
    }`;
  return (
    <button
      className={`${btnClass} ${className}`}
      onClick={onPress}
      tabIndex="0"
      type="button"
      {...props}
    >
      {label}
    </button>
  );
};

CommonButton.defaultProps = {
  className: '',
  disabled: false,
  fullWidth: true,
  primary: true,
  onPress: undefined,
};

CommonButton.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  primary: PropTypes.bool,
};

export default CommonButton;
