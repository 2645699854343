import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import locationAPI from 'api/locationAPI';
import masterAPI from 'api/masterAPI';
import userAPI from 'api/userAPI';

const getProvinces = createAsyncThunk('GET_PROVINCE', async (_arg, { rejectWithValue }) => {
  try {
    const res = await locationAPI.getProvinces();
    return res;
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

const getDistricts = createAsyncThunk('GET_DISTRICT', async (arg, { rejectWithValue }) => {
  try {
    const { provinceId } = arg;
    const res = await locationAPI.getDistricts(provinceId);
    return res;
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

const getWards = createAsyncThunk('GET_WARD', async (arg, { rejectWithValue }) => {
  try {
    const { provinceId, districtId } = arg;
    const res = await locationAPI.getWards({ provinceId, districtId });
    return res;
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

const getNationalities = createAsyncThunk('GET_NATIONALITIES', async (arg, { rejectWithValue }) => {
  try {
    const res = await locationAPI.getNationalites();
    return res;
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

const getOccupation = createAsyncThunk('GET_OCCUPATION', async (_arg, { rejectWithValue }) => {
  try {
    const res = await userAPI.getOccupation();
    return res?.occupations;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const agreeTermsAndConditions = createAsyncThunk(
  'AGREE_TERM_AND_CONFITION',
  async (_arg, { rejectWithValue }) => {
    try {
      const res = await userAPI.agreeTermsAndConditions();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getStore = createAsyncThunk('GET_Store', async (arg, { rejectWithValue }) => {
  try {
    const res = await masterAPI.getStore();
    return res;
  } catch (errors) {
    return rejectWithValue(errors);
  }
});

const initialState = {
  location: {
    provinces: [],
    districts: [],
    wards: [],
    user: {},
  },
  nationalities: [],
  notify: {
    isLoading: true,
    messageCode: '',
    type: '',
  },
  appLoading: {
    isLoading: false,
    count: 0,
  },
  errors: [],
  loginError: [],
  occupations: [],
  stores: [],
  provinceId: -1,
};

const location = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLoading: {
      reducer: (state, action) => {
        if (action.payload) {
          state.appLoading.count += 1;
          state.appLoading.isLoading = true;
        } else if (state.appLoading.count > 0) {
          state.appLoading.count -= 1;
          state.appLoading.isLoading = state.appLoading.count > 0;
        }
      },
    },
    notifyMessage: {
      reducer: (state, action) => {
        if (action.payload?.messageCode) {
          state.notify.messageCode = action.payload?.messageCode;
          state.notify.type = action.payload?.type;
          state.notify.isLoading = true;
        } else {
          state.notify.isLoading = false;
          state.notify.messageCode = '';
          state.notify.type = '';
        }
      },
    },
    setProvinceId: {
      reducer: (state, action) => {
        state.provinceId = action.payload;
      },
    },
  },
  extraReducers: {
    [getProvinces.fulfilled]: (state, action) => {
      state.location.provinces = action.payload;
      state.loading = false;
      state.errors = [];
    },
    [getProvinces.pending]: (state) => {
      state.loading = true;
      state.location.provinces = [];
      state.location.districts = [];
      state.wards = [];
    },
    [getProvinces.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getDistricts.fulfilled]: (state, action) => {
      state.location.districts = action.payload;
      state.loading = false;
      state.errors = [];
    },
    [getDistricts.pending]: (state) => {
      state.loading = true;
      state.location.districts = [];
      state.location.wards = [];
    },
    [getDistricts.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getWards.fulfilled]: (state, action) => {
      state.location.wards = action.payload;
      state.loading = false;
      state.errors = [];
    },

    [getWards.pending]: (state) => {
      state.loading = true;
      state.location.wards = [];
    },
    [getWards.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getNationalities.fulfilled]: (state, action) => {
      state.nationalities = action.payload;
      state.loading = false;
      state.errors = [];
    },
    [getNationalities.pending]: (state) => {
      state.loading = true;
      state.location.nationalities = [];
    },
    [getNationalities.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getOccupation.pending]: (state) => {
      state.occupations = [];
    },
    [getOccupation.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [getOccupation.fulfilled]: (state, action) => {
      state.occupations = action.payload;
      state.errors = [];
    },
    [agreeTermsAndConditions.fulfilled]: (state) => {
      state.errors = [];
    },
    [agreeTermsAndConditions.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [getStore.pending]: (state) => {
      state.stores = [];
    },
    [getStore.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [getStore.fulfilled]: (state, action) => {
      state.stores = action.payload;
      state.errors = [];
    },
  },
});

const { reducer, actions } = location;
const { setLoading, notifyMessage, setProvinceId } = actions;
export {
  getProvinces,
  getDistricts,
  getWards,
  setLoading,
  notifyMessage,
  getNationalities,
  getOccupation,
  agreeTermsAndConditions,
  getStore,
  setProvinceId,
};
export default reducer;
