import axiosClient from './axiosClient';

const productAPI = {
  getProductByProductId: (id) => {
    return axiosClient.get(`/product/${id}`, {
      transformRequest: [
        (data, headers) => {
          delete headers.Authorization;
          return data;
        },
      ],
    });
  },
  updatePlanAndCoverage: (param) => {
    return axiosClient.put(`/order/${param.order_id}/product/${param.product_id}`, param.data);
  },
  getProducts: () => {
    return axiosClient.get(`/product`);
  },
  getRecommendation: () => {
    return axiosClient.get(`/recommendation`);
  },
  registerDelivery: ({ orderId, params }) => {
    return axiosClient.post(`order/${orderId}/delivery`, params);
  },
  getDelivery: (orderId) => {
    return axiosClient.get(`order/${orderId}/delivery`);
  },
  updateDelivery: ({ orderId, params }) => {
    return axiosClient.put(`order/${orderId}/delivery`, params);
  },
  getComboList: () => {
    return axiosClient.get('/product/combo');
  },
};

export default productAPI;
