/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormSelectBox } from 'components';
import { getNationalities } from 'app/appSlice';
import { useTranslation } from 'react-i18next';

const NationalityBox = forwardRef(({ ...props }, ref) => {
  const dispatch = useDispatch();
  const nationalities = useSelector((state) => state.app.nationalities);
  const [value, setValue] = useState(props?.value);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getNationalities());
  }, [dispatch, t]);

  useEffect(() => {
    const nationalityId = nationalities[0]?.id;
    if (nationalityId) setValue(nationalityId);
  }, [nationalities]);

  const handleChange = (event) => {
    setValue(Number(event?.target?.value));
  };

  useImperativeHandle(ref, () => ({
    setImperativeValue(value) {
      setValue(value);
    },
  }));

  return (
    <FormSelectBox {...props} ref={ref} onChange={handleChange} value={value}>
      {nationalities.map((item, index) => (
        <option key={`nationality_${index + 1}`} value={item.id}>
          {item.value}
        </option>
      ))}
    </FormSelectBox>
  );
});

export default NationalityBox;
