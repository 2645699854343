import { CommonButton, Checkbox } from 'components';
import { getFullName, isArray } from 'utils/common';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { findIndex } from 'lodash';
import './HistoryInsuredPersonDialog.scss';

const HistoryInsuredPersonDialog = ({ isShow, onCancel, onAccept, insuredListPerson }) => {
  const { t } = useTranslation();
  const [personList, setPersonList] = useState(insuredListPerson);

  const submitHandler = () => {
    onAccept(personList);
  };

  const onCheck = (event) => {
    const { id, checked } = event.target;
    const listTemp = [...personList];
    const index = findIndex(listTemp, (person) => Number(person.id) === Number(id));
    listTemp[index] = { ...personList[index], selected: checked };
    setPersonList(listTemp);
  };

  useEffect(() => {
    setPersonList(insuredListPerson);
  }, [insuredListPerson]);

  return (
    <>
      <Modal
        centered
        show={isShow}
        keyboard={false}
        animation={false}
        className="history-wrapper"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title className="history-title mx-auto mt-2">
            {t('history_member_list')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="history-content">
          <div className="history-listouter">
            {(personList || []).map((person) => (
              <div key={person?.id} className="history-person-item">
                <Checkbox
                  checked={person.selected || false}
                  name="selectedPersons"
                  id={person?.id || '-'}
                  value={person?.id}
                  label={getFullName({ firstName: person.first_name, lastName: person.last_name })}
                  onChange={(event) => onCheck(event)}
                />
              </div>
            ))}
            {!isArray(personList, 0) && <p className="no-data">{t('no_data')}</p>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="tm-popup-footer">
            <CommonButton
              primary={false}
              type="button"
              className="ml-0 mr-2"
              label={t('cancel')}
              onPress={onCancel}
            />
            <CommonButton
              type="button"
              className="ml-0"
              label={t('agree')}
              onPress={submitHandler}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

HistoryInsuredPersonDialog.defaultProps = {};

HistoryInsuredPersonDialog.propTypes = {
  isShow: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  insuredListPerson: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default HistoryInsuredPersonDialog;
