import ReactGA from 'react-ga';
import { isEmptyOrNull } from 'utils/common';

const TRACKING_ID = process.env.REACT_APP_GA;
const enable = !isEmptyOrNull(TRACKING_ID);

function init() {
  /** Enable debug mode only on the local dev environment */
  // const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  ReactGA.initialize(TRACKING_ID, { debug: false });
}

/**
 * Send Event
 * @param {*} path
 */
function sendEvent(payload) {
  ReactGA.event(payload);
}

/**
 * Send Page View
 * @param {*} path
 */
function sendPageview(path) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export default {
  init,
  sendEvent,
  sendPageview,
  enable,
};
