import { notifyMessage } from 'app/appSlice';
import { setDrawer } from 'components/Header/headerSlice';
import { getProfile } from 'features/Profile/profileSlice';
import i18n from 'app/i18n';
import PATH from 'constants/path';
import { NotifyDialog } from 'components';
import { icLogout, iconAeon } from 'assets/images';
import { ALERT_TYPE, KEY_STORAGE, LABELS, OBJECT_LANGUAGE, TITLES } from 'constants/global';
import { isAuthenticated, isDev, removeStorageAndRedirectLogin } from 'utils/common';
import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import './DrawerMenu.scss';

const listMenu = [
  { id: 1, title: 'title_home', redirectTo: PATH.HOME, classname: 'icon-icon-home' },
  // { id: 2, title: 'title_product', redirectTo: PATH.PRODUCT_LIST, classname: 'icon-icon-product', gotoLink: false },
  // {
  //   id: 4,
  //   title: 'title_promotion',
  //   redirectTo: '/c',
  //   classname: 'icon-icon-promotion',
  //   gotoLink: false,
  // },
  {
    id: 5,
    title: 'title_contract',
    redirectTo: PATH.CONTRACT.MAIN,
    classname: 'icon-icon-contract',
  },
  {
    id: 6,
    title: 'title_indemnify',
    redirectTo: PATH.CLAIM.MAIN,
    classname: 'icon-icon-indemnify',
  },
  { id: 7, title: 'title_qes_ans', redirectTo: PATH.QA.MAIN, classname: 'icon-icon-qes-ans' },
  { id: 8, title: 'title_rules', redirectTo: PATH.TERMS, classname: 'icon-icon-rules' },
];

const DrawerMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((app) => app.login);
  const changeLanguage = (lng) => {
    if (lng !== i18next.language) {
      i18n.changeLanguage(lng);
      localStorage.setItem(KEY_STORAGE.LANGUAGE, lng);
      dispatch(setDrawer(false));
    }
  };

  const [showNotify, setShowNotify] = useState(false);

  const handleLogout = () => {
    removeStorageAndRedirectLogin({ dispatch, setDrawer });
  };

  const getActiveLanguage = (lng) => {
    return lng === i18next.language ? 'active' : '';
  };

  const redirectTo = (item, e) => {
    if (item?.gotoLink === false) {
      e.preventDefault();
      setShowNotify(true);
    }
  };

  useEffect(() => {
    (async function settingApp() {
      if (isAuthenticated()) {
        const userResponse = await dispatch(getProfile());
        const { status } = userResponse?.payload || {};
        if (status === 404 || (isDev && status === 'UNKNOW')) {
          window.location.replace(`#${PATH.PROFILE.EDIT_PROFILE}`);
          setTimeout(() => {
            dispatch(
              notifyMessage({ messageCode: 'please_update_your_info', type: ALERT_TYPE.NOTIFY })
            );
          });
        } else if (userResponse.payload && userResponse.payload.terms_agreement === false) {
          window.location.replace(`#${PATH.TERMS}`);
        } else if (status) {
          removeStorageAndRedirectLogin({ dispatch, setDrawer });
        }
      }
    })();
  }, [dispatch, location, user, t]);
  return (
    <div className="menu-sidebar">
      {listMenu.map((item) => (
        <div className="item-menu d-flex" key={item.id}>
          <NavLink
            className="link"
            exact
            to={item.redirectTo}
            onClick={(e) => {
              redirectTo(item, e);
              dispatch(setDrawer(false));
            }}
          >
            {t(item.title)}
            <span className={`${item.classname}`} />
          </NavLink>
        </div>
      ))}
      <div className="logout d-flex" role="button" onClick={() => handleLogout()}>
        <div className="logout-icon">
          <img src={icLogout} alt="" />
        </div>
        <div className="label">{t('logout')}</div>
      </div>
      <ul className="nav nav-tabs lng-tab">
        <li className={`nav-item ${getActiveLanguage(OBJECT_LANGUAGE.VI)}`}>
          <button
            onClick={() => changeLanguage(OBJECT_LANGUAGE.VI)}
            type="button"
            className="nav-link"
          >
            VI
          </button>
        </li>
        <li className={`nav-item ${getActiveLanguage(OBJECT_LANGUAGE.EN)}`}>
          <button
            onClick={() => changeLanguage(OBJECT_LANGUAGE.EN)}
            type="button"
            className="nav-link"
          >
            EN
          </button>
        </li>
      </ul>
      <div className="box-footer">
        <div className="version">{`Version ${process.env.REACT_APP_VERSION}` || ''}</div>
        <div className="icon-footer">
          <img src={iconAeon} alt="" />
        </div>
      </div>
      <NotifyDialog
        isShow={showNotify}
        title={TITLES.NOTIFY}
        message="feature_are_developed"
        okClick={() => setShowNotify(false)}
        leftBtn={LABELS.OK}
      />
    </div>
  );
};

export default DrawerMenu;
