import {
  Checkbox,
  CommonButton,
  CustomDatePicker,
  DistrictBox,
  FormInput,
  FormSelectBox,
  NationalityBox,
  OccupationBox,
  ProvinceBox,
  Radio,
  Title,
  WardBox,
} from 'components';
import FooterActions from 'components/FooterActions';
import {
  DATE_FORMAT,
  EMAIL_MATCH,
  GENDER,
  MARRIED,
  PHONE_MATCH,
  RELATIONSHIP,
  UPDATE_TIMES,
  VALIDATION,
} from 'constants/global';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { formatDate, useSetAddress } from 'utils/common';
import './InputInfo.scss';

const RELATIONSHIPS = [
  RELATIONSHIP.ACCOUNT_OWNER,
  RELATIONSHIP.PARENTS,
  RELATIONSHIP.SPOUSE,
  RELATIONSHIP.CHILD,
  RELATIONSHIP.GRANDPARENTS,
  RELATIONSHIP.GRANDCHILDREN,
  RELATIONSHIP.SIBLINGS,
  RELATIONSHIP.ADOPTED,
  RELATIONSHIP.CHILD_IN_LAW,
];

const MARRIED_LIST = [
  MARRIED.SINGLE,
  MARRIED.MARRIED,
  MARRIED.REMARRIED,
  MARRIED.SEPARATED,
  MARRIED.DIVORCED_WIDOWED,
];

const InputInfo = forwardRef(
  (
    { children, onSubmit, leftBtn, rightBtn, leftClick, showRelationship, isNew, dataProvider },
    ref
  ) => {
    const { t } = useTranslation();
    const { register, handleSubmit, errors, reset, setValue } = useForm();
    const btnSubmit = useRef();
    const [isUseOwnerInfo, setIsUseOwnerInfo] = useState(false);
    const [overUpdateTime, setOverUpdateTime] = useState(false);
    const dispatch = useDispatch();
    const [seletedDate, setSeletedDate] = useState(formatDate(new Date(), DATE_FORMAT.DATE));
    const provinceRef = useRef();
    const districtRef = useRef();
    const wardRef = useRef();
    const nationalityRef = useRef();
    const occupationRef = useRef();

    let defaultData = {
      id: '',
    };

    if (!isNew) defaultData = dataProvider;
    useImperativeHandle(ref, () => ({
      /**
       * Trigger submit from parent
       */
      callSubmit() {
        btnSubmit.current.click();
      },

      /**
       * Trigger clear form from parent
       */
      reset() {
        reset();
      },
    }));

    /**
     * Set form inital data
     * when isNew : False
     */
    useEffect(() => {
      const initForm = () => {
        const nationalityId = defaultData.nationality?.id;
        setValue('relationship', defaultData.relationship);
        setValue('first_name', defaultData.first_name);
        setValue('last_name', defaultData.last_name);
        setValue('gender', defaultData.gender);
        setValue('id_card', defaultData.id_card);
        setValue('phone', defaultData.phone);
        setValue('email', defaultData.email);
        setValue('married', defaultData.married || MARRIED_LIST[0]);
        setValue('street', defaultData.address?.street);
        if (defaultData.dob) setSeletedDate(defaultData.dob);
        if (nationalityId) nationalityRef.current.setImperativeValue(nationalityId);
        occupationRef.current.setImperativeValue(defaultData?.occupation);
      };

      if (!isNew) {
        setTimeout(() => initForm());
        if (defaultData?.update_times && defaultData.update_times >= UPDATE_TIMES) {
          setOverUpdateTime(true);
        }
      } else {
        setValue('married', MARRIED_LIST[0]);
      }
    }, [setValue, defaultData, dispatch, isNew]);

    const useContractOwnerInfo = async () => {
      setIsUseOwnerInfo(false);
    };

    /**
     * Format Input
     * @param {*} event
     */
    const formatInput = (event) => {
      const { name, value } = event.target;
      setValue(name, value.trim());
    };

    const handlePaste = (e) => {
      const str = e.clipboardData.getData('Text');
      e.target.value = str.slice(0, VALIDATION.CUSTOMER.PHONE_MAX_LENGTH);
      e.preventDefault();
    };

    useSetAddress(
      defaultData?.address?.province?.id,
      defaultData?.address?.district?.id,
      defaultData?.address?.ward?.id,
      { provinceRef, districtRef, wardRef }
    );

    return (
      <div ref={ref}>
        <Form
          onSubmit={handleSubmit((data) => {
            onSubmit(data, errors, overUpdateTime);
          })}
          className="ii-wrapper"
          noValidate
        >
          {/* PERSONAL */}
          <Row>
            <Title className="w-100 ii-col-custom pf-item-1" name={t('personal')} />
          </Row>
          {showRelationship && (
            <Row>
              <Col>
                <FormSelectBox
                  label={t('relationship_with_owner')}
                  name="relationship"
                  ref={register({ required: t('this_field_is_required') })}
                >
                  {RELATIONSHIPS.map((item, index) => (
                    <option key={`k_${index + 1}`} value={item}>
                      {t(item)}
                    </option>
                  ))}
                </FormSelectBox>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <FormInput
                name="last_name"
                label={t('last_name')}
                ref={register({
                  required: t('this_field_is_required'),
                })}
                onBlur={(e) => formatInput(e)}
                error={errors?.last_name?.message}
                controlId="last_name"
                disabled={overUpdateTime}
                maxLength={VALIDATION.CUSTOMER.LAST_NAME_MAX_LENGTH}
              />
            </Col>
            <Col>
              <FormInput
                label={t('first_name')}
                name="first_name"
                error={errors?.first_name?.message}
                ref={register({
                  required: t('this_field_is_required'),
                })}
                onBlur={(e) => formatInput(e)}
                controlId="first_name"
                disabled={overUpdateTime}
                maxLength={VALIDATION.CUSTOMER.FIRST_NAME_MAX_LENGTH}
              />
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>{t('gender')}</Form.Label>
            <Row>
              <Col className="d-flex d-inline-block ii-radio-group">
                <Radio
                  type="radio"
                  name="gender"
                  value={GENDER.MALE}
                  id={GENDER.MALE}
                  label={t(GENDER.MALE)}
                  ref={register({ required: t('this_field_is_required') })}
                  disabled={overUpdateTime}
                />
                <Radio
                  type="radio"
                  name="gender"
                  value={GENDER.FEMALE}
                  id={GENDER.FEMALE}
                  label={t(GENDER.FEMALE)}
                  defaultChecked={defaultData.gender === GENDER.FEMALE}
                  ref={register({ required: t('this_field_is_required') })}
                  disabled={overUpdateTime}
                />
              </Col>
            </Row>
            <p className="text-danger m-0 p-0">{errors?.gender?.message}</p>
          </Form.Group>

          <Row>
            <Col>
              <FormInput
                label={t('cmnd/cccd')}
                type="text"
                name="id_card"
                error={errors?.id_card?.message}
                ref={register({
                  required: t('this_field_is_required'),
                })}
                onBlur={(e) => formatInput(e)}
                disabled={overUpdateTime}
                controlId="id_card"
                maxLength={VALIDATION.CUSTOMER.ID_CARD_MAX_LENGTH}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OccupationBox
                label={t('occupation')}
                name="occupation"
                disabled={overUpdateTime}
                ref={(e) => {
                  register(e);
                  occupationRef.current = e;
                }}
              />
            </Col>
            <Col>
              <FormSelectBox
                label={t('married_status')}
                name="married"
                error={errors?.married?.message}
                ref={register({ required: t('this_field_is_required') })}
                disabled={overUpdateTime}
                defaultValue={MARRIED_LIST[0]}
              >
                {MARRIED_LIST.map((item, index) => (
                  <option key={`k_${index + 1}`} value={item}>
                    {t(item)}
                  </option>
                ))}
              </FormSelectBox>
            </Col>
          </Row>

          <Row>
            <Col>
              <CustomDatePicker
                label={t('date_of_birth')}
                type="text"
                name="dob"
                error={errors?.dob?.message}
                seletedDate={seletedDate}
                ref={register({ required: t('this_field_is_required') })}
                disabled={overUpdateTime}
                setSeletedDate={(value) => setSeletedDate(value)}
              />
            </Col>
            <Col>
              <NationalityBox
                label={t('nationality')}
                name="nationality"
                disabled={overUpdateTime}
                ref={(e) => {
                  register(e);
                  nationalityRef.current = e;
                }}
              />
            </Col>
          </Row>
          {/* CONTACT */}

          <Row className="pf-item-1 label-contact">
            <Title className="w-100 ii-col-custom" name={t('contact')} />
          </Row>
          <Row>
            <Col>
              <FormInput
                label={t('phone_number')}
                type="number"
                onPaste={(e) => handlePaste(e)}
                error={errors?.phone?.message}
                ref={register({
                  required: t('this_field_is_required'),
                  pattern: {
                    value: PHONE_MATCH,
                    message: t('invalid_phone'),
                  },
                })}
                name="phone"
              />
            </Col>
            <Col>
              <FormInput
                label={t('e_mail')}
                name="email"
                error={errors?.email?.message}
                ref={register({
                  required: t('this_field_is_required'),
                  pattern: {
                    value: EMAIL_MATCH,
                    message: t('invalid_email'),
                  },
                })}
                controlId="email"
              />
            </Col>
          </Row>
          {showRelationship && (
            <Row>
              <Col className="d-flex d-inline-block ">
                <Checkbox
                  name="useMyInfo"
                  value={0}
                  id="useMyInfo"
                  label={t('use_contract_owner_information')}
                  onChange={useContractOwnerInfo}
                  ref={register}
                />
              </Col>
            </Row>
          )}
          <ProvinceBox
            label={t('province')}
            name="province_id"
            disabled={isUseOwnerInfo}
            ref={(e) => {
              register(e);
              provinceRef.current = e;
            }}
          />
          <Form.Row>
            <Col>
              <DistrictBox
                label={t('district')}
                name="district_id"
                disabled={isUseOwnerInfo}
                ref={(e) => {
                  register(e);
                  districtRef.current = e;
                }}
              />
            </Col>
            <Col>
              <WardBox
                label={t('ward')}
                name="ward_id"
                disabled={isUseOwnerInfo}
                ref={(e) => {
                  register(e, { required: t('this_field_is_required') });
                  wardRef.current = e;
                }}
              />
            </Col>
          </Form.Row>
          <Row>
            <Col>
              <FormInput
                label={t('street')}
                type="text"
                name="street"
                error={errors?.street?.message}
                readOnly={isUseOwnerInfo}
                onBlur={(e) => formatInput(e)}
                ref={register({
                  required: t('this_field_is_required'),
                })}
                controlId="street"
                maxLength={VALIDATION.CUSTOMER.STREET_MAX_LENGTH}
              />
            </Col>
          </Row>
          {children}
          <FooterActions>
            <CommonButton primary={false} onPress={leftClick} label={t(leftBtn)} />
            <CommonButton label={t(rightBtn)} type="submit" />
          </FooterActions>
          <button ref={btnSubmit} type="submit" className="d-none" label="_" />
        </Form>
      </div>
    );
  }
);

InputInfo.defaultProps = {
  children: [],
  leftBtn: 'cancel',
  rightBtn: 'save',
  showRelationship: false,
  isNew: false,
  dataProvider: {},
};

InputInfo.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onSubmit: PropTypes.func.isRequired,
  leftClick: PropTypes.func.isRequired,
  leftBtn: PropTypes.string,
  rightBtn: PropTypes.string,
  showRelationship: PropTypes.bool,
  isNew: PropTypes.bool,
  dataProvider: PropTypes.oneOfType([PropTypes.object]),
};

export default InputInfo;
