import PATH from 'constants/path';
import { lazy } from 'react';
import './App.scss';

/**
 * List features Page
 */
const Profile = lazy(() => import('./features/Profile'));
const TermsAndConditions = lazy(() => import('./features/TermsAndConditions'));
const LandingPage = lazy(() => import('./features/LandingPage'));
const PA = lazy(() => import('./features/PA'));
const PA2 = lazy(() => import('./features/PA2'));
const HealthCare = lazy(() => import('./features/HealthCare'));
const HomeLiability = lazy(() => import('./features/HomeLiability'));
const Automobile = lazy(() => import('./features/Automobile'));
const Motorbike = lazy(() => import('./features/Motorbike'));
const Confirm = lazy(() => import('./features/Confirm'));
const ThankYou = lazy(() => import('./features/ThankYou'));
const Contract = lazy(() => import('./features/Contract'));
const QA = lazy(() => import('./features/QuestionAnswer'));
const Customer = lazy(() => import('./features/Customer'));
const Claim = lazy(() => import('./features/Claim'));
const ComboQuestionnaire = lazy(() => import('./features/Combo'));

const routes = [
  { path: PATH.PROFILE.VIEW_PROFILE, component: Profile },
  { path: PATH.LANDING_PAGE, component: LandingPage },
  { path: PATH.TERMS, component: TermsAndConditions },
  { path: PATH.PA.MAIN, component: PA },
  { path: PATH.PA2.MAIN, component: PA2 },
  { path: PATH.HEALTH_CARE.MAIN, component: HealthCare },
  { path: PATH.HOME_LIABILITY.MAIN, component: HomeLiability },
  { path: PATH.AUTOMOBILE.MAIN, component: Automobile },
  { path: PATH.MOTORBIKE.MAIN, component: Motorbike },
  { path: PATH.CONFIRM, component: Confirm },
  { path: PATH.THANK_YOU, component: ThankYou },
  { path: PATH.CONTRACT.MAIN, component: Contract },
  { path: PATH.QA.MAIN, component: QA },
  { path: PATH.CUSTOMER.INFO, component: Customer },
  { path: PATH.CLAIM.MAIN, component: Claim },
  { path: PATH.COMBO.MAIN, component: ComboQuestionnaire },
];

export default routes;
