import React from 'react';

import { useHistory } from 'react-router-dom';
import { icArrowLeft } from 'assets/images';
import { useSelector } from 'react-redux';

function BackItem() {
  const { backUrl } = useSelector((state) => state.header);
  const history = useHistory();
  return <img alt="" className="header-left" src={icArrowLeft} onClick={() => history.push(backUrl)} />;
}

export default BackItem;
