import React from 'react';
import PATH from 'constants/path';
import { useHistory } from 'react-router-dom';
import CommonButton from '../CommonButton';
import './NotFound.scss';

function NotFound() {
  const history = useHistory();
  return (
    <div className="nf-container">
      <h3>Oopss!...Not found</h3>
      <CommonButton
        fullWidth={false}
        label="Home"
        onPress={() => {
          history.push(PATH.HOME);
        }}
      />   
    </div>
  );
}
NotFound.propTypes = {};
export default NotFound;
