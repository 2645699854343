import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { iconMenu } from 'assets/images';
import SideBar from 'components/SideBar';
import { setDrawer } from 'components/Header/headerSlice';

function MenuItem() {
  const { drawerOpen } = useSelector((state) => state.header);
  const dispatch = useDispatch();
  return (
    <div>
      <img
        alt=""
        className="header-left"
        src={iconMenu}
        onClick={() => dispatch(setDrawer(!drawerOpen))}
      />
      <SideBar closeHandler={() => dispatch(setDrawer(false))} open={drawerOpen} />
    </div>
  );
}

export default MenuItem;
