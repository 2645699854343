import { ProductItem } from 'components';
import { PRODUCTS_ID } from 'constants/global';
import PATH from 'constants/path';
import { getProductList } from 'features/Home/homeSlice';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isArray } from 'utils/common';
import './ProductBox.scss';

const TAG = 'HOME PAGE';

const ProductBox = () => {
  const dispatch = useDispatch();
  const [listProductSupperView, setListProductSupperView] = useState([]);
  const [listProductVehicleView, setListProductVehicleView] = useState([]);
  const listProduct = useSelector((state) => state.home?.listProduct);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      dispatch(getProductList({ dispatchFunc: dispatch }));
    }, 0);
  }, [dispatch, t]);

  useEffect(() => {
    if (listProduct) {
      const objectProduct = [
        { id: PRODUCTS_ID.PA, link: PATH.PA.MAIN },
        { id: PRODUCTS_ID.HEALTH_CARE, link: PATH.HEALTH_CARE.MAIN },
        { id: PRODUCTS_ID.HOME_LIABILITY, link: PATH.HOME_LIABILITY.MAIN },
        { id: PRODUCTS_ID.AUTOMOBILE, link: PATH.AUTOMOBILE.MAIN },
        { id: PRODUCTS_ID.MOTORBIKE, link: PATH.MOTORBIKE.MAIN },
      ];

      const listSupper = [];
      const listVehicle = [];
      if (isArray(listProduct)) {
        listProduct.forEach((item) => {
          const resultFind = objectProduct.find((x) => x.id === item.id);
          if (resultFind) {
            item = {
              ...item,
              link: resultFind.link,
            };
            if (item.id !== PRODUCTS_ID.AUTOMOBILE && item.id !== PRODUCTS_ID.MOTORBIKE) {
              listSupper.push(item);
            } else {
              delete item.plan;
              listVehicle.push(item);
            }
          }
        });
        setListProductSupperView(listSupper);
        setListProductVehicleView(listVehicle);
      }
    }
  }, [listProduct]);

  return (
    <div>
      {listProductSupperView &&
        listProductSupperView.map((item) => (
          <ProductItem tag={TAG} productItem={item} key={item.id} />
        ))}
      <p className="des-vehicle col-11 mx-auto">{t('des_product_vehicle')}</p>
      {listProductVehicleView &&
        listProductVehicleView.map((item) => (
          <ProductItem tag={TAG} productItem={item} key={item.id} />
        ))}
    </div>
  );
};

export default ProductBox;
