import { DELIVERY_METHOD } from 'constants/global';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './DeliveryTab.scss';

const DeliveryTab = ({ method, tabChange }) => {
  const { t } = useTranslation();

  return (
    <div className="delivery-method">
      <div className="rd-select-outer">
        <label className="rd-select-method" htmlFor="i1">
          <input
            onChange={() => tabChange(DELIVERY_METHOD.DELIVERY)}
            className="delivery-radio-custom"
            name="method"
            type="radio"
            id="i1"
            value={method}
            checked={method === DELIVERY_METHOD.DELIVERY}
          />
          {t('delivery')}
          <div className="border-box" />
        </label>
      </div>

      <div className="rd-select-outer">
        <label className="rd-select-method" htmlFor="i2">
          <input
            onChange={() => tabChange(DELIVERY_METHOD.AEON)}
            className="delivery-radio-custom"
            name="method"
            type="radio"
            id="i2"
            value={method}
            checked={method === DELIVERY_METHOD.AEON}
          />
          {t('aeon')}
          <div className="border-box" />
        </label>
      </div>
    </div>
  );
};

DeliveryTab.propTypes = {
  method: PropTypes.string.isRequired,
  tabChange: PropTypes.func.isRequired,
};

export default DeliveryTab;
