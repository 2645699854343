import React from 'react';
import './Footer.scss';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer text-center">
      <span style={{ color: '#607D8B' }}>
        {t('copyright_first')}
      </span>
      <span style={{ color: '#B41F8E' }}>
        {t('copyright_last')}
      </span>
    </div>
  );
}

export default Footer;