import { configureStore } from '@reduxjs/toolkit';
import confirmSlice from 'features/Confirm/confirmSlice';
import homeLiabilitySlice from 'features/HomeLiability/homeLiabilitySlice';
import motorbikeSlice from 'features/Motorbike/motorbikeSlice';
import paSlice from 'features/PA/paSlice';
import pa2Slice from 'features/PA2/pa2Slice';
import headerSlice from '../components/Header/headerSlice';
import automobileSlice from '../features/Automobile/automobileSlice';
import contractSlice from '../features/Contract/contractSlice';
import healthCareSlice from '../features/HealthCare/healthCareSlice';
import homeSlice from '../features/Home/homeSlice';
import loginSlice from '../features/Login/loginSlice';
import profileSlice from '../features/Profile/profileSlice';
import comboSlice from '../features/Combo/comboSlice';
import appSlice from './appSlice';

const rootReducer = {
  header: headerSlice,
  profile: profileSlice,
  pa: paSlice,
  pa2: pa2Slice,
  healthCare: healthCareSlice,
  homeLiability: homeLiabilitySlice,
  automobile: automobileSlice,
  app: appSlice,
  confirm: confirmSlice,
  motorbike: motorbikeSlice,
  contract: contractSlice,
  login: loginSlice,
  home: homeSlice,
  combo: comboSlice
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
