import { OBJECT_HEADER_FULL_WIDTH, OBJECT_TYPE_MENU } from 'constants/global';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { iconAeon } from '../../assets/images'
import CartItem from './CartItem';
import DownloadItem from './DownloadItem';
import './Header.scss';
import BackItem from './BackItem';
import MenuItem from './MenuItem';
import ShareItem from './ShareItem';

const components = {};
components[OBJECT_TYPE_MENU.MENU] = MenuItem;
components[OBJECT_TYPE_MENU.SHARE] = ShareItem;
components[OBJECT_TYPE_MENU.SEARCH] = MenuItem;
components[OBJECT_TYPE_MENU.DOWNLOAD] = DownloadItem;
components[OBJECT_TYPE_MENU.CART] = CartItem;
components[OBJECT_TYPE_MENU.BACK] = BackItem;
const headerFullWidth = [OBJECT_HEADER_FULL_WIDTH.LANDING_PAGE];

function Header({ location }) {
  const { typeMenuLeft, typeMenuRight, titleHeader, hasDownload } = useSelector(
    (state) => state.header
  );
  const { t } = useTranslation();
  const MenuLeft = components[typeMenuLeft];
  const MenuRight = components[typeMenuRight];
  // check and return class name fullwidth or container
  const classBoxHeader = () => {
    let classHeader = 'header container';
    const subPath = location.pathname.slice(1);
    if (headerFullWidth.indexOf(subPath) !== -1 || subPath === '') {
      classHeader = 'header container full-width';
    }
    return classHeader;
  };

  return (
    <header className={classBoxHeader()}>
      <div className="nav row w-100">
        <div className="box d-flex justify-content-between">
          <div className="left-header">
            {typeMenuLeft !== OBJECT_TYPE_MENU.EMPTY && <MenuLeft />}
          </div>
        </div>
        <div className="right-header">
          {typeMenuRight !== OBJECT_TYPE_MENU.EMPTY && <MenuRight />}
        </div>
        {hasDownload && (
          <div className="right-header">
            <DownloadItem />
          </div>
        )}
        <div className="title-header">
          {
            titleHeader ? <span className="title">{t(titleHeader)}</span> : (
              <>
                <img className="icon-aeon" src={iconAeon} alt="" />
              </>
            )}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

Header.defaultProps = {
  location: PropTypes.shape({
    pathname: '',
  }),
};
export default withRouter(Header);
