import { getStore } from 'app/appSlice';
import { FormSelectBox, Title } from 'components';
import { isArray } from 'utils/common';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './StoreBox.scss';

const StoreBox = forwardRef(({ onSubmit }, ref) => {
  const { t } = useTranslation();
  const { stores } = useSelector((state) => state.app);
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();

  /**
   * Handle Change
   * @param {*} event
   */
  const handleChange = (event) => {
    setSelected(Number(event?.target?.value));
  };

  useImperativeHandle(ref, () => ({
    submit() {
      onSubmit(selected);
    },
    getValue() {
      return selected;
    },
    setValue(value) {
      setSelected(value);
    },
  }));

  useEffect(() => {
    if (!selected && isArray(stores, 0)) {
      setSelected(stores?.[0].id);
    }
  }, [stores, selected]);

  useEffect(() => {
    dispatch(getStore());
  }, [dispatch]);

  return (
    <>
      <Row>
        <Title className="s-column-custom" name={t('receiving_information')} />
      </Row>
      <Row>
        <Col>
          <FormSelectBox onChange={handleChange} value={selected || {}} label={t('place')}>
            {stores.map((item, index) => (
              <option key={`s_${index + 1}`} value={item.id}>
                {item.value}
              </option>
            ))}
          </FormSelectBox>
        </Col>
      </Row>
      <div>
        <div className="note">{`${t('note')}: `}</div>
        &nbsp;
        {t('delivery_note', { d: 'XX/XX/XXXX' })}
      </div>
    </>
  );
});

StoreBox.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default StoreBox;
