import { DistrictBox, FormInput, ProvinceBox, Title, WardBox, Checkbox } from 'components';
import { PHONE_MATCH, VALIDATION } from 'constants/global';
import { getFullName, useSetAddress } from 'utils/common';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './InputContactInfo.scss';

const InputContactInfo = forwardRef(({ onSubmit }, ref) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setValue, clearErrors } = useForm();
  const [isUseOwnerInfo, setIsUseOwnerInfo] = useState(false);
  const [currInfo, setCurrInfo] = useState({});
  const [currAddress, setAddress] = useState({});
  const { profile } = useSelector((app) => app.profile);
  const provinceRef = useRef();
  const districtRef = useRef();
  const wardRef = useRef();
  const btnSubmit = useRef();

  /**
   * Format Input
   * @param {*} event
   */
  const formatInput = (event) => {
    const { name, value } = event.target;
    setValue(name, value.trim());
  };

  /**
   * Handle Paste
   * @param {*} e
   */
  const handlePaste = (e) => {
    const str = e.clipboardData.getData('Text');
    e.target.value = str.slice(0, VALIDATION.CUSTOMER.PHONE_MAX_LENGTH);
    e.preventDefault();
  };
  /**
   * Fill Profile To Form
   */
  const fillProfileToForm = (data) => {
    setValue(
      'full_name',
      data?.name || getFullName({ firstName: data.first_name, lastName: data.last_name })
    );
    setValue('phone', (data.phone || '').replace(' ', ''));
    setValue('street', data.address?.street);
    setAddress(data.address);
  };

  useSetAddress(currAddress?.province?.id, currAddress?.district?.id, currAddress?.ward?.id, {
    provinceRef,
    districtRef,
    wardRef,
  });

  useImperativeHandle(ref, () => ({
    /**
     * Trigger submit from parent
     */
    callSubmit() {
      btnSubmit.current.click();
    },
    /**
     * Trigger init from parent
     */
    init(payload) {
      const payloadTemp = { ...payload };
      fillProfileToForm(payloadTemp);
      setCurrInfo(payloadTemp);
    },
  }));

  const handleUseOwnerInfo = (e) => {
    setIsUseOwnerInfo(e.target.checked);
    clearErrors();
    if (e.target.checked) {
      fillProfileToForm(profile);
    } else {
      fillProfileToForm(currInfo);
    }
  };

  const submitHandler = (data) => {
    if (isUseOwnerInfo) {
      const { phone, address } = profile || {};
      const wardId = address?.ward?.id;
      const street = address?.street;
      const userProfile = {
        full_name: getFullName({ firstName: profile.first_name, lastName: profile.last_name }),
        phone,
        ward_id: wardId,
        street,
      };
      onSubmit(userProfile);
    } else onSubmit(data);
  };

  return (
    <Form className="dl-wrapper" onSubmit={handleSubmit((data) => submitHandler(data))}>
      <div>
        <Row>
          <Title className="w-100 dl-col-custom" name={t('delivery_information')} />
        </Row>
        <Row>
          <Col className="d-flex d-inline-block ">
            <Checkbox
              name="useOwnerInfo"
              value={0}
              id="useMyInfo"
              label={t('use_buyer_information')}
              onChange={(e) => handleUseOwnerInfo(e)}
              labelClass="use-owner-info"
              ref={register}
            />
          </Col>
        </Row>
        <Form.Row>
          <Col>
            <FormInput
              name="full_name"
              controlId="full_name"
              label={t('full_name')}
              ref={register({
                required: t('this_field_is_required'),
              })}
              error={errors?.full_name?.message}
              maxLength={
                VALIDATION.CUSTOMER.LAST_NAME_MAX_LENGTH + VALIDATION.CUSTOMER.FIRST_NAME_MAX_LENGTH
              }
              onBlur={(e) => formatInput(e)}
            />
          </Col>
          <Col>
            <FormInput
              name="phone"
              controlId="phone"
              label={t('phone_number')}
              type="number"
              onPaste={(e) => handlePaste(e)}
              error={errors?.phone?.message}
              ref={register({
                required: t('this_field_is_required'),
                pattern: {
                  value: PHONE_MATCH,
                  message: t('invalid_phone'),
                },
              })}
              onBlur={(e) => formatInput(e)}
            />
          </Col>
        </Form.Row>
      </div>
      <div className="section-wrapper">
        <div className="section-content">
          <ProvinceBox
            label={t('province')}
            name="province_id"
            controlId="province_id"
            ref={(e) => {
              register(e);
              provinceRef.current = e;
            }}
            // disabled={isUseOwnerInfo}
          />
          <Form.Row>
            <Col>
              <DistrictBox
                label={t('district')}
                name="district_id"
                controlId="district_id"
                ref={(e) => {
                  register(e);
                  districtRef.current = e;
                }}
                // disabled={isUseOwnerInfo}
              />
            </Col>
            <Col>
              <WardBox
                controlId="ward_id"
                label={t('ward')}
                name="ward_id"
                ref={(e) => {
                  register(e);
                  wardRef.current = e;
                }}
                // disabled={isUseOwnerInfo}
              />
            </Col>
          </Form.Row>
          <FormInput
            label={t('street')}
            name="street"
            error={errors?.street?.message}
            ref={register({
              required: t('this_field_is_required'),
            })}
            onBlur={(e) => formatInput(e)}
            controlId="street"
            // disabled={isUseOwnerInfo}
            maxLength={VALIDATION.CUSTOMER.STREET_MAX_LENGTH}
          />
        </div>
      </div>
      <button ref={btnSubmit} type="submit" className="d-none" label="_" />
    </Form>
  );
});

InputContactInfo.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default InputContactInfo;
