import { DATE_FORMAT } from 'constants/global';
import { getProfile } from 'features/Profile/profileSlice';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, parseAddressToString } from 'utils/common';
import './ViewCustomerInfo.scss';

function ViewCustomerInfo() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  return (
    <Container>
      <Row className="pf-item">
        <Col className="pf-title">{t('cmnd/cccd')}</Col>
        <Col className="pf-id">{profile.id_card}</Col>
      </Row>
      <Row>
        <Col className="buffer-line" />
      </Row>
      <Row className="pf-item">
        <Col className="pf-title">{t('full_name')}</Col>
        <Col className="pf-info">{`${profile.last_name || ''} ${profile.first_name || ''}`}</Col>
      </Row>
      <Row>
        <Col className="buffer-line" />
      </Row>
      <Row className="pf-item">
        <Col className="pf-title">{t('gender')}</Col>
        <Col className="pf-info">{t(profile.gender)}</Col>
      </Row>
      <Row>
        <Col className="buffer-line" />
      </Row>
      <Row className="pf-item">
        <Col className="pf-title">{t('date_of_birth')}</Col>
        <Col className="pf-info">{formatDate(profile.dob, DATE_FORMAT.DATE)}</Col>
      </Row>
      <Row>
        <Col className="buffer-line" />
      </Row>
      <Row className="pf-item">
        <Col className="pf-title">{t('email')}</Col>
        <Col className="pf-info">{profile.email}</Col>
      </Row>
      <Row>
        <Col className="buffer-line" />
      </Row>
      <Row className="pf-item">
        <Col className="pf-title">{t('address')}</Col>
        <Col className="pf-info">{parseAddressToString(profile.address)}</Col>
      </Row>
      <Row>
        <Col className="buffer-line" />
      </Row>
      <Row className="pf-item">
        <Col className="pf-title">{t('phone')}</Col>
        <Col className="pf-info">{profile.phone}</Col>
      </Row>
      <Row>
        <Col className="buffer-line" />
      </Row>
      <Row className="pf-item">
        <Col className="pf-title">{t('nationality')}</Col>
        <Col className="pf-info">{profile.nationality?.name}</Col>
      </Row>
    </Container>
  );
}
export default ViewCustomerInfo;
