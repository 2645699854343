/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormSelectBox } from 'components';

const WardBox = forwardRef(({ ...props }, ref) => {
  const { wards } = useSelector((state) => state.app.location);
  const [value, setValue] = useState(props?.value);

  const handleChange = (event) => {
    setValue(Number(event?.target?.value));
  };

  useEffect(() => {
    const wardId = wards[0]?.id;
    if (wardId && !wards.some((i) => i?.id === value)) setValue(wardId);
  }, [wards, value]);

  useImperativeHandle(ref, () => ({
    setImperativeValue(varible) {
      if (wards.some((i) => i?.id === varible)) setValue(varible);
    },
  }));

  return (
    <FormSelectBox {...props} ref={ref} onChange={handleChange} value={value} controlId="ward-box">
      {wards.map((item, index) => (
        <option key={`ward_${index + 1}`} value={item.id}>
          {item.name}
        </option>
      ))}
    </FormSelectBox>
  );
});

export default WardBox;
