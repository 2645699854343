import { ROUTER_HISTORY_ACTIONS } from 'constants/global';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { usePrevious } from 'utils/common';

const DynamicScrollToTop = ({ history, location }) => {
  const prevPath = usePrevious(location);
  useEffect(() => {
    // Keep scroll when user:
    // - clicked back button
    // - clicked on a link that programmatically calls `history.goBack()`
    // - manually changed the URL in the address bar
    if (
      history.action === ROUTER_HISTORY_ACTIONS.POP ||
      location?.pathname === prevPath?.pathname
    ) {
      return;
    }
    // In all other cases, check fragment/scroll to top
    const { hash } = location;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  });

  return <div />;
};

DynamicScrollToTop.defaultProps = {
  location: '',
};

DynamicScrollToTop.propTypes = {
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withRouter(DynamicScrollToTop);
