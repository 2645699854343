import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import orderAPI from 'api/orderAPI';
import productAPI from 'api/productAPI';
import { isEmptyOrNull, isArray, convertSold } from 'utils/common';

const getRecommendation = createAsyncThunk(
  'GET_RECOMMENDATION',
  async (arg, { rejectWithValue }) => {
    try {
      const res = await productAPI.getRecommendation();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getSoldCount = createAsyncThunk('GET_SOLD_COUNT', async (arg, { rejectWithValue }) => {
  try {
    const res = await orderAPI.getSoldCount();
    // console.log(res);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getProductPurchased = createAsyncThunk(
  'GET_PRODUCT_PURCHASED',
  async (arg, { rejectWithValue }) => {
    try {
      const res = await orderAPI.getProductPurchased();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getProductList = createAsyncThunk('GET_PRODUCT_LIST', async (params, { rejectWithValue }) => {
  try {
    const res = await productAPI.getProducts();
    if (res && params?.dispatchFunc) {
      const solds = await params.dispatchFunc(getSoldCount());
      const recommentdation = await params.dispatchFunc(getRecommendation());
      const purchased = await params.dispatchFunc(getProductPurchased());
      const soldList = isArray(solds.payload, 0) ? solds.payload : [];
      const recommendationList = isArray(recommentdation.payload, 0) ? recommentdation.payload : [];
      const purchasedList = isArray(purchased.payload, 0) ? purchased.payload : [];
      res.forEach((item, index) => {
        const resultSold = soldList.find((x) => x.id === item.id);
        const resultRecommendation = recommendationList.find((x) => x.id === item.id);
        const resultPurchased = purchasedList.find((x) => x.product_id === item.id && x.purchased === true);
        if (resultSold) {
          res[index] = { ...res[index], sold: convertSold(resultSold.sold_number) };
        }
        if (resultRecommendation && !isEmptyOrNull(resultRecommendation.plan)) {
          res[index] = { ...res[index], premium: resultRecommendation.plan.premium };
        }
        if (resultPurchased) {
          res[index] = { ...res[index], bought: true };
        }
      });
    }
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getStatusPA2 = createAsyncThunk('GET_STATUS_PA2', async (arg, { rejectWithValue }) => {
  try {
    const res = await orderAPI.getStatusPA2();
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const initialState = {
  listProduct: [],
};

const home = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: {
    // getProductList
    [getProductList.pending]: (state) => {
      state.list = null;
    },
    [getProductList.fulfilled]: (state, { payload }) => {
      state.listProduct = payload;
    },
    [getProductList.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
  },
});

const { reducer } = home;
export { getProductList, getStatusPA2, getSoldCount };
export default reducer;
