import PropTypes from 'prop-types';
import React from 'react';
import './Title.scss';

function Title({ name, className }) {
  return (
    <div className={`ht-container ${className}`}>
      <div>
        <span>{name}</span>
      </div>
    </div>
  );
}

Title.defaultProps = {
  className: '',
};

Title.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Title;
