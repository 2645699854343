import axiosClient from './axiosClient';

const userAPI = {
  get: () => {
    const url = `/customer`;
    return axiosClient.get(url);
  },
  update: (params) => {
    const url = `/customer`;
    return axiosClient.put(url, params);
  },
  create: (params) => {
    const url = `/customer`;
    return axiosClient.post(url, params);
  },
  delete: (id) => {
    const url = `/customer${id}`;
    return axiosClient.put(url);
  },
  auth: (params) => {
    const url = `/oauth/token`;
    return axiosClient.post(url, params);
  },
  updateCustomerContact: (params) => {
    const url = `/customer`;
    return axiosClient.patch(url, params);
  },
  login: (params) => {
    const url = `token/issue`;
    return axiosClient.post(url, params);
  },
  getOccupation: () => {
    const url = `/configuration/occupation`;
    return axiosClient.get(url);
  },
  agreeTermsAndConditions: () => {
    const url = `/customer/terms-and-condition-agreement`;
    return axiosClient.patch(url);
  },
};

export default userAPI;
