import axiosClient from './axiosClient';

const motorbikeAPI = {
  getInsuredInfo: (orderId) => {
    const url = `/order/${orderId}/motorbike`;
    return axiosClient.get(url);
  },
  createMotorBikeInsured: (id, params) => {
    const url = `/order/${id}/motorbike`;
    return axiosClient.post(url, params);
  },
  updateMotorBikeInsured: (id, params) => {
    const url = `/order/${id}/motorbike`;
    return axiosClient.put(url, params);
  },
};

export default motorbikeAPI;
