import analytics from 'app/analytics';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GATracking = () => {
  const location = useLocation();

  useEffect(() => {
    analytics.init();
  }, []);

  useEffect(() => {
    analytics.sendPageview(`#${location.pathname + location.search}`);
  }, [location]);

  return <div />;
};

export default GATracking;
