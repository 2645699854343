/* eslint-disable react/no-danger */
import analytics from 'app/analytics';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { formatPrice } from 'utils/common';
import './ProductItem.scss';

function ProductItem({ productItem, tag }) {
  const { t } = useTranslation();
  const history = useHistory();

  /**
   * Product Clicked
   * @param {*} link
   */
  const productClick = (link) => {
    if (analytics.enable) {
      analytics.sendEvent({
        category: `View ${link}`,
        action: `click`,
        label: `Tracking users access ${link} from ${tag}`,
      });
    }
    history.push(link);
  };

  return (
    <div className="item-product">
      <div role="button" onClick={() => productClick(productItem.link)}>
        {
          (productItem?.premium > 0)
          && <p className="plan" dangerouslySetInnerHTML={{ __html: formatPrice(productItem.premium, t) }} />
        }
        <p className="title">{t(productItem.name)}</p>
        <p className="des">{productItem.overview}</p>
        <p className="link-now" />
        <div className="icon-product" style={{ backgroundImage: `url(${productItem.icon_path})` }}>
          <span className="tem">
            {!productItem.bought ? (
              <span className="sold">{`${productItem.sold} ${t('sold')}`}</span>
            ) : (
                productItem.bought && <span className="bought">{t('purchased')}</span>
              )}
          </span>
        </div>
      </div>
    </div>
  );
}

ProductItem.propTypes = {
  productItem: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
    overview: PropTypes.string,
    background: PropTypes.string,
    icon_path: PropTypes.string,
    bought: PropTypes.bool,
    sold: PropTypes.string,
    premium: PropTypes.number,
  }).isRequired,
  tag: PropTypes.string.isRequired,
};

export default ProductItem;
