import PATH from 'constants/path';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginMain from './page/Main';

function Login() {
  return (
    <div>
      <Switch>
        <Route exact path={PATH.LOGIN} component={LoginMain} />
      </Switch>
    </div>
  );
}

export default Login;
