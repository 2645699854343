import { iconPencil, iconTrash } from 'assets/images';
import PropTypes from 'prop-types';
import React from 'react';
import { getFullName } from 'utils/common';
import './InsuredListItem.scss';

const InsuredListItem = ({ insuredItem, editHandle, deleteHandle }) => {
  return (
    <div className="ili-item-wrapper d-flex">
      <p className="user-info">
        {getFullName({ firstName: insuredItem.first_name, lastName: insuredItem.last_name })}
      </p>

      <div className="action">
        <span className="icon">
          <img src={iconPencil} alt="" onClick={() => editHandle(insuredItem.id)} />
        </span>
        <span className="icon">
          <img src={iconTrash} alt="" onClick={() => deleteHandle(insuredItem.id)} />
        </span>
      </div>
    </div>
  );
};

InsuredListItem.propTypes = {
  editHandle: PropTypes.func.isRequired,
  deleteHandle: PropTypes.func.isRequired,
  insuredItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
};

export default InsuredListItem;
