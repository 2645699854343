import React from 'react';

import './Drawer.scss';
import PropTypes from 'prop-types';
import DrawerHeader from './DrawerHeader';
import DrawerMenu from './DrawerMenu';

const Drawer = ({ show }) => {
  return (
    <nav className={show ? 'drawer open' : 'drawer'}>
      <DrawerHeader />
      <DrawerMenu />
    </nav>
  );
};

Drawer.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default Drawer;
