import PropTypes from 'prop-types';
import React from 'react';
import './Loading.scss';

const Loading = ({ show }) => {
  if (show) {
    document.body.style.overflow = 'hiden';
    return (
      <div className="tm-app-loader">
        <div className="loader" />
      </div>
    );
  }
  document.body.style.overflow = 'visible';
  return <div className="tm-loading d-none" />;
};
Loading.propTypes = {
  show: PropTypes.bool.isRequired,
};
export default Loading;
