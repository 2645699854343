import { notifyMessage } from 'app/appSlice';
import { CommonButton, FormInput } from 'components';
import { setHeader } from 'components/Header/headerSlice';
import { ALERT_TYPE, OBJECT_TYPE_MENU, PARENT_APP_CONTAINS_FOOTER_CLASS } from 'constants/global';
import PATH from 'constants/path';
import { login } from 'features/Login/loginSlice';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isAuthenticated, isEmptyOrNull, reponseSuccess } from 'utils/common';
import './LoginMain.scss';

function LoginMain({ location }) {
  const { register, handleSubmit, errors: formErrors } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = location;

  const showNotify = () => {
    const alertMessage = {
      messageCode: t('login_failed'), // TODO: need to define MSG constant
      type: ALERT_TYPE.UNSUCCESS,
    };
    dispatch(notifyMessage(alertMessage));
  };

  const onSubmit = async (data) => {
    const result = await dispatch(login(data));
    if (reponseSuccess(result.payload)) {
      let next = PATH.HOME;
      if (!isEmptyOrNull(state?.from)) {
        next = `${state?.from?.pathname}${state?.from?.search}`;
      }
      history.replace(next);
    } else {
      showNotify();
    }
  };

  useEffect(() => {
    dispatch(
      setHeader({
        titleHeader: t('Login'),
        typeMenuLeft: OBJECT_TYPE_MENU.EMPTY,
        typeMenuRight: OBJECT_TYPE_MENU.EMPTY,
      })
    );
    if (isAuthenticated()) {
      history.replace(PATH.HOME);
    }
  }, [dispatch, t, history]);

  return (
    <>
      <div className={`${PARENT_APP_CONTAINS_FOOTER_CLASS} login-container`}>
        <Form
          onSubmit={handleSubmit((data) => {
            onSubmit(data);
          })}
        >
          <Form.Row>
            <Col xs={12}>
              <FormInput
                name="username"
                label={t('user_name')}
                controlId="username"
                ref={register({ required: t('this_field_is_required') })}
                error={formErrors?.username?.message}
              />
            </Col>
            <Col xs={12}>
              <FormInput
                type="password"
                label={t('password')}
                name="password"
                controlId="password"
                ref={register({ required: t('this_field_is_required') })}
                error={formErrors?.password?.message}
              />
            </Col>
            <Col xs={12}>
              <CommonButton label={t('Submit')} type="submit" />
            </Col>
          </Form.Row>
        </Form>
      </div>
    </>
  );
}

LoginMain.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      from: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
      }).isRequired,
    }),
  }).isRequired,
};

export default LoginMain;
