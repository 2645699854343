
import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from 'components/Backdrop';
import Drawer from 'components/Drawer';
import './SideBar.scss';

const SideBar = ({ closeHandler, open }) => {
  return (
    <>
      <Drawer show={open} />
      {open &&
        <Backdrop clickHandler={closeHandler} />
      }
    </>
  )
}

SideBar.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default SideBar;