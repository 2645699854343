import axiosClient from './axiosClient';

const automobileAPI = {
  getInsured: (orderId) => {
    const url = `/order/${orderId}/automobile`;
    return axiosClient.get(url);
  },
};

export default automobileAPI;
