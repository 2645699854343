import { icAngleDown, icAngleUp } from 'assets/images';
import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import './Checkbox.scss';

const Checkbox = forwardRef(({ label, ellipsis, labelClass, ...props }, ref) => {
  const [isExpand] = useState(ellipsis > 0);
  const [curEllipsis, setEllipsis] = useState(ellipsis);
  const ellipsisStyles = {
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitLineClamp: curEllipsis,
    WebkitBoxOrient: 'vertical',
  };
  return (
    <div className="checkbox-wrapper">
      <label className="checkbox bounce">
        <input type="checkbox" {...props} ref={ref} />
        <svg viewBox="0 0 21 21">
          <polyline points="5 10.75 8.5 14.25 16 6" />
        </svg>
        <span className={labelClass} style={ellipsis > 0 ? ellipsisStyles : {}}>
          {label}
        </span>
      </label>
      {isExpand && (
        <img
          src={curEllipsis === ellipsis ? icAngleDown : icAngleUp}
          onClick={() => setEllipsis(curEllipsis === ellipsis ? 99 : ellipsis)}
          alt=""
        />
      )}
    </div>
  );
});
Checkbox.defaultProps = {
  label: '',
  ellipsis: 0,
  labelClass: '',
};
Checkbox.propTypes = {
  label: PropTypes.string,
  labelClass: PropTypes.string,
  ellipsis: PropTypes.number,
};

export default Checkbox;
