import axiosClient from './axiosClient';

const locationAPI = {
  getProvinces: () => {
    const url = '/location/province';
    return axiosClient.get(url);
  },
  getDistricts: (provinceId) => {
    const url = `/location/province/${provinceId}/district`;
    return axiosClient.get(url);
  },
  getWards: ({ provinceId, districtId }) => {
    const url = `/location/province/${provinceId}/district/${districtId}/ward`;
    return axiosClient.get(url);
  },
  getNationalites: () => {
    const url = `/configuration/value-code?type=nationality`;
    return axiosClient.get(url);
  },
};

export default locationAPI;
