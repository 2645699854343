import React from 'react';
import PropTypes from 'prop-types';

import './FooterActions.scss';

const FooterActions = ({ children, component, isBlur }) => {
  return (
    <div className="actions-container">
      <div className={`-blur${isBlur ? '' : ' d-none'}`} />
      {component && <div className="-action-component">{component}</div>}
      <div className="-action-container">{children}</div>
    </div>
  );
};
FooterActions.defaultProps = {
  children: [],
  isBlur: true,
  component: '',
};
FooterActions.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isBlur: PropTypes.bool,
};

export default FooterActions;
