import React from 'react';
import PropTypes from 'prop-types';
import { imgNoData } from 'assets/images';
import './NoData.scss';

function NoData({ size, message }) {
  return (
    <div className="no-data-container">
      <img style={{ width: size, height: size }} alt="" src={imgNoData} />
      {message && <p className="message">{message}</p>}
    </div>
  );
}
NoData.defaultProps = {
  size: 200,
  message: '',
};
NoData.propTypes = {
  size: PropTypes.number,
  message: PropTypes.string,
};
export default NoData;
