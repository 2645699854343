/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './OptionalItem.scss';
import { ToggleBox } from 'components';
import { formatPrice, isArray } from 'utils/common';


function OptionalItem({ plans, checked, selected, checkedOptionView }) {
  const { t } = useTranslation();

  const filterCoveragesOptional = (coverages) => {
    return coverages.filter(x => x.optional === true);
  }

  const findCoverages = (id) => {
    return plans.find(x => x.id === id).coverages;
  }


  const objectOptionalFunc = (id) => {
    return filterCoveragesOptional(findCoverages(id))
  }

  const filterOptional = (objectOptional, flagInsured) => {
    const listOptional = [];
    if (objectOptional) {
      objectOptional.forEach(item => {
        if (item.sub_coverages) {
          listOptional.push(...item.sub_coverages.filter(x => x.adult === flagInsured))
        } else if (item.adult === flagInsured) {
          listOptional.push(item);
        }
      })
    }
    return listOptional;
  };

  const optionalItemFunc = (idPlan, flagInsured, idOptional) => {
    const objectOptional = objectOptionalFunc(idPlan);
    const objectOptionalItem = filterOptional(objectOptional, flagInsured);
    return objectOptionalItem.find(x => x.id === idOptional);
  }

  const renderChild = (idPlan, flagInsured, idOptional) => {
    const optionalItem = optionalItemFunc(idPlan, flagInsured, idOptional);
    if (optionalItem) {
      return (
        <div className={`price ${selected === idPlan ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: formatPrice(optionalItem.sum_insured, t) }} />
      )
    }
    return "";
  }

  const checkedCheckbox = (item) => {
    if (isArray(checkedOptionView, 0)) {
      if (checkedOptionView.find(x => (x === item.id && item.optional))) {
        return true;
      }
    }
    return false;
  }

  const renderBox = (flagInsured) => {
    const objectOptional = objectOptionalFunc(plans[0].id);
    const optionalItem = filterOptional(objectOptional, flagInsured);
    if (optionalItem && optionalItem.length > 0) {
      return (
        <div className="box-optional">
          {optionalItem.map((item) => (
            <div className="box" key={item.id}>
              <div className="box-title d-flex align-items-center">
                <div className="title-optional">
                  {item.name}
                </div>
                <ToggleBox checked={checkedCheckbox(item)} onChange={() => checked([item.id])} />
              </div>
              <div className="box">
                <p className="title-object">
                  {t('adult')}
                </p>
                <div className="d-flex justify-content-between text-center">
                  {
                    plans.map((child) => (
                      <div key={child.id}>
                        {renderChild(child.id, true, item.id)}
                      </div>
                    ))
                  }
                </div>
              </div>
              { optionalItemFunc(plans[0].id, false, item.id) && (
                <div className="box">
                  <p className="title-object">
                    {t('children')}
                  </p>
                  <div className="d-flex justify-content-between text-center">
                    {
                      plans.map((child) => (
                        <div key={child.id}>
                          {renderChild(child.id, false, item.id)}
                        </div>
                      ))
                    }
                  </div>
                </div>
              )}

            </div>
          ))}
        </div>
      );
    }
    return '';
  };

  return (
    <div className="optional-item">
      <div className="benefit-item">
        <div className="title-optionals">
          {t('optional_benefit')}
        </div>
        {renderBox(true)}
      </div>
    </div>
  );
}

OptionalItem.propTypes = {
  checked: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PropTypes.object),
  checkedOptionView: PropTypes.arrayOf(PropTypes.number),
  selected: PropTypes.number.isRequired,
};

OptionalItem.defaultProps = {
  plans: {},
  checkedOptionView: []
};
export default OptionalItem;
