/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import './FormInput.scss';

const FormInput = forwardRef(({ label, error, controlId, ...props }, ref) => {
  const errClass = error ? 'form-control is-invalid' : '';
  return (
    <Form.Group className="form-input-wrapper" controlId={controlId}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control className={errClass} {...props} ref={ref} />
      <p className="text-danger m-0 p-0">{error}</p>
    </Form.Group>
  );
});
FormInput.defaultProps = {
  label: '',
  error: '',
  controlId: '',
};
FormInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  controlId: PropTypes.string,
};

export default FormInput;
