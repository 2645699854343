import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import orderAPI from 'api/orderAPI';
import { isArray, convertSold, redirectHomeOrderPaid } from 'utils/common';
import paymentAPI from 'api/paymentAPI';
import productAPI from 'api/productAPI';
import { PRODUCTS_ID, ORDER_STATUS } from 'constants/global';

const deleteProduct = createAsyncThunk(
  'CONFIRM_DELETE_PRODUCT',
  async (param, { rejectWithValue }) => {
    try {
      const res = await orderAPI.deleteProduct(param);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const getOrderDetailByOrderId = createAsyncThunk(
  'GET_ORDER_DETAIL_BY_ORDER_ID',
  async (orderId, { rejectWithValue }) => {
    try {
      const data = {
        order_details: [],
        total_amounts: 0,
      };
      const res = await orderAPI.getOrderDetailByOrderId(orderId);
      if (res) {
        // redirect home and remove order id in localstorage when purchased order 
        if (res.order_status && res.order_status === ORDER_STATUS.PAID) {
          redirectHomeOrderPaid();
        }
        if (isArray(res.order_details, 0)) {
          data.order_details = res.order_details;
        }
        data.total_amounts = res.total_amounts;
      }

      return data;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const orderComplate = createAsyncThunk(
  'ORDER_COMPLATE',
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await orderAPI.orderComplate(orderId);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const paymentComplate = createAsyncThunk(
  'PAYMENT_COMPLATE',
  async (params, { rejectWithValue }) => {
    try {
      const res = await paymentAPI.paymentComplate(params);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const getSuggestionList = createAsyncThunk(
  'GET_SUGGESTION_LIST',
  async (_arg, { rejectWithValue }) => {
    try {
      const res = [];
      const resSuggestions = await productAPI.getRecommendation();
      const resSolds = await orderAPI.getSoldCount() || [];
      if (isArray(resSuggestions, 0)) {
        const productSuppers = [PRODUCTS_ID.PA, PRODUCTS_ID.HOME_LIABILITY, PRODUCTS_ID.HEALTH_CARE];
        const productVehicles = [PRODUCTS_ID.PA, PRODUCTS_ID.AUTOMOBILE, PRODUCTS_ID.MOTORBIKE];
        const resultSupper = resSuggestions.find(x => productSuppers.includes(x.id));
        const resultVehicle = resSuggestions.find(x => productVehicles.includes(x.id));
        if (resultSupper) {
          res.push({ supper: true, package_name: 'title_suggestion_add_one_product', items: [] });
        }
        if (resultVehicle) {
          res.push({ supper: false, package_name: 'title_suggestion_add_product_vehicle', items: [] });
        }

        resSuggestions.forEach((item) => {
          if (productSuppers.includes(item.id)) {
            const i = res.findIndex(x => x.supper === true);
            res[i].items.push({
              product_id: item.id,
              name: item.name,
              sub_title: item.plan?.premium || '',
              has_plan: true,
              img: item.icon_path
            })
          } else if (productVehicles.includes(item.id)) {
            const i = res.findIndex(x => x.supper === false);
            res[i].items.push({
              product_id: item.id,
              name: item.name,
              has_plan: false,
              sub_title: convertSold(resSolds.find(x => x.id === item.id)?.sold_number),
              img: item.icon_path
            })
          }
        })
      }
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const getEffectDateOfSuperInsurance = createAsyncThunk(
  'GET_EFFECT_DATE_OF_SUPER_INSURANCE',
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await orderAPI.getEffectDateOfSuperInsurance(orderId);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const updateEffectDateOfSuperInsurance = createAsyncThunk(
  'UPDATE_EFFECT_DATE_OF_SUPER_INSURANCE',
  async ({ orderId, params }, { rejectWithValue }) => {
    try {
      const res = await orderAPI.updateEffectDateOfSuperInsurance(orderId, params);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const getOrderId = createAsyncThunk('GET_ORDER_ID', async (arg, { rejectWithValue }) => {
  try {
    const res = await orderAPI.getOrderId();
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

/**
 * Get Insured List
 */
const getListOfInsuredPerson = createAsyncThunk(
  'CONFIRM_GET_LIST_INSURED',
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await orderAPI.getListInsured(orderId);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getProductByProductId = createAsyncThunk(
  'CONFIRM_GET_PRODUCT_BY_PRODUCT_ID',
  async (param, { rejectWithValue }) => {
    try {
      let result = [];
      const res = await productAPI.getProductByProductId(param.product_id);
      if (res) {
        result = res.plans.find(x => x.id === param.plan_id);
      }
      return result;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const initialState = {
  orderList: null,
  errors: null,
  totalAmounts: 0,
  suggestionList: [],
  effectDateObj: {},
  listOfInsuredPerson: null
};
const confirm = createSlice({
  name: 'confirm',
  initialState,
  reducers: {
    resetObjectState: {
      reducer: (state) => {
        Object.keys(state)
          .forEach(function eachKey(key) {
            state[key] = initialState[key]
          });
      }
    }
  },
  extraReducers: {
    [deleteProduct.pending]: (state) => {
      state.errors = null;
      state.loading = true;
    },
    [deleteProduct.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteProduct.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    [getOrderDetailByOrderId.pending]: (state) => {
      state.errors = null;
      state.loading = true;
      state.orderList = [];
    },
    [getOrderDetailByOrderId.fulfilled]: (state, { payload }) => {
      state.orderList = payload?.order_details;
      state.totalAmounts = payload?.total_amounts;
      state.loading = false;
    },
    [getOrderDetailByOrderId.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    [orderComplate.pending]: (state) => {
      state.errors = null;
      state.loading = true;
    },
    [orderComplate.fulfilled]: (state) => {
      state.loading = false;
    },
    [orderComplate.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    [paymentComplate.pending]: (state) => {
      state.errors = null;
      state.loading = true;
    },
    [paymentComplate.fulfilled]: (state) => {
      state.loading = false;
    },
    [paymentComplate.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    [getSuggestionList.pending]: (state) => {
      state.errors = null;
      state.loading = true;
      state.suggestionList = null;
    },
    [getSuggestionList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.suggestionList = payload;
    },
    [getSuggestionList.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    [getEffectDateOfSuperInsurance.pending]: (state) => {
      state.errors = null;
      state.loading = true;
      state.effectDateObj = {};
    },
    [getEffectDateOfSuperInsurance.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.effectDateObj = payload;
    },
    [getEffectDateOfSuperInsurance.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    [updateEffectDateOfSuperInsurance.pending]: (state) => {
      state.errors = null;
      state.loading = true;
    },
    [updateEffectDateOfSuperInsurance.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateEffectDateOfSuperInsurance.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    // get List Insured call back
    [getListOfInsuredPerson.pending]: (state) => {
      state.listOfInsuredPerson = [];
      state.insuredPerson = {};
    },
    [getListOfInsuredPerson.fulfilled]: (state, action) => {
      state.listOfInsuredPerson = action.payload;
    },
    [getListOfInsuredPerson.rejected]: (state, action) => {
      state.errors = action.payload;
    },
  },
});

const { reducer, actions } = confirm;
export const { resetObjectState } = actions
export {
  deleteProduct,
  getOrderDetailByOrderId,
  orderComplate,
  paymentComplate,
  getSuggestionList,
  getEffectDateOfSuperInsurance,
  updateEffectDateOfSuperInsurance,
  getOrderId,
  getListOfInsuredPerson,
  getProductByProductId
};
export default reducer;
