import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OptionalItem, BasicItem, PlanItem } from 'components';
import './SelectPlanItem.scss';

function SelectPlanItem({ projectDetail, changeSelectedPlan, checkedOptional, selected, checkedOptionView, premiumsOfPlansByProductId }) {

  const [plansView, setPlansView] = useState(null);

  const changeRadio = (id) => {
    changeSelectedPlan(id);
  };

  useEffect(() => {
    if (projectDetail && premiumsOfPlansByProductId) {
      const plansTemp = [...projectDetail.plans];
      plansTemp.forEach((item, index) => {
        const result = premiumsOfPlansByProductId.find(x => x.plan_id === item.id);
        if (result) {
          plansTemp[index] = {
            ...plansTemp[index],
            totalPremiumPlan: result.premium
          }
        }
      })

      setPlansView(plansTemp);
    }
  }, [projectDetail, premiumsOfPlansByProductId])

  const renderLayout = () => {
    if (plansView) {
      return (
        <>
          <div>
            <div className="box-plans">
              {plansView.map((item) => (
                <PlanItem
                  handleChangeRadio={changeRadio}
                  planItem={item}
                  key={item.id}
                  selected={selected}
                  premium={item.totalPremiumPlan}
                />
              ))}
            </div>
            <div className="">
              <div className="benefit">
                <BasicItem plans={plansView} selected={selected} />
              </div>
              <div className="optionals">
                <OptionalItem plans={plansView} selected={selected} checked={checkedOptional} checkedOptionView={checkedOptionView} />
              </div>
            </div>
          </div>
        </>
      )
    }
    return "";
  }

  return (
    renderLayout()
  );
}
SelectPlanItem.propTypes = {
  projectDetail: PropTypes.objectOf(PropTypes.any),
  checkedOptionView: PropTypes.arrayOf(PropTypes.number),
  changeSelectedPlan: PropTypes.func.isRequired,
  checkedOptional: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  premiumsOfPlansByProductId: PropTypes.arrayOf(PropTypes.object)
};


export default SelectPlanItem;
