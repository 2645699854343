import axiosClient from './axiosClient';

const surveyAPI = {
  get: (id) => {
    const url = `/survey/${id}`;
    return axiosClient.get(url);
  },
  answer: (id, params) => {
    const url = `/survey/${id}/answer`;
    return axiosClient.post(url, params);
  },
  result: (id, params) => {
    const url = `/survey/${id}/result`;
    return axiosClient.get(url, params);
  },
};

export default surveyAPI;
