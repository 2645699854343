const PATH = {
  HOME: '/',
  LOGIN: '/login',
  THANK_YOU: '/thank-you',
  CONFIRM: '/confirm',
  LANDING_PAGE: '/landing-page',
  TERMS: '/terms-and-conditions',
  PA: {
    MAIN: '/pa',
    SELECT_PLAN: '/pa/select-plan/',
    QUESTIONNAIRE: '/pa/questionnaire',
    ADD_INSURED: '/pa/add-insured',
    EDIT_INSURED: '/pa/edit-insured/:id',
    INSURED_LIST: '/pa/insured-list',
    RESULT: '/pa/result',
  },
  PA2: {
    MAIN: '/pa2',
    SUMMARY_BENEFIT: '/pa2/summary-benefit',
    MARKETING_QUESTIONNAIRE: '/pa2/marketing-questionnaire',
  },
  HEALTH_CARE: {
    MAIN: '/health-care',
    SELECT_PLAN: '/health-care/select-plan',
    QUESTIONNAIRE: '/health-care/questionnaire',
    ADD_INSURED: '/health-care/add-insured',
    EDIT_INSURED: '/health-care/edit-insured/:id',
    INSURED_LIST: '/health-care/insured-list',
    RESULT: '/health-care/result',
  },
  HOME_LIABILITY: {
    MAIN: '/home-liability',
    INPUT_INSURED: '/home-liability/input-insured',
    SELECT_PLAN: '/home-liability/select-plan',
    ADDRESS_INSURANCE: '/home-liability/address-insurance',
  },
  AUTOMOBILE: {
    MAIN: '/automobile',
    CONFIRM: '/automobile/input-confirm',
    ADD_INSURED: '/automobile/add-insured',
    DELIVERY: '/automobile/delivery',
  },
  MOTORBIKE: {
    MAIN: '/motorbike',
    INPUT_INFO: '/motorbike/input-info',
    CONFIRM: '/motorbike/input-confirm',
    DELIVERY: '/motorbike/delivery',
  },
  PROFILE: {
    VIEW_PROFILE: '/profile',
    EDIT_PROFILE: '/profile/edit',
  },
  CONTRACT: {
    MAIN: '/contracts',
    DETAIL: '/contracts/detail/:id',
  },
  QA: {
    MAIN: '/qa',
  },
  CUSTOMER: {
    INFO: '/customer-info',
    EDIT: '/customer-info/edit',
  },
  CLAIM: {
    MAIN: '/claim',
  },
  COMBO: {
    MAIN: '/combo',
    QUESTIONNAIRE: '/combo/questionnaire',
    RESULT: '/combo/result',
  },
};

export default PATH;
