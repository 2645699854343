import React from 'react';
import { iconGiftSmall, iconGiftVi, iconGiftEn } from 'assets/images';
import './GiftBox.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PATH from 'constants/path';
// import { PRODUCT_DISCOUNT } from 'constants/global';
import { getObjectByLanguage } from 'utils/common';

const GiftBox = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="box-gift text-center">
      <div className="img-gift d-flex justify-content-center">
        <img src={getObjectByLanguage(iconGiftVi, iconGiftEn)} alt="" />
      </div>
      <p className="col-10 mx-auto title text-center">{t('get_a_free_insurance_package')}</p>
      <p className="des col-10 mx-auto">{t('answer_question_a_get_package_benefit')}</p>
      <Button type="button" className="btn-answer" onClick={() => history.push(PATH.PA2.MAIN)}>
        <img src={iconGiftSmall} alt="" />
        {t('receive')}
      </Button>
      {/* <p className="des-sale col-11 mx-auto">{t('percent_sale_when_buy_multiple_product', { discount: PRODUCT_DISCOUNT })}</p> */}
    </div>
  )
}
export default GiftBox;