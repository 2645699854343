import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import surveyAPI from 'api/surveyAPI';
import productAPI from 'api/productAPI';
import { SURVEY, PRODUCTS_ID } from 'constants/global';
import orderAPI from 'api/orderAPI';
import { isArray } from 'utils/common';
import PATH from 'constants/path';
import { orderBy } from 'lodash';

const answerPa2Questionaire = createAsyncThunk(
  'PA2_ANSWER_QUESTIONAIRE',
  async (arg, { rejectWithValue }) => {
    try {
      const res = await surveyAPI.answer(SURVEY.PA2, arg);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getPa2Questionnaire = createAsyncThunk(
  'GET_PA2_QUESTIONNAIRE',
  async (_arg, { rejectWithValue }) => {
    try {
      const res = await surveyAPI.get(SURVEY.PA2);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getProductByProductId = createAsyncThunk(
  'PA2_GET_PRODUCT_BY_PRODUCT_ID',
  async (param, { rejectWithValue }) => {
    try {
      const res = await productAPI.getProductByProductId(param.product_id);
      return res;
    } catch (error) {
      return rejectWithValue({ errors: error });
    }
  }
);

const registerPA2 = createAsyncThunk('REGISTER_PA2', async (param, { rejectWithValue }) => {
  try {
    const res = await orderAPI.registerPA2();
    return res;
  } catch (error) {
    return rejectWithValue({ errors: error });
  }
});

const getComboList = createAsyncThunk('PA2_GET_COMBO_LIST', async (arg, { rejectWithValue }) => {
  try {
    const res = await productAPI.getComboList();
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getRecommendation = createAsyncThunk(
  'PA2_GET_RECOMMENDATION',
  async (arg, { rejectWithValue }) => {
    try {
      const recommendationList = [];
      const res = await productAPI.getRecommendation();
      if (isArray(res, 0)) {
        let index = 0;
        res.forEach((item) => {
          if (
            item.id !== PRODUCTS_ID.AUTOMOBILE &&
            item.id !== PRODUCTS_ID.MOTORBIKE &&
            index < 2
          ) {
            let link = '';
            if (item.id === PRODUCTS_ID.PA) {
              link = PATH.PA.MAIN;
            }
            if (item.id === PRODUCTS_ID.HEALTH_CARE) {
              link = PATH.HEALTH_CARE.MAIN;
            }
            if (item.id === PRODUCTS_ID.HOME_LIABILITY) {
              link = PATH.HOME_LIABILITY.MAIN;
            }
            recommendationList.push({ ...item, link_product: link });
            index += 1;
          }
        });
      }
      return recommendationList;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const registerPlanAndCoverage = createAsyncThunk(
  'PA2_REGISTER_PLAN_AND_COVERAGE',
  async (param, { rejectWithValue }) => {
    try {
      const res = await orderAPI.registerPlanAndCoverage(param);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  userInfo: null,
  loading: false,
  errors: [],
  marketingQuestionnaire: [],
  recommendationList: null,
  comboList: [],
  registerPlanAndCoverageResult: null,
};

const pa2Slice = createSlice({
  name: 'pa2',
  initialState,
  reducers: {},
  extraReducers: {
    [getPa2Questionnaire.fulfilled]: (state, action) => {
      state.marketingQuestionnaire = action.payload?.questions || [];
      state.loading = false;
      state.errors = [];
    },
    [getPa2Questionnaire.pending]: (state) => {
      state.loading = true;
    },
    [getPa2Questionnaire.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.errors;
    },
    [answerPa2Questionaire.fulfilled]: (state) => {
      state.errors = {};
    },
    [answerPa2Questionaire.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [getProductByProductId.fulfilled]: (state, { payload }) => {
      state.product = payload;
    },
    [getProductByProductId.pending]: (state) => {
      state.product = null;
    },
    [getProductByProductId.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
    [getRecommendation.fulfilled]: (state, { payload }) => {
      state.recommendationList = payload;
    },
    [getRecommendation.pending]: (state) => {
      state.recommendationList = null;
    },
    [getRecommendation.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
    [getComboList.fulfilled]: (state, { payload }) => {
      const comboList = payload.map(({ products, ...arg }) => {
        const sorted = orderBy(products, ['id'], ['asc']);
        return { ...arg, products: sorted };
      });
      state.comboList = comboList;
    },
    [getComboList.pending]: (state) => {
      state.comboList = null;
    },
    [getComboList.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
  },
});

const { reducer } = pa2Slice;
export {
  getPa2Questionnaire,
  answerPa2Questionaire,
  getProductByProductId,
  registerPA2,
  getComboList,
  getRecommendation,
  registerPlanAndCoverage,
};
export default reducer;
