import axiosClient from './axiosClient';

const contractAPI = {
  getList: () => {
    const url = `contract`;
    return axiosClient.get(url);
  },
  getDetail: (id) => {
    const url = `contract/${id}`;
    return axiosClient.get(url);
  },
  cancelContract: (id) => {
    const url = `contract/${id}/cancel`;
    return axiosClient.patch(url);
  },
};

export default contractAPI;
