import { CommonButton } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './NotifyDialog.scss';

const NotifyDialog = ({ title, message, label, okClick, isShow, children }) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        className="cfp-wrapper"
        show={isShow}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
      >
        <Modal.Header>
          <Modal.Title className="cfp-title mx-auto mt-2">{t(title)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="cfp-content mx-auto">{message ? t(message) : children}</Modal.Body>
        <Modal.Footer>
          <div className="tm-popup-footer">
            <CommonButton className="ml-2" label={t(label)} onPress={okClick} />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

NotifyDialog.defaultProps = {
  label: 'ok',
  message: '',
  children: '',
};

NotifyDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  isShow: PropTypes.bool.isRequired,
  okClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default NotifyDialog;
