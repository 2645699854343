import React, { useState, forwardRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from 'react-bootstrap';
import CommonButton from 'components/CommonButton';
import { useTranslation } from 'react-i18next';
import { LABELS, DATE_FORMAT } from 'constants/global';
import './CustomDateTimePicker.scss';
import { FormInput } from 'components';
import PropTypes from 'prop-types';
import { formatDate, convertStringToDate } from 'utils/common';

const CustomDateTimePicker = forwardRef(({ minDate, maxDate, seletedDate, setSeleted, ...props }, ref) => {
  const { t } = useTranslation();
  const [isShow, setShow] = useState(false);
  const [dateTime, setDateTime] = useState(
    formatDate(seletedDate, DATE_FORMAT.DATE)
  );
  useEffect(() => {
    if (seletedDate) {
      setDateTime(formatDate(seletedDate, DATE_FORMAT.DATE));
    }
  }, [seletedDate]);

  return (
    <div className="datetimepicker">
      <FormInput
        className="custom-input-datetimepicker"
        value={formatDate(dateTime, DATE_FORMAT.DATE)}
        onClick={() => {
          setShow(true);
        }}
        {...props}
        ref={ref}
        onChange={() => { }}
      />
      <Modal
        centered
        show={isShow}
        keyboard={false}
        animation={false}
        className="cfp-wrapper datetimepicker-modal"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="cfp-content mx-auto">
          <div className="customDateTimePickerWidth">
            <DatePicker
              timeInputLabel="Time:"
              className="custom-date"
              minDate={convertStringToDate(minDate, DATE_FORMAT.DATE)}
              maxDate={convertStringToDate(maxDate, DATE_FORMAT.DATE)}
              selected={convertStringToDate(dateTime, DATE_FORMAT.DATE)}
              onChange={(date) => {
                setDateTime(formatDate(date, DATE_FORMAT.DATE));
              }}
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              inline
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="tm-popup-footer">
            <CommonButton
              primary={false}
              type="button"
              className="ml-2"
              label={t(LABELS.CANCEL)}
              onPress={() => {
                setDateTime(seletedDate);
                setShow(false);
              }}
            />
            <CommonButton
              className="ml-2"
              label={t(LABELS.OK)}
              onPress={() => {
                setSeleted(dateTime);
                setShow(false);
              }}
              type="button"
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

CustomDateTimePicker.defaultProps = {
  seletedDate: "",
  minDate: "",
  maxDate: "",
};
CustomDateTimePicker.propTypes = {
  seletedDate: PropTypes.string,
  setSeleted: PropTypes.func.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string
};

export default CustomDateTimePicker;
