/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import { getOccupation } from 'app/appSlice';
import { FormSelectBox } from 'components';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const OccupationBox = forwardRef(({ ...props }, ref) => {
  const dispatch = useDispatch();
  const occupations = useSelector((state) => state.app.occupations);
  const [value, setValue] = useState(props?.value);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getOccupation());
  }, [dispatch, t]);

  useEffect(() => {
    const occupationId = occupations[0]?.id;
    if (occupationId) setValue(occupationId);
  }, [occupations]);

  const handleChange = (event) => {
    setValue(Number(event?.target?.value));
  };

  useImperativeHandle(ref, () => ({
    setImperativeValue(value) {
      setValue(value);
    },
  }));

  return (
    <FormSelectBox {...props} ref={ref} onChange={handleChange} value={value}>
      {occupations.map((item, index) => (
        <option key={`occupation_${index + 1}`} value={item.id}>
          {item.name}
        </option>
      ))}
    </FormSelectBox>
  );
});

export default OccupationBox;
