/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormSelectBox } from 'components';
import { getProvinces, getDistricts, setProvinceId } from 'app/appSlice';

const ProvinceBox = forwardRef(({ ...props }, ref) => {
  const dispatch = useDispatch();
  const { provinces } = useSelector((state) => state.app.location);
  const provinceId = useSelector((state) => state.app.provinceId);

  useEffect(() => {
    dispatch(getProvinces());
  }, [dispatch]);

  useEffect(() => {
    if (provinceId && provinceId !== -1) dispatch(getDistricts({ provinceId }));
  }, [provinceId, dispatch]);

  useEffect(() => {
    const provinceId = provinces[0]?.id;
    if (provinceId) dispatch(setProvinceId(provinceId));
  }, [provinces, dispatch]);

  const handleChange = (event) => {
    dispatch(setProvinceId(Number(event?.target?.value)));
  };

  useImperativeHandle(ref, () => ({
    setImperativeValue(value) {
      dispatch(setProvinceId(value));
    },
  }));

  return (
    <FormSelectBox
      {...props}
      ref={ref}
      onChange={handleChange}
      value={provinceId}
      controlId="province-box"
    >
      {provinces.map((item, index) => (
        <option key={`province_${index + 1}`} value={item.id}>
          {item.name}
        </option>
      ))}
    </FormSelectBox>
  );
});

export default ProvinceBox;
