import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import orderAPI from 'api/orderAPI';
import productAPI from 'api/productAPI';
import surveyAPI from 'api/surveyAPI';
import { ALERT_TYPE, ORDER_ID, PRODUCTS_ID, RELATIONSHIP, SURVEY } from 'constants/global';
import { MSG_0007, MSG_0008, MSG_0009, MSG_0010, MSG_0017, MSG_0018 } from 'constants/messages';
import { getJsonObject, isArray, isEmptyOrNull } from 'utils/common';

/**
 * Get Insured List
 */
const getListOfInsuredPerson = createAsyncThunk(
  'PA_GET_LIST_INSURED',
  async (_arg, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const res = await orderAPI.getListInsured(orderId, { query: { product_id: PRODUCTS_ID.PA } });
      const listOfInsuredPerson = !isEmptyOrNull(res)
        ? res.filter((item) => item?.product_id === PRODUCTS_ID.PA)
        : false;
      return listOfInsuredPerson[0]?.personals || [];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Get Insured Person
 * @param {string} id: Person ID
 */
const getInsuredPerson = createAsyncThunk(
  'PA_GET_INSURED_PERSON',
  async (arg, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const { personId } = arg;
      const res = await orderAPI.getInsuredPerson(orderId, personId);
      return !isEmptyOrNull(res) ? res : {};
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Update insured person
 * @param {String} personId: person ID
 * @param {Object} data: person data
 */
const updateInsuredPerson = createAsyncThunk(
  'PA_UPDATE_INSURED_PERSON',
  async (arg, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const { personId, data } = arg;
      const submitData = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        dob: data?.dob,
        gender: data?.gender,
        relationship: data?.relationship,
        adult: data?.adult,
      };
      const res = await orderAPI.updateInsuredPerson(orderId, personId, submitData);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const registerInsuredPerson = createAsyncThunk(
  'PA_CREATE_CUSTOMER_INFO',
  async (arg, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const data = {
        product_id: PRODUCTS_ID.PA,
        personal: {
          first_name: arg?.first_name,
          last_name: arg?.last_name,
          dob: arg?.dob,
          gender: arg?.gender,
          relationship: arg?.relationship,
          adult: arg?.adult,
        },
      };
      const res = await orderAPI.registerInsuredPerson(orderId, data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * delete insured person
 * @param {Object} personId
 */
const deleteInsuredPerson = createAsyncThunk(
  'PA_DELETE_CUSTOMER_INFO',
  async (arg, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const { personId } = arg;
      const res = await orderAPI.deleteInsuredPerson(orderId, personId);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Answer Questionaire
 * @param {Object} arg: Answers
 */
const answerQuestionaire = createAsyncThunk(
  'PA_ANSWER_QUESTIONAIRE',
  async (arg, { rejectWithValue }) => {
    try {
      const res = await surveyAPI.answer(SURVEY.PA, arg);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * QUESTIONAIRE
 */
const getQuestionnaire = createAsyncThunk(
  'PA_GET_QUESTIONAIRE',
  async (_arg, { rejectWithValue }) => {
    try {
      const res = await surveyAPI.get(SURVEY.PA);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Get Accepted Insured
 */
const getAcceptedInsured = createAsyncThunk(
  'PA_GET_ACCEPTED_INSURED',
  async (_arg, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const params = { params: { order_id: orderId } };
      const res = await surveyAPI.result(SURVEY.PA, params);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Get Current Answers
 */
const getCurrentAnswers = createAsyncThunk(
  'PA_GET_CURRENT_ANSWER',
  async (_arg, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const params = { params: { order_id: orderId } };
      const res = await surveyAPI.result(SURVEY.PA, params);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getSeletedPlanAndCoverage = createAsyncThunk(
  'PA_GET_SELETED_PLAN_AND_COVERAGE',
  async (param, { rejectWithValue }) => {
    try {
      let data = {
        plan_id: 1,
        coverage_ids: [],
        number_of_people: 1,
        product_id: 1,
        total_amount: 0,
      };

      const res = await orderAPI.getOrderDetailByOrderId(param.order_id);
      if (res && isArray(res.order_details, 0)) {
        data = res.order_details.find((x) => x.product_id === param.product_id);
      }
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getProductByProductId = createAsyncThunk(
  'PA_GET_PRODUCT_BY_PRODUCT_ID',
  async (param, { rejectWithValue }) => {
    try {
      const res = await productAPI.getProductByProductId(param.product_id);
      if (param.seletedMode) {
        param.dispatchFuc(getSeletedPlanAndCoverage(param));
      }
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Add product to order
 * @object { orderId, params }
 */

const registerPlanAndCoverage = createAsyncThunk(
  'PA_REGISTER_PLAN_AND_COVERAGE',
  async (param, { rejectWithValue }) => {
    try {
      const res = await orderAPI.registerPlanAndCoverage(param);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updatePlanAndCoverage = createAsyncThunk(
  'PA_UPDATE_PLAN_AND_COVERAGE',
  async (param, { rejectWithValue }) => {
    try {
      const res = await productAPI.updatePlanAndCoverage(param);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const validateAcceptedList = createAsyncThunk(
  'PA_VALIDATE_ACCEPTED_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const res = await orderAPI.validateAcceptedList(params);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getEffectDateOfSuperInsurance = createAsyncThunk(
  'PA_GET_EFFECT_DATE_OF_SUPER_INSURANCE',
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await orderAPI.getEffectDateOfSuperInsurance(orderId);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const updateEffectDateOfSuperInsurance = createAsyncThunk(
  'PA_UPDATE_EFFECT_DATE_OF_SUPER_INSURANCE',
  async ({ orderId, params }, { rejectWithValue }) => {
    try {
      const res = await orderAPI.updateEffectDateOfSuperInsurance(orderId, params);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const getListHistoryInsuredPerson = createAsyncThunk(
  'PA_GET_HISTORY_INSURED_PERSON',
  async (_arg, { rejectWithValue }) => {
    try {
      const res = await orderAPI.getListHistoryInsuredPerson();
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const getPremiumsOfPlansByProductId = createAsyncThunk(
  'PA_GET_PREMIUM_OF_PLANS_BY_PRODUCT_ID',
  async (params, { rejectWithValue }) => {
    try {
      const res = await orderAPI.getPremiumsOfPlansByProductId(params);
      return res;
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const initialState = {
  listOfInsuredPerson: [],
  hasOwner: undefined,
  insuredPerson: {},
  errors: {},
  registeredMessage: '',
  deletedMessage: '',
  product: null,
  answered: [],
  questionnaire: [],
  acceptResult: [],
  loading: false,
  seletedPlanAndCoverageOld: null,
  registerPlanAndCoverageResult: null,
  validateAcceptedList: null,
  effectDateObj: null,
  historyInsuredPerson: [],
  premiumsOfPlansByProductId: [],
};

const paSlice = createSlice({
  name: 'pa',
  initialState,
  reducers: {
    clearRegisterResponse: {
      reducer: (state) => {
        state.registerPlanAndCoverageResult = null;
      },
    },
    resetStateByKey: {
      reducer: (state, action) => {
        state[action.payload] = null;
      },
    },
    clearRegistereMessage: {
      reducer: (state) => {
        state.registeredMessage = '';
      },
    },
    clearDeleteMessage: {
      reducer: (state) => {
        state.deletedMessage = '';
      },
    },
  },

  extraReducers: {
    // get insured person call back
    [getInsuredPerson.pending]: (state) => {
      state.insuredPerson = {};
    },
    [getInsuredPerson.fulfilled]: (state, action) => {
      state.insuredPerson = action.payload;
      state.errors = {};
    },
    [getInsuredPerson.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    // get List Insured call back
    [getListOfInsuredPerson.pending]: (state) => {
      state.listOfInsuredPerson = [];
      state.insuredPerson = {};
    },
    [getListOfInsuredPerson.fulfilled]: (state, action) => {
      const hasOwner = action.payload.find((x) => x.relationship === RELATIONSHIP.ACCOUNT_OWNER);
      state.hasOwner = !isEmptyOrNull(hasOwner);
      state.listOfInsuredPerson = action.payload;
    },
    [getListOfInsuredPerson.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    // resigter insured person
    [registerInsuredPerson.fulfilled]: (state) => {
      state.registeredMessage = { messageCode: MSG_0009, type: ALERT_TYPE.SUCCESS };
    },
    [registerInsuredPerson.rejected]: (state) => {
      state.registeredMessage = { messageCode: MSG_0010, type: ALERT_TYPE.UNSUCCESS };
    },
    // update insured person
    [updateInsuredPerson.fulfilled]: (state) => {
      state.insuredPerson = {};
      state.registeredMessage = { messageCode: MSG_0007, type: ALERT_TYPE.SUCCESS };
    },
    [updateInsuredPerson.rejected]: (state) => {
      state.insuredPerson = {};
      state.deletedMessage = { messageCode: MSG_0008, type: ALERT_TYPE.UNSUCCESS };
    },
    // delete insured person
    [deleteInsuredPerson.fulfilled]: (state) => {
      state.deletedMessage = { messageCode: MSG_0017, type: ALERT_TYPE.SUCCESS };
    },
    [deleteInsuredPerson.rejected]: (state) => {
      state.deletedMessage = { messageCode: MSG_0018, type: ALERT_TYPE.UNSUCCESS };
    },
    // get questionnaire
    [getQuestionnaire.pending]: (state) => {
      state.questionnaire = [];
    },
    [getQuestionnaire.fulfilled]: (state, action) => {
      state.errors = {};
      state.questionnaire = action.payload.questions;
    },
    [getQuestionnaire.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    // answer Questionnaire
    [answerQuestionaire.fulfilled]: (state) => {
      state.errors = {};
    },
    [answerQuestionaire.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    // get acceppted result
    [getAcceptedInsured.pending]: (state) => {
      state.acceptResult = [];
    },
    [getAcceptedInsured.fulfilled]: (state, action) => {
      state.errors = {};
      state.acceptResult = action.payload;
    },
    [getAcceptedInsured.rejected]: (state, action) => {
      state.errors = action.payload;
    },
    [getProductByProductId.fulfilled]: (state, { payload }) => {
      state.product = payload;
      state.loading = false;
    },
    [getProductByProductId.pending]: (state) => {
      state.loading = true;
    },
    [getProductByProductId.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    [getCurrentAnswers.pending]: (state) => {
      state.answered = [];
    },
    // get current answer
    [getCurrentAnswers.fulfilled]: (state, { payload }) => {
      state.answered = payload;
    },
    [getCurrentAnswers.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
    [registerPlanAndCoverage.fulfilled]: (state, { payload }) => {
      state.registerPlanAndCoverageResult = payload;
    },
    [registerPlanAndCoverage.pending]: (state) => {
      state.registerPlanAndCoverageResult = null;
    },
    [registerPlanAndCoverage.rejected]: (state, { payload }) => {
      state.registerPlanAndCoverageResult = payload;
    },
    [getSeletedPlanAndCoverage.fulfilled]: (state, { payload }) => {
      state.errors = {};
      state.seletedPlanAndCoverageOld = payload;
      state.loading = false;
    },
    [getSeletedPlanAndCoverage.pending]: (state) => {
      state.loading = true;
    },
    [getSeletedPlanAndCoverage.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    [updatePlanAndCoverage.fulfilled]: (state) => {
      state.errors = {};
      state.loading = false;
    },
    [updatePlanAndCoverage.pending]: (state) => {
      state.loading = true;
    },
    [updatePlanAndCoverage.rejected]: (state) => {
      state.loading = false;
    },
    [validateAcceptedList.fulfilled]: (state, { payload }) => {
      state.validateAcceptedList = payload;
    },
    [validateAcceptedList.pending]: (state) => {
      state.validateAcceptedList = null;
    },
    [validateAcceptedList.rejected]: (state, { payload }) => {
      state.validateAcceptedList = payload;
    },
    // get effect date of super insurance
    [getEffectDateOfSuperInsurance.pending]: (state) => {
      state.errors = null;
      state.loading = true;
      state.effectDateObj = null;
    },
    [getEffectDateOfSuperInsurance.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.effectDateObj = payload;
    },
    [getEffectDateOfSuperInsurance.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    // update effect date of super insurance
    [updateEffectDateOfSuperInsurance.pending]: (state) => {
      state.errors = null;
      state.loading = true;
    },
    [updateEffectDateOfSuperInsurance.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateEffectDateOfSuperInsurance.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    // getListHistoryInsuredPerson
    [getListHistoryInsuredPerson.pending]: (state) => {
      state.historyInsuredPerson = [];
    },
    [getListHistoryInsuredPerson.fulfilled]: (state, { payload }) => {
      state.historyInsuredPerson = payload;
    },
    // get premium of plans by product id
    [getPremiumsOfPlansByProductId.pending]: (state) => {
      state.errors = null;
      state.loading = true;
      state.premiumsOfPlansByProductId = [];
    },
    [getPremiumsOfPlansByProductId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.premiumsOfPlansByProductId = payload;
    },
    [getPremiumsOfPlansByProductId.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
      state.premiumsOfPlansByProductId = [];
    },
  },
});

const {
  reducer,
  actions: { clearRegisterResponse, resetStateByKey, clearRegistereMessage, clearDeleteMessage },
} = paSlice;
export {
  getListOfInsuredPerson,
  getInsuredPerson,
  registerInsuredPerson,
  updateInsuredPerson,
  deleteInsuredPerson,
  getQuestionnaire,
  answerQuestionaire,
  getAcceptedInsured,
  getProductByProductId,
  getCurrentAnswers,
  registerPlanAndCoverage,
  getSeletedPlanAndCoverage,
  updatePlanAndCoverage,
  validateAcceptedList,
  clearRegisterResponse,
  resetStateByKey,
  clearRegistereMessage,
  clearDeleteMessage,
  getEffectDateOfSuperInsurance,
  updateEffectDateOfSuperInsurance,
  getListHistoryInsuredPerson,
  getPremiumsOfPlansByProductId,
};
export default reducer;
