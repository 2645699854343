import { FormInput } from 'components';
import CommonButton from 'components/CommonButton';
import { DATE_FORMAT, LABELS } from 'constants/global';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { convertStringToDate, formatDate } from 'utils/common';
import './CustomDatePicker.scss';

const CustomDatePicker = forwardRef(({ seletedDate, setSeletedDate, maxDate, ...props }, ref) => {
  const { t } = useTranslation();
  const [isShow, setShow] = useState(false);
  const [dateTime, setDateTime] = useState(formatDate(seletedDate, DATE_FORMAT.DATE));

  useEffect(() => {
    if (seletedDate) {
      setDateTime(formatDate(seletedDate, DATE_FORMAT.DATE));
    }
  }, [seletedDate]);

  return (
    <div className="datepicker">
      <FormInput
        value={formatDate(dateTime, DATE_FORMAT.DATE)}
        className="custom-input-datepicker"
        onClick={() => {
          setShow(true);
        }}
        {...props}
        ref={ref}
        onChange={() => { }}
      />
      <Modal
        centered
        show={isShow}
        keyboard={false}
        animation={false}
        className="cfp-wrapper datepicker-modal"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="cfp-content mx-auto">
          <div className="customDatePickerWidth">
            <DatePicker
              timeInputLabel="Time:"
              className="custom-date"
              selected={convertStringToDate(dateTime, DATE_FORMAT.DATE)}
              onChange={(date) => {
                setDateTime(formatDate(date, DATE_FORMAT.DATE));
              }}
              dateFormat={DATE_FORMAT.DATE}
              inline
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              maxDate={maxDate}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="tm-popup-footer">
            <CommonButton
              primary={false}
              type="button"
              className="ml-2"
              label={t(LABELS.CANCEL)}
              onPress={() => {
                setDateTime(seletedDate);
                setShow(false);
              }}
            />
            <CommonButton
              className="ml-2"
              label={t(LABELS.OK)}
              onPress={() => {
                setSeletedDate(dateTime);
                setShow(false);
              }}
              type="button"
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

CustomDatePicker.defaultProps = {
  seletedDate: '',
  maxDate: null,
};
CustomDatePicker.propTypes = {
  seletedDate: PropTypes.string,
  setSeletedDate: PropTypes.func.isRequired,
  maxDate: PropTypes.instanceOf(Date),
};

export default CustomDatePicker;
