import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import orderAPI from 'api/orderAPI';
import surveyAPI from 'api/surveyAPI';
import { getJsonObject, isEmptyOrNull, isArray } from 'utils/common';
import { ORDER_ID, PRODUCTS_ID, SURVEY } from 'constants/global';

/**
 * Get Insured List
 */
const getListOfInsuredPerson = createAsyncThunk(
  'COMBO_GET_LIST_INSURED',
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await orderAPI.getListInsured(orderId);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * QUESTIONAIRE
 */
/* eslint-disable no-await-in-loop */
const getQuestionnaireByIds = createAsyncThunk(
  'COMBO_GET_QUESTIONAIRE',
  async (ids, { rejectWithValue }) => {
    try {
      const res = [];
      for (let i = 0; i < ids.length; i += 1) {
        const result = await surveyAPI.get(ids[i].id);
        if (result && isArray(result?.questions, 0)) {
          result.questions.forEach((item) => {
            res.push({ ...item, product_id: result.id });
          });
        }
      }
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* Answer Questionaire
 * @param {Object} arg: Answers
 */
const answerQuestionaire = createAsyncThunk(
  'COMBO_ANSWER_QUESTIONAIRE',
  async (arg, { rejectWithValue }) => {
    try {
      const res = await surveyAPI.answer(arg.id, arg.params);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Get Insured List by product id
 */
const getListOfInsuredPersonByProductId = createAsyncThunk(
  'COMBO_GET_LIST_INSURED_BY_PRODUCT',
  async (productId, { rejectWithValue }) => {
    try {
      const orderId = getJsonObject(ORDER_ID);
      const res = await orderAPI.getListInsured(orderId, { query: { product_id: productId } });
      const listOfInsuredPerson = !isEmptyOrNull(res)
        ? res.filter((item) => item?.product_id === productId)
        : false;
      return listOfInsuredPerson[0]?.personals || [];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/*
 * Get Current Answers
 */
const getCurrentAnswers = createAsyncThunk(
  'COMBO_GET_CURRENT_ANSWER',
  async (resParams, { rejectWithValue }) => {
    try {
      let res = [];
      const params = { params: { order_id: resParams.orderId } };
      for (let i = 0; i < resParams.productIds.length; i += 1) {
        const result = await surveyAPI.result(resParams.productIds[i].id, params);
        if (result) {
          res = [...res, ...result];
        }
      }
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Get Accepted Insured
 */
const getAcceptedInsured = createAsyncThunk(
  'COMBO_GET_ACCEPTED_INSURED',
  async (productId, { rejectWithValue }) => {
    try {
      const surveyId = {
        [PRODUCTS_ID.PA]: SURVEY.PA,
        [PRODUCTS_ID.HEALTH_CARE]: SURVEY.HEALTHCARE,
        [PRODUCTS_ID.PA2]: SURVEY.PA2,
      };
      const orderId = getJsonObject(ORDER_ID);
      const res = await surveyAPI.result(surveyId[productId], { params: { order_id: orderId } });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Validate accepted list
 */
const validateAcceptedList = createAsyncThunk(
  'COMBO_VALIDATE_ACCEPTED_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const res = await orderAPI.validateAcceptedList(params);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  listOfInsuredPerson: [],
  questionnaires: [],
  acceptResult: {},
  listPerson: {},
  validateListResult: null,
  answered: [],
};

const comboSlice = createSlice({
  name: 'combo',
  initialState,
  reducers: {
    resetStateByKey: {
      reducer: (state, action) => {
        state[action.payload] = null;
      },
    },
    resetState: {
      reducer: (state) => {
        state.listOfInsuredPerson = [];
        state.questionnaires = [];
        state.answered = [];
      },
    },
  },
  extraReducers: {
    [getListOfInsuredPerson.fulfilled]: (state, { payload }) => {
      state.listOfInsuredPerson = payload || [];
      state.loading = false;
      state.errors = [];
    },
    [getListOfInsuredPerson.pending]: (state) => {
      state.loading = true;
      state.listOfInsuredPerson = [];
    },
    [getListOfInsuredPerson.rejected]: (state, action) => {
      state.listOfInsuredPerson = [];
      state.loading = false;
      state.errors = action.errors;
    },
    // get questionnaire
    [getQuestionnaireByIds.pending]: (state) => {
      state.questionnaires = [];
    },
    [getQuestionnaireByIds.fulfilled]: (state, { payload }) => {
      state.errors = {};
      state.questionnaires = payload;
    },
    [getQuestionnaireByIds.rejected]: (state, action) => {
      state.errors = action.payload;
      state.questionnaires = [];
    },

    // answer Questionnaire
    [answerQuestionaire.fulfilled]: (state) => {
      state.errors = {};
    },
    [answerQuestionaire.rejected]: (state, action) => {
      state.errors = action.payload;
    },

    // get List Insured call back
    [getListOfInsuredPersonByProductId.pending]: (state, { meta }) => {
      state.listPerson = {
        ...state.listPerson,
        [meta.arg]: [],
      };
    },
    [getListOfInsuredPersonByProductId.fulfilled]: (state, { meta, payload }) => {
      state.listPerson = {
        ...state.listPerson,
        [meta.arg]: payload,
      };
    },

    // get acceppted result
    [getAcceptedInsured.pending]: (state, { meta }) => {
      state.acceptResult = {
        ...state.acceptResult,
        [meta.arg]: [],
      };
    },
    [getAcceptedInsured.fulfilled]: (state, { meta, payload }) => {
      state.acceptResult = {
        ...state.acceptResult,
        [meta.arg]: payload,
      };
    },

    // validateAcceptedList
    [validateAcceptedList.pending]: (state) => {
      state.validateListResult = null;
    },

    [validateAcceptedList.fulfilled]: (state, { meta, payload }) => {
      const { is_valid: isValid } = payload;
      state.validateListResult = {
        ...(state.validateListResult || {}),
        [meta.arg?.productId]: isValid,
      };
    },
    [validateAcceptedList.rejected]: (state, { meta }) => {
      state.validateListResult = {
        ...(state.validateListResult || {}),
        [meta.arg?.productId]: false,
        error: true,
      };
    },
    // get current answer
    [getCurrentAnswers.pending]: (state) => {
      state.answered = [];
    },
    [getCurrentAnswers.fulfilled]: (state, { payload }) => {
      state.answered = payload;
    },
    [getCurrentAnswers.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
  },
});

const { reducer, actions } = comboSlice;
export const { resetState, resetStateByKey } = actions;
export {
  getListOfInsuredPerson,
  getQuestionnaireByIds,
  answerQuestionaire,
  getCurrentAnswers,
  getListOfInsuredPersonByProductId,
  validateAcceptedList,
  getAcceptedInsured,
};
export default reducer;
