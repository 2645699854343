import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import contractAPI from 'api/contractAPI';
import { convertSold, isArray } from 'utils/common';
import productAPI from 'api/productAPI';
import orderAPI from 'api/orderAPI';

// eslint-disable-next-line no-unused-vars
const getContractList = createAsyncThunk(
  'CT_GET_CONTRACT_LIST',
  async (arg, { rejectWithValue }) => {
    try {
      const res = await contractAPI.getList();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getContractDetail = createAsyncThunk(
  'CT_GET_CONTRACT_DETAIL',
  async (id, { rejectWithValue }) => {
    try {
      const res = await contractAPI.getDetail(id);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const cancelContract = createAsyncThunk('CT_CANCEL_CONTRACT', async (id, { rejectWithValue }) => {
  try {
    const res = await contractAPI.cancelContract(id);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getRecommendation = createAsyncThunk(
  'GET_RECOMMENDATION',
  async (arg, { rejectWithValue }) => {
    try {
      const res = await productAPI.getRecommendation();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getSoldCount = createAsyncThunk('GET_SOLD_COUNT', async (arg, { rejectWithValue }) => {
  try {
    const res = await orderAPI.getSoldCount();
    // console.log(res);
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getProductList = createAsyncThunk(
  'CT_GET_PRODUCT_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const res = await productAPI.getProducts();
      if (res && params?.dispatchFunc) {
        const solds = await params.dispatchFunc(getSoldCount());
        const recommentdation = await params.dispatchFunc(getRecommendation());
        const soldList = isArray(solds.payload, 0) ? solds.payload : [];
        const recommendationList = isArray(recommentdation.payload, 0)
          ? recommentdation.payload
          : [];
        res.forEach((item, index) => {
          const resultSold = soldList.find((x) => x.id === item.id);
          const resultRecommendation = recommendationList.find((x) => x.id === item.id);
          if (resultSold) {
            res[index] = { ...res[index], sold: convertSold(resultSold.sold_number) };
          }
          if (resultRecommendation) {
            res[index] = { ...res[index], plan: resultRecommendation.plan.name };
          }
        });
      }
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  errors: [],
  loading: false,
  list: null,
  detail: null,
  listProduct: null,
};

const contract = createSlice({
  name: 'contract',
  initialState,
  reducers: {},
  extraReducers: {
    // getContractList
    [getContractList.pending]: (state) => {
      state.list = null;
    },
    [getContractList.fulfilled]: (state, { payload }) => {
      state.list = payload?.contracts;
    },
    [getContractList.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
    // getContractDetail
    [getContractDetail.pending]: (state) => {
      state.detail = null;
      state.loading = true;
    },
    [getContractDetail.fulfilled]: (state, { payload }) => {
      state.detail = payload;
      state.loading = false;
    },
    [getContractDetail.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.loading = false;
    },
    // getProductList
    [getProductList.pending]: (state) => {
      state.listProduct = null;
    },
    [getProductList.fulfilled]: (state, { payload }) => {
      state.listProduct = payload;
    },
    [getProductList.rejected]: (state, { payload }) => {
      state.errors = payload;
    },
  },
});

const { reducer } = contract;
export { getContractList, getContractDetail, cancelContract, getProductList };
export default reducer;
