import axiosClient from 'api/axiosClient';
import { OBJECT_TYPE_MENU } from 'constants/global';
import { isIOS } from 'utils/common';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const getFile = createAsyncThunk('HD_DOWNLOAD', async (params, { rejectWithValue }) => {
  try {
    const res = await axiosClient({
      url: params,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    });
    const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));

    if (isIOS()) {
      window.location.assign(url);
    } else {
      window.open(url);
    }

    return {};
  } catch (error) {
    return rejectWithValue(error);
  }
});

const headerSlice = createSlice({
  name: 'headerSlice',
  initialState: {
    typeMenuLeft: OBJECT_TYPE_MENU.MENU,
    typeMenuRight: OBJECT_TYPE_MENU.EMPTY,
    has: OBJECT_TYPE_MENU.EMPTY,
    titleHeader: 'title_header_home',
    drawerOpen: false,
    hasDownload: false,
    url: '',
    backUrl: ''
  },
  reducers: {
    setHeader: {
      reducer: (state, action) => {
        if (action.payload.titleHeader != null) state.titleHeader = action.payload?.titleHeader;
        if (action.payload.typeMenuLeft != null) state.typeMenuLeft = action.payload?.typeMenuLeft;
        if (action.payload.typeMenuRight != null)
          state.typeMenuRight = action.payload?.typeMenuRight;
        state.hasDownload = false;
      },
    },
    setDrawer: {
      reducer: (state, action) => {
        state.drawerOpen = action.payload;
      },
    },
    setDowload: {
      reducer: (state, { payload }) => {
        state.url = payload;
        state.hasDownload = true;
      },
    },
    setBackUrl: {
      reducer: (state, { payload }) => {
        state.backUrl = payload;
      },
    },
  },
});

const { reducer, actions } = headerSlice;
export const { setHeader, setDrawer, setDowload, setBackUrl } = actions;
export { getFile };
export default reducer;
