import { OBJECT_LANGUAGE, KEY_STORAGE } from 'constants/global';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import vi from '../locales/vi-VN.json';
import en from '../locales/en-US.json';

// the translations
const resources = {
  'en-US': {
    translation: en
  },
  'vi-VN': {
    translation: vi
  }
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem(KEY_STORAGE.LANGUAGE) || process.env.REACT_APP_LANGUAGE_DEFAULT,
    resources,
    fallbackLng: OBJECT_LANGUAGE.EN,
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
      useSuspense: true,
    },
    defaultTransParent: 'div',
  });

export default i18n;
