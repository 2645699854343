import { setHeader } from 'components/Header/headerSlice';
import { OBJECT_TYPE_MENU, KEY_STORAGE, YOUTUBE_INTRODUCE_URL } from 'constants/global';
import { removeJsonObject, getObjectByLanguage } from 'utils/common';
// import PromotionBox from 'features/Home/components/PromotionBox';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import 'swiper/swiper.scss';
import './Main.scss';
import ProductBox from 'features/Home/components/ProductBox';
import { iconSaleProductVi, iconSaleProductEn } from 'assets/images';

const iframeStyle = {
  height: 200,
  width: '100%',
  borderRadius: 6,
};

const iframeAtributtes = {
  style: iframeStyle,
  src: YOUTUBE_INTRODUCE_URL,
  frameBorder: 0,
  allow: 'autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; encrypted-media',
};

const MainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setHeader({
        titleHeader: 'title_header_home',
        typeMenuLeft: OBJECT_TYPE_MENU.MENU,
        typeMenuRight: OBJECT_TYPE_MENU.CART,
      })
    );
    removeJsonObject(KEY_STORAGE.COMBO);
  }, [dispatch]);

  return (
    <>
      <div className="icon-image-sale text-center">
        <img src={getObjectByLanguage(iconSaleProductVi, iconSaleProductEn)} alt="" />
      </div>
      <ProductBox />
      {/* <PromotionBox /> */}
      <iframe title="introduce" {...iframeAtributtes} />
    </>
  );
};
MainPage.propTypes = {};
export default MainPage;
