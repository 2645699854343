import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { iconArrowDown } from 'assets/images';
import './ToggleDescription.scss';

const ToggleDescription = ({ title, showDefault, children }) => {
  const [show, setShow] = useState(showDefault);
  const onShow = () => {
    setShow(!show);
  };

  return (
    <div className="tg-content-outer">
      <div className="tg-header-box" role="button" onClick={() => onShow()}>
        <span className="tg-header-title">{title || '-'}</span>
        <img className={`icon-arrow-down ${show ? 'active' : ''} `} src={iconArrowDown} alt="" />
      </div>
      <div className={`tg-content-box ${show ? 'active' : ''} `}>{children}</div>
    </div>
  );
};

ToggleDescription.defaultProps = {
  title: '',
  showDefault: false,
};
ToggleDescription.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showDefault: PropTypes.bool,
};

export default ToggleDescription;
