import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import './Radio.scss';

const Radio = forwardRef(({ label, id, parentClass, ...props }, ref) => {
  return (
    <div className={`radio ${parentClass}`}>
      <input type="radio" id={id} {...props} ref={ref} />
      <label htmlFor={id} className="m-0">
        {label}
      </label>
    </div>
  );
});
Radio.defaultProps = {
  label: '',
  parentClass: '',
};
Radio.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  parentClass: PropTypes.string,
};

export default Radio;
