import { icQuestion } from 'assets/images';
import {
  CommonButton,
  CustomDatePicker,
  FormInput,
  FormSelectBox,
  NotifyDialog,
  Radio,
  Title,
} from 'components';
import FooterActions from 'components/FooterActions';
import { AGE, DATE_FORMAT, GENDER, RELATIONSHIP, TITLES, VALIDATION } from 'constants/global';
import { getProfile } from 'features/Profile/profileSlice';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { formatDate, isEmptyOrNull } from 'utils/common';
import './InputInsured.scss';

const ORIGIN_RRELATIONSHIPS = [
  RELATIONSHIP.ACCOUNT_OWNER,
  RELATIONSHIP.PARENTS,
  RELATIONSHIP.SPOUSE,
  RELATIONSHIP.CHILD,
  RELATIONSHIP.GRANDPARENTS,
  RELATIONSHIP.GRANDCHILDREN,
  RELATIONSHIP.SIBLINGS,
  RELATIONSHIP.ADOPTED,
  RELATIONSHIP.CHILD_IN_LAW,
];

const AGES = [AGE.CHILDREN, AGE.ADULT];

const InputInsured = forwardRef(
  ({ children, onSubmit, leftBtn, rightBtn, leftClick, dataProvider, hasOwner }, ref) => {
    const { t } = useTranslation();
    const { register, handleSubmit, errors, reset, setValue } = useForm();
    const btnSubmit = useRef();
    const dispatch = useDispatch();
    const [seletedDate, setSeletedDate] = useState(formatDate(new Date(), DATE_FORMAT.DATE));
    const [relationships, setRelationships] = useState([]);
    const [agePopup, setAgePopup] = useState(false);

    useImperativeHandle(ref, () => ({
      callSubmit() {
        btnSubmit.current.click();
      },
      reset() {
        setSeletedDate(formatDate(new Date(), DATE_FORMAT.DATE));
        reset();
      },
    }));

    /**
     * Set form inital data
     * when isNew : False
     */
    useEffect(() => {
      const initForm = (data) => {
        setValue('relationship', data?.relationship || '');
        setValue('first_name', data?.first_name || '');
        setValue('last_name', data?.last_name || '');
        setValue('gender', data?.gender || '');
        setValue('adult', data?.adult ? AGE.ADULT : AGE.CHILDREN);
        setSeletedDate(data?.dob || '');
      };

      const asyncProfile = async () => {
        await dispatch(getProfile());
      };

      setRelationships(ORIGIN_RRELATIONSHIPS.slice());
      if (!isEmptyOrNull(dataProvider)) {
        /** Edit */
        setTimeout(() => initForm(dataProvider));
        if (RELATIONSHIP.ACCOUNT_OWNER === dataProvider?.relationship) {
          asyncProfile();
        }
        if (RELATIONSHIP.ACCOUNT_OWNER !== dataProvider?.relationship && hasOwner) {
          setRelationships(ORIGIN_RRELATIONSHIPS.slice(1));
        }
      } else if (hasOwner) {
        /** Add new */
        setRelationships(ORIGIN_RRELATIONSHIPS.slice(1));
      }
    }, [setValue, dispatch, dataProvider, hasOwner]);

    /**
     * Format Input
     * @param {*} event
     */
    const formatInput = (event) => {
      const { name, value } = event.target;
      setValue(name, value.trim());
    };

    const relationshipChange = async (event) => {
      const { value } = event.target;
      if (RELATIONSHIP.ACCOUNT_OWNER === value) {
        const { payload } = await dispatch(getProfile());

        if (!isEmptyOrNull(payload)) {
          setValue('first_name', payload?.first_name || '');
          setValue('last_name', payload?.last_name || '');
          setValue('gender', payload?.gender || '');
          setValue('adult', payload?.adult ? AGE.ADULT : AGE.CHILDREN);
          setSeletedDate(payload?.dob || '');
        }
      }
    };

    return (
      <div ref={ref}>
        <Form
          className="iis-wrapper"
          onSubmit={handleSubmit((data) => {
            data.adult = data?.adult === AGE.ADULT;
            onSubmit(data, errors);
          })}
        >
          <Title className="w-100 ii-col-custom" name={t('information')} />
          <Row>
            <Col>
              <FormInput
                name="last_name"
                label={t('last_name')}
                ref={register({
                  required: t('this_field_is_required'),
                })}
                onBlur={(e) => formatInput(e)}
                error={errors?.last_name?.message}
                controlId="last_name"
                maxLength={VALIDATION.CUSTOMER.LAST_NAME_MAX_LENGTH}
              />
            </Col>
            <Col>
              <FormInput
                name="first_name"
                label={t('first_name')}
                error={errors?.first_name?.message}
                ref={register({
                  required: t('this_field_is_required'),
                })}
                onBlur={(e) => formatInput(e)}
                controlId="first_name"
                maxLength={VALIDATION.CUSTOMER.FIRST_NAME_MAX_LENGTH}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormSelectBox
                label={t('relationship_with_owner')}
                name="relationship"
                error={errors?.relationship?.message}
                ref={register({ required: t('this_field_is_required') })}
                onChange={relationshipChange}
              >
                <option key={`k_${-1}`} value="">
                  {t(RELATIONSHIP.CHOOSE)}
                </option>
                {relationships.map((item, index) => (
                  <option key={`k_${index + 1}`} value={item}>
                    {t(item)}
                  </option>
                ))}
              </FormSelectBox>
              {errors.relationship && errors.relationship.type === 'validate' && (
                <div className="error-custom">{t('this_field_is_required')}</div>
              )}
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>{t('gender')}</Form.Label>
            <Row>
              <Col className="d-flex d-inline-block ii-radio-group">
                <Radio
                  type="radio"
                  name="gender"
                  value={GENDER.MALE}
                  id={GENDER.MALE}
                  label={t(GENDER.MALE)}
                  defaultChecked={dataProvider?.gender === GENDER.MALE}
                  ref={register({ required: t('this_field_is_required') })}
                />
                <Radio
                  type="radio"
                  name="gender"
                  value={GENDER.FEMALE}
                  id={GENDER.FEMALE}
                  label={t(GENDER.FEMALE)}
                  defaultChecked={dataProvider?.gender === GENDER.FEMALE}
                  ref={register({ required: t('this_field_is_required') })}
                />
              </Col>
            </Row>
            <p className="text-danger m-0 p-0">{errors?.gender?.message}</p>
          </Form.Group>
          <Row>
            <Col>
              <CustomDatePicker
                label={t('date_of_birth')}
                type="text"
                name="dob"
                error={errors?.dob?.message}
                seletedDate={seletedDate}
                ref={register({ required: t('this_field_is_required') })}
                setSeletedDate={(value) => setSeletedDate(value)}
                maxDate={new Date()}
              />
            </Col>
            <Col>
              <FormSelectBox
                label={t('object')}
                name="adult"
                error={errors?.adult?.message}
                ref={register({ required: t('this_field_is_required') })}
              >
                {AGES.map((item, index) => (
                  <option key={`k_${index + 1}`} value={item}>
                    {t(item)}
                  </option>
                ))}
              </FormSelectBox>
              <img
                onClick={() => setAgePopup(true)}
                className="tm-question-mark"
                alt=""
                src={icQuestion}
              />
            </Col>
          </Row>

          {children}
          <FooterActions>
            <CommonButton primary={false} onPress={leftClick} label={t(leftBtn)} />
            <CommonButton label={t(rightBtn)} type="submit" />
          </FooterActions>
          <button ref={btnSubmit} type="submit" className="d-none" label="_" />
        </Form>
        <NotifyDialog
          isShow={agePopup}
          title={TITLES.INFORMATION}
          message="age_alert"
          okClick={() => setAgePopup(false)}
        />
      </div>
    );
  }
);

InputInsured.defaultProps = {
  children: [],
  leftBtn: 'cancel',
  rightBtn: 'save',
  dataProvider: {},
  hasOwner: false,
};

InputInsured.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onSubmit: PropTypes.func.isRequired,
  leftClick: PropTypes.func.isRequired,
  leftBtn: PropTypes.string,
  rightBtn: PropTypes.string,
  dataProvider: PropTypes.oneOfType([PropTypes.object]),
  hasOwner: PropTypes.bool,
};

export default InputInsured;
