export const MSG_0001 = 'MSG_0001';
export const MSG_0002 = 'MSG_0002';
export const MSG_0003 = 'MSG_0003';
export const MSG_0004 = 'MSG_0004';
export const MSG_0005 = 'MSG_0005';
export const MSG_0006 = 'MSG_0006';
export const MSG_0007 = 'MSG_0007';
export const MSG_0008 = 'MSG_0008';
export const MSG_0009 = 'MSG_0009';
export const MSG_0010 = 'MSG_0010';
export const MSG_0016 = 'MSG_0016';
export const MSG_0017 = 'MSG_0017';
export const MSG_0018 = 'MSG_0018';
