import React from 'react';
import { iconCart } from 'assets/images';
import PATH from 'constants/path';
import { NavLink } from 'react-router-dom';

const CartItem = () => {
  return (
    <div>
      <NavLink exact to={PATH.CONFIRM}>
        <img src={iconCart} alt="" />
      </NavLink>
    </div>
  )
}

export default CartItem;