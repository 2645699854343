import React from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import './ToggleBox.scss';

const ToggleBox = (props) => {
  return (
    <div className="toggle-box">
      <BootstrapSwitchButton onstyle="primary" offstyle="info" size="sm" {...props} />
    </div>
  )
}

export default ToggleBox;