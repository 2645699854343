import React, { useEffect, useState, useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import { setHeader } from 'components/Header/headerSlice';

import PATH from 'constants/path';
import { PARENT_APP_CLASS, PRODUCT_DISCOUNT } from 'constants/global';
import GiftBox from 'features/Home/components/GiftBox';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getStatusPA2 } from 'features/Home/homeSlice';
import NotFound from '../../components/NotFound';
import MainPage from './pages/Main';
import { Footer } from '../../components';
import './Home.scss';



function Home() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [displayGift, setDisplayGift] = useState(false);

  /**
   * set header
   * get product detail
   */

  useEffect(() => {
    dispatch(
      setHeader({
        titleHeader: "",
      })
    );
  }, [dispatch, t]);

  const getStatusPA2Fuc = useCallback(async () => {
    const statusPA2 = await dispatch(getStatusPA2());
    if (statusPA2) {
      setDisplayGift(statusPA2?.payload?.received)
    }
  }, [dispatch]);

  useEffect(() => {
    getStatusPA2Fuc();
  }, [dispatch, getStatusPA2Fuc]);

  return (
    <>
      <div className="background-header">
        {
          !displayGift ?
            <GiftBox />
            :
            (
              <div className="box-sale">
                <div className="col-10 mx-auto text-center title-sale-header">{t('title_sale_home')}</div>
                <p className="des-sale col-11 mx-auto">{t('percent_sale_when_buy_multiple_product', { discount: PRODUCT_DISCOUNT })}</p>
              </div>
            )
        }
      </div>
      <div className={PARENT_APP_CLASS}>
        <Switch>
          <Route exact path={PATH.HOME} component={MainPage} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </div>
    </>
  );
}
Home.propTypes = {};
export default Home;
