import { notifyMessage } from 'app/appSlice';
import { icNotify, icSuccess, icUnsuccess, icWarning } from 'assets/images';
import { ALERT_TYPE } from 'constants/global';
import PATH from 'constants/path';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './Alert.scss';

const Alert = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(notifyMessage(null));
  }, [dispatch, location]);

  const { isLoading, messageCode, type } = useSelector((state) => state.app.notify);
  if (!isLoading || location.pathname === PATH.HOME) return <></>;
  let icImg;
  switch (type) {
    case ALERT_TYPE.SUCCESS:
      icImg = icSuccess;
      break;
    case ALERT_TYPE.UNSUCCESS:
      icImg = icUnsuccess;
      break;
    case ALERT_TYPE.NOTIFY:
      icImg = icNotify;
      break;
    case ALERT_TYPE.WARNING:
      icImg = icWarning;
      break;
    default:
      icImg = icSuccess;
      break;
  }

  return (
    <div className={`tm-notify d-flex tm-alert-${type}`}>
      <img className="alert-img" alt="" src={icImg} />
      <div>{t(messageCode)}</div>
    </div>
  );
};

export default Alert;
