/** product list screen */
import icArrowLeft from './icons/arrow-left.svg';
import iconMenu from './icons/ic-menu.svg';
import iconArrowDown from './icons/icon-arrow-down.svg';
import iconAutomobike from './icons/icon-automobike.svg';
import iconAvatar from './icons/icon-avt.svg';
import iconChargeFast from './icons/icon-charge-fast.svg';
import iconContractHome from './icons/icon-contract-home.svg';
import iconContract from './icons/icon-contract.svg';
import icDownloadFile from './icons/icon-download-file.svg';
import iconHealthcare from './icons/icon-healthcare.svg';
import iconHome from './icons/icon-home.svg';
import iconIndemnify from './icons/icon-indemnify.svg';
import icLoading from './icons/icon-loading.svg';
import iconMail from './icons/icon-mail.svg';
import iconMenuClose from './icons/icon-menu-close.svg';
import iconMotorbike from './icons/icon-motorbike.svg';
import iconNews from './icons/icon-news.svg';
import thumbnailNews1 from './icons/icon-news1.svg';
import thumbnailNews2 from './icons/icon-news2.svg';
import icNotify from './icons/icon-notify.svg';
import iconPen from './icons/icon-pen.svg';
import iconPencil from './icons/icon-pencil.svg';
import iconPersonalAccident from './icons/icon-personal-accident.svg';
import iconProduct from './icons/icon-product.svg';
import iconPromotion from './icons/icon-promotion.svg';
import iconPublicLiability from './icons/icon-public-liability.svg';
import iconQesAndAns from './icons/icon-qes-ans.svg';
import icQuestion from './icons/icon-question.svg';
import iconRegisterInsurance from './icons/icon-register-insurance.svg';
import iconRules from './icons/icon-rules.svg';
import iconSetting from './icons/icon-setting.svg';
import iconShare from './icons/icon-share.svg';
import icSuccess from './icons/icon-success.svg';
import iconSupportIndemnify from './icons/icon-support-indemnify.svg';
import iconTrash from './icons/icon-trash.svg';
import icUnsuccess from './icons/icon-unsuccess.svg';
import iconUserPlus from './icons/icon-user-plus.svg';
import icWarning from './icons/icon-warning.svg';
import thumbnailBenefit1 from './icons/img-benefit-thumbnail-1.svg';
import thumbnailPromotion1 from './icons/img-promotion-item1.svg';
import thumbnailPromotion2 from './icons/img-promotion-item2.svg';
import icPlus from './icons/icon-plus.svg';
import icQuestionOutline from './icons/icon-question-outline.svg';
import icLogout from './icons/icon-logout.svg';
import iconPhone from './icons/icon-phone.svg';
import iconTmiv from './icons/icon-tmiv.svg';
import iconAeon from './icons/icon-aeon.svg';
/** icon */
import iconProductAutomobike from './product/icon-autobike.svg';
import iconProductHealthcare from './product/icon-healthcare.svg';
import iconProductMotorbike from './product/icon-motobike.svg';
import icPa2 from './product/icon-pa2.svg';
import iconProductPersonalAccident from './product/icon-personal-accident.svg';
import iconProductPublicLiability from './product/icon-public-liability.svg';
import thumbnailAutomobile from './product/thumbnail-automobile.svg';
import thumbnailMotorbike from './product/thumbnail-motorbike.svg';
/** thank you */
import imgThankYouBg from './thankYou/img-thank-you-bg.svg';
import imgThankYouText from './thankYou/img-thank-you-text.svg';
import iconCart from './icons/icon-cart.svg';
import iconGiftEn from './icons/icon-gift-en.svg';
import iconGiftVi from './icons/icon-gift-vi.svg';
import iconGiftSmall from './icons/icon-gift-small.svg';

import icAngleUp from './icons/angle-up.svg';
import icAngleDown from './icons/angle-down.svg';

import imgNoData from './errors/img-no-data.svg';
import icNote from './icons/icon-note.svg';
import iconSaleProductVi from './icons/icon-sale-product-vi.svg';
import iconSaleProductEn from './icons/icon-sale-product-en.svg';
import icGiftBox from './icons/icon-gift-box.svg';

export {
  iconAvatar,
  iconSetting,
  iconHome,
  iconContract,
  iconIndemnify,
  iconNews,
  iconProduct,
  iconPromotion,
  iconQesAndAns,
  iconRules,
  iconPersonalAccident,
  iconHealthcare,
  iconPublicLiability,
  iconAutomobike,
  iconMotorbike,
  iconChargeFast,
  iconRegisterInsurance,
  iconSupportIndemnify,
  thumbnailPromotion1,
  thumbnailPromotion2,
  thumbnailBenefit1,
  thumbnailNews1,
  thumbnailNews2,
  iconMenu,
  iconMenuClose,
  icArrowLeft,
  iconShare,
  iconProductPersonalAccident,
  iconProductHealthcare,
  iconProductPublicLiability,
  iconProductAutomobike,
  iconProductMotorbike,
  iconPencil,
  iconTrash,
  iconUserPlus,
  icDownloadFile,
  iconContractHome,
  iconArrowDown,
  iconPen,
  imgThankYouText,
  imgThankYouBg,
  icPa2,
  thumbnailAutomobile,
  thumbnailMotorbike,
  iconMail,
  icLoading,
  icSuccess,
  icUnsuccess,
  icWarning,
  icNotify,
  icQuestion,
  icPlus,
  iconCart,
  iconGiftVi,
  iconGiftEn,
  iconGiftSmall,
  icQuestionOutline,
  icAngleUp,
  icAngleDown,
  icLogout,
  imgNoData,
  icNote,
  iconPhone,
  iconTmiv,
  iconAeon,
  iconSaleProductVi,
  iconSaleProductEn,
  icGiftBox,
};
