/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import { formatPrice } from 'utils/common';
import './PlanItem.scss';

function PlanItem({ planItem, handleChangeRadio, selected }) {
  const { t } = useTranslation();
  return (
    <div className={`plan-item ${planItem.id === selected ? 'active' : ''}`}>
      <div className="pi-pi-content-outer">
        <div className="pi-header-box" role="button">
          <label className="title-plan" htmlFor={`plan${planItem.id}`}>
            <div className="title-box">{t(planItem.name)}</div>
            {/* <div
              className="title-premium"
              dangerouslySetInnerHTML={{ __html: formatPrice(premium, t) }}
            /> */}
            <input
              checked={planItem.id === selected}
              className="custom"
              name={planItem.id}
              type="radio"
              id={`plan${planItem.id}`}
              onChange={() => handleChangeRadio(planItem.id)}
            />
          </label>
        </div>
      </div>
    </div>
  );
}

// PlanItem.defaultProps = {
//   premium: '',
// };

PlanItem.propTypes = {
  planItem: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    active: PropTypes.bool,
  }).isRequired,
  handleChangeRadio: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  // premium: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PlanItem;
