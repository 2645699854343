/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './FormSelectBox.scss';

const FormSelectBox = forwardRef(
  ({ children, label, error, controlId, options, ...props }, ref) => {
    const { t } = useTranslation();
    const errClass = error ? 'form-control is-invalid' : '';
    return (
      <Form.Group className="form-input-wrapper" controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control className={errClass} as="select" {...props} ref={ref}>
          {children}
          {options.map(({ label: itemLabel, value }, index) => (
            <option key={`k_${index + 1}`} value={value}>
              {t(itemLabel)}
            </option>
          ))}
        </Form.Control>
        <p className="text-danger m-0 p-0">{error}</p>
      </Form.Group>
    );
  }
);

FormSelectBox.defaultProps = {
  children: '',
  label: '',
  error: '',
  controlId: '',
  options: [],
};
FormSelectBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  label: PropTypes.string,
  error: PropTypes.string,
  controlId: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default FormSelectBox;
