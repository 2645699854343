import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './PromotionItem.scss';

function PromotionItem({ item }) {
  const { t } = useTranslation();

  return (
    <div className="col-custom promotion-item">
      <div className="thumbnail">
        <img src={item.img} alt="" />
      </div>
      <div className="title">
        {t(item.title)}
      </div>
      <div className="created">
        {t(item.created)}
      </div>
    </div>
  );
}

PromotionItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    img: PropTypes.string,
    created: PropTypes.string,
  }).isRequired,
};


export default PromotionItem;